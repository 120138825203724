//@ts-noCheck
import { Fragment, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useQuery, useReactiveVar } from "@apollo/client";
import { POS_SCREEN_CUSTOMER_LIST } from "../../../pages/PosScreen/queries";
import { customerCache, CustomerItemsVar, searchCustomer } from "../../localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../localstate/Cart";

type Props = {
  selected?: any;
  setSelected?: any;
  forPosScreen?: boolean
  placeHolder?: string
  customerItems?: any
};

const AutoComplete = (props: Props) => {
  /*This auto complete only works for customer */
  const { selected, setSelected, forPosScreen = true, placeHolder, customerItems } = props;

  const searchCustomerVar = useReactiveVar(searchCustomer)
  const { data } = useQuery(POS_SCREEN_CUSTOMER_LIST, {
    variables: {
      search: searchCustomerVar,
      first: 10
    },
  });
  let filteredPeople: any = [];
  data?.customers?.edges?.map((item: any) => {
    filteredPeople.push({
      id: item?.node?.id,
      pk: item?.node?.pk,
      name: item?.node?.user?.fullName
    })
  });

  const handleClearCustomer = () => {
    setSelected({ id: "", pk: "", name: "" })
    customerCache.writeQuery({
      query: GET_CUSTOMER_ITEMS,
      data: {
        customerItems: CustomerItemsVar({
          id: "",
          name: "",
          pk: "",
        }),
      },
    });
  }

  useEffect(() => {
    if (customerItems?.name)
      setSelected({
        id: customerItems?.id ?? "",
        pk: customerItems?.pk ?? "",
        name: customerItems?.name ?? "",
      })
  }, [customerItems?.name])

  return (
    <div className="">
      <Combobox value={selected?.name ? selected : {
        id: customerItems?.id ?? "",
        pk: customerItems?.pk ?? "",
        name: customerItems?.name ?? "",
      }} onChange={setSelected}>
        <div className="relative">
          <div className="relative w-full border-2 border-solid border-[#4338CA] text-left bg-white rounded-full shadow-md cursor-default focus:outline-none sm:text-sm overflow-hidden ">
            <span className="flex w-[max-content] p-1 text-center text-white text-[10px]">
              <Combobox.Input
                className={`w-full ${forPosScreen ? "" : "bg-gray-200"}  focus:outline-none py-2 pl-3 pr-10 text-sm leading-5 text-[#272727]`}
                displayValue={(person) => person?.name ?? ""}
                onChange={(event) => {
                  searchCustomer(
                    event.target.value
                      ? event.target.value
                      : setSelected({ id: "", pk: "", name: "" }),
                  );
                }}
                autoComplete={"off"}
                placeholder={placeHolder}
              />
            </span>
            {selected?.name && (
              <span
                onClick={() => handleClearCustomer()}
                className="flex absolute  w-[20px] h-[20px] text-white text-center items-center justify-center right-10 top-3 bg-red-600 p-2 rounded-full cursor-pointer"
              >
                x
              </span>
            )}

            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="w-5 h-5 text-[#000000]"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => searchCustomer("")}
          >
            <Combobox.Options className="absolute w-full z-50 py-1 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 sm:text-sm">
              {filteredPeople.length === 0 && searchCustomerVar !== "" ? (
                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? "text-white bg-[#4338CA]" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? "font-medium" : "font-normal"
                            }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-teal-600"
                              }`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
export default AutoComplete;
