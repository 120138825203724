import React, { useState } from "react";
import randString from "../../utils/randomString";
import Modal from "../reusable/Modal";
import ChangeModalForm from "./AustraliaPosScreen/ChangeModalForm";
import randomString from "../../utils/randomString";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  REMOVE_ASSIGNED_MODIFIER,
  UPDATE_MODIFIER_PLACEHOLDER,
} from "../../pages/PosScreen/mutation";
import { PRODUCTS_BY_MODIFIERCATEGORY } from "../../pages/PosScreen/queries";

type Props = {
  item?: any;
  plachodersValueIndex: number;
  handleOnModIncreasingCounter: any;
  handleClickContext: any;
  openContextPopup: any;
  setOpenContextPopup: any;
  data: any;
  productId: any;
  productPk?: any;
  handleQuickAddColor: any;
};

const ReusableModifierPlaceholders = (props: Props) => {
  const {
    item,
    plachodersValueIndex,
    handleOnModIncreasingCounter,
    handleClickContext,
    setOpenContextPopup,
    productId,
    openContextPopup,
    handleQuickAddColor,
    productPk,
    data,
  } = props;

  const [changePopup, setChangePopup] = useState(false);
  const [input, setInput] = useState<string>("");
  const [updateModifierPlaceholder] = useMutation(UPDATE_MODIFIER_PLACEHOLDER, {
    refetchQueries: [
      {
        query: PRODUCTS_BY_MODIFIERCATEGORY,
        variables: {
          modifierCategoryId: item?.pk,
          categoryId: item?.pk,
          productId: productPk,
        },
      },
    ],
  });
  const [removeAssignedModifiers] = useMutation(REMOVE_ASSIGNED_MODIFIER, {
    refetchQueries: [
      {
        query: PRODUCTS_BY_MODIFIERCATEGORY,
        variables: {
          modifierCategoryId: item?.pk,
          categoryId: item?.pk,
          productId: productPk,
        },
      },
    ],
  });

  const handleChangeButton = (e: any) => {
    e.stopPropagation();
    setChangePopup(true);
    setOpenContextPopup(false);
  };

  const handleModifierCategoryChange = async (data: any) => {
    const response = await updateModifierPlaceholder({
      variables: {
        id: productId,
        modifierProduct: data,
        categoryId: item.pk,
        productId: productPk,
      },
    });
    if (response?.data?.assignModifier?.success) {
      setChangePopup(false);
      toast.success("Modifier Add Successfully");
      setInput("");
    }
  };

  const handleRemoveAssignModifier = async (e: any, modifierId: any) => {
    e.preventDefault();
    const response = await removeAssignedModifiers({
      variables: {
        id: modifierId,
        categoryId: item.pk,
      },
    });
    if (response?.data?.removeAssignedModifiers?.success) {
      toast.success("Modifier Removed Successfully");
    } else {
      toast.error(response?.data?.removeAssignedModifiers?.errors?.message);
    }
    setOpenContextPopup(false);
  };

  const modifierPlaceHoldersPk = data?.modifierPlaceHolders?.edges?.flatMap(
    (item: any) =>
      item?.node?.modifierOrderPlaceholder !== null
        ? item?.node?.modifierOrderPlaceholder?.modifierProduct?.pk
        : [],
  );
  const itemCheck = item?.subModifier?.filter(
    (subItem: any) =>
      modifierPlaceHoldersPk?.includes(subItem?.pk) && subItem?.isUpdated,
  );

  const isItemUpdated = (modifierProductPk: string) => {
    return itemCheck?.some(
      (checkedItem: any) =>
        checkedItem.pk === modifierProductPk && checkedItem.isUpdated,
    );
  };
  return (
    <>
      <div className={"grid grid-cols-7 p-4 gap-2"}>
        {data?.modifierPlaceHolders?.edges?.map(
          (modifierPlaceHolderItem: any, modifierPlaceHolderIndex: number) => (
            <React.Fragment key={randString(20)}>
              <div
                className={`relative cursor-pointer border-2 h-[70px] rounded-lg `}
                onClick={() => {
                  handleOnModIncreasingCounter(
                    item,
                    plachodersValueIndex,
                    modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                      ?.modifierProduct?.pk,
                  );
                }}
                onMouseLeave={() => setOpenContextPopup(false)}
                onContextMenu={(event: any) =>
                  handleClickContext(event, modifierPlaceHolderItem)
                }
                style={{
                  background: modifierPlaceHolderItem?.node
                    ?.modifierOrderPlaceholder?.modifierProduct?.title
                    ? modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                        ?.color ?? "#8B6BA6"
                    : "#F3F4F6",
                }}
              >
                {modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                  ?.modifierProduct ? (
                  <>
                    <img
                      src={
                        modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                          ?.modifierProduct?.image
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      className={`${
                        isItemUpdated(
                          modifierPlaceHolderItem?.node
                            ?.modifierOrderPlaceholder?.modifierProduct?.pk,
                        )
                          ? "text-red-600"
                          : "text-white"
                      } textWithTwoLine absolute bottom-[0%] w-full  text-left font-bold px-2`}
                    >
                      {modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                        ?.modifierProduct?.title ?? ""}
                    </span>
                  </>
                ) : (
                  <div>
                    <img
                      src={
                        modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                          ?.modifierProduct?.image
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <span
                      title={item?.subModifier[modifierPlaceHolderIndex]?.title}
                      className={`${
                        isItemUpdated(
                          modifierPlaceHolderItem?.node
                            ?.modifierOrderPlaceholder?.modifierProduct?.pk,
                        )
                          ? "text-red-600"
                          : "text-white"
                      } textWithTwoLine absolute bottom-[0%] w-full  text-left font-bold px-2`}
                    >
                      {modifierPlaceHolderItem?.node?.modifierOrderPlaceholder
                        ?.modifierProduct?.title ?? ""}
                    </span>
                  </div>
                )}

                {modifierPlaceHolderItem?.node?.pk === productId &&
                  openContextPopup && (
                    <>
                      <div
                        className={
                          "w-full absolute cursor-pointer top-[50%] bg-[#e0e0e0] rounded-md p-1 z-20"
                        }
                        style={{ width: "120px" }}
                      >
                        {!modifierPlaceHolderItem?.node
                          ?.modifierOrderPlaceholder?.modifierProduct
                          ?.title && (
                          <div
                            onClick={(e) => handleChangeButton(e)}
                            className="hover:bg-[#4338CA]/50 pl-1 my-2 text-left"
                          >
                            Add Modifier
                          </div>
                        )}

                        <div
                          onClick={(e) =>
                            handleRemoveAssignModifier(
                              e,
                              modifierPlaceHolderItem?.node
                                ?.modifierOrderPlaceholder?.pk,
                            )
                          }
                          className="hover:bg-[#4338CA]/50 pl-1 my-2 text-left"
                        >
                          Remove
                        </div>

                        <div
                          onClick={(e) =>
                            handleQuickAddColor(
                              e,
                              modifierPlaceHolderItem?.node
                                ?.modifierOrderPlaceholder?.pk,
                            )
                          }
                          className="hover:bg-[#4338CA]/50 pl-1 my-2 text-left"
                        >
                          Add Color
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </React.Fragment>
          ),
        )}
      </div>
      <Modal
        title={"Add Modifier"}
        open={changePopup}
        setOpen={setChangePopup}
        onCloseModal={() => setInput("")}
      >
        <ChangeModalForm
          input={input}
          setInput={setInput}
          children={
            <ul className="block w-full text-left p-1 ml-1  h-[100px] overflow-y-scroll shadow-lg">
              {input.length > 0
                ? item?.subModifier
                    ?.filter((item: any) =>
                      item.title
                        .toLowerCase()
                        .includes(input.trim().toLowerCase()),
                    )
                    .map((item: any) => (
                      <li
                        key={randomString(20)}
                        className="hover:bg-[#4338CA] hover:text-white cursor-pointer px-2"
                        onClick={() => handleModifierCategoryChange(item?.pk)}
                      >
                        {item?.title}
                      </li>
                    ))
                : item?.subModifier?.map((item: any, index: number) => (
                    <li
                      key={randomString(20)}
                      className="hover:bg-[#4338CA] hover:text-white cursor-pointer px-2"
                      onClick={() => handleModifierCategoryChange(item?.pk)}
                    >
                      {item?.title}
                    </li>
                  ))}
            </ul>
          }
          name={"searchModifier"}
        />
      </Modal>
    </>
  );
};

export default ReusableModifierPlaceholders;
