import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useEffect, useState } from "react";
import { POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER } from "../../../pages/PosScreen/queries";
import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
  kitchenOrderItemId,
  kitchenOrdreCache,
  modifiedKitchenOrderCache,
  modifiedKitchenOrderItemVar,
  orderTypeCache,
  orderTypeVar,
  tableCache,
  tableItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_KITCHEN_CARD_ID,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_ORDER_TYPE,
  GET_TABLE_ITEMS,
} from "../../localstate/Cart";
import Modal from "../../reusable/Modal";
import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";
import PosScreenMergeTable from "./PosScreenMergeTable";
import PosScreenSwapTable from "./PosScreenSwapTable";
import randomString from "../../../utils/randomString";
import { GET_RESTAURANT_FLOOR } from "../../../pages/queries";
import PosScreenSplitTable from "./PosScreenSplitTable";
import PosScreenSplitSubTableList from "./PosScreenSplitSubTableList";
import DropDrag from "./DynamicLayout";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import usePosScreenHandleClear from "../../../customhooks/usePosScreenHandleClear";
import { toast } from "react-toastify";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";
import { UNMERGE_RESTAURANT_TABLE } from "../../../pages/mutations";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";

type Props = {
  setOpenTableList?: any;
  openSplitTable: boolean;
  setOpenSplitTable: any;
  openMergeTable: boolean;
  setOpenMergeTable: any;
  selectedSwapTable: string;
  setSelectedSwapTable: any;
  openSwapTable: any;
  setOpenSwapTable: any;
  setSelectedTableArray: any;
  selectedTableArray: any;
  setTableId: any;
  tableId: string;
  handleMergeTable: any;
  handleSwapTable: any;
  handleSplitTable: any;
  debouncedHandleClick: any;
  quantity: number;
  setQuantity: any;
  handleSplitDisableSubTable: any;
  openSplitSubTableList: boolean;
  setOpenSplitSubTableList: any;
  floorId: string;
  setFloorId: any;
  setFloorPk: any;
  floorPk: string;
  isFloorLoading: boolean;
  data: any;
  splitloading: any;
};

const PosScreenTableList = (props: Props) => {
  const {
    setOpenTableList,
    openSplitTable,
    openSplitSubTableList,
    setOpenSplitSubTableList,
    setOpenSplitTable,
    setOpenMergeTable,
    openMergeTable,
    selectedSwapTable,
    setSelectedSwapTable,
    setSelectedTableArray,
    selectedTableArray,
    setTableId,
    tableId,
    handleMergeTable,
    handleSplitTable,
    handleSwapTable,
    openSwapTable,
    setOpenSwapTable,
    debouncedHandleClick,
    quantity,
    setQuantity,
    handleSplitDisableSubTable,
    floorId,
    setFloorId,
    setFloorPk,
    floorPk,
    isFloorLoading,
    data,
    splitloading,
  } = props;
  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const [getOrderedItem, { data: tableWiseData }] = useLazyQuery(
    POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER,
    {
      fetchPolicy: "no-cache",
    }
  );
  const handleSplitOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setOpenSplitTable(true);
  };
  const handleDisableSplitOption = (e: any) => {
    handleSplitDisableSubTable(e.target.dataset.tableId);
  };

  const handleMergeOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setSelectedTableArray((prev: any) => [...prev, e.target.dataset.tableId]);
    setOpenMergeTable(true);
  };

  const handleSwapOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setOpenSwapTable(true);
  };
  const [unmergeRestroTable, {}] = useMutation(UNMERGE_RESTAURANT_TABLE, {
    refetchQueries: [],
  });
  const handleUnmergeOption = async (e: any, value: any) => {
    const response = await unmergeRestroTable({
      variables: {
        mergeInstanceId: value,
      },
    });
    if (response.data?.disableMergeTable?.success) {
      toast.success("Tables Unmerged Successfully");
      handleClearPosScreen();
    } else if (response.data?.disableMergeTable?.errors) {
      backendErrorDisplay(response.data?.disableMergeTable?.errors);
    } else {
      toast.info(response.data?.disableMergeTable?.messages);
    }
  };
  const { handleClearPosScreen } = usePosScreenHandleClear();

  const handleSelectTable = async (item: any) => {
    setLocalKey("disableRemotePrint", "true");
    let tableItems = {
      id: item?.id,
      status: item?.status,
      pk: item?.pk,
      title: item?.title,
      currentKitchenOrder: item?.currentKitchenOrder ?? "",
      mergedId: item?.mergeInstance,
    };
    if (item?.status === "OCCUPIED") {
      await getOrderedItem({
        variables: {
          id: item?.id,
        },
      });
    } else if (
      item?.status === "VACANT" &&
      kitchenOrdreItemId?.kitchenCardItemId?.id
    ) {
      handleClearPosScreen();
    } else {
      setLocalKey("disableRemotePrint", "false");
      kitchenOrdreCache.writeQuery({
        query: GET_KITCHEN_CARD_ID,
        data: {
          kitchenOrderItemId: kitchenOrderItemId({}),
        },
      });
      modifiedKitchenOrderCache.writeQuery({
        query: GET_MODIFIED_KITCHEN_CARD_ID,
        data: {
          modifiedKitchenOrderId: modifiedKitchenOrderItemVar({}),
        },
      });

      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar({}),
        },
      });
    }

    tableCache.writeQuery({
      query: GET_TABLE_ITEMS,
      data: {
        tableItems: tableItemsVar(tableItems),
      },
    });
    setOpenSplitSubTableList(false);
    setOpenTableList();
  };
  const handleTable = async (item: any) => {
    if (item?.isSubTableVisible) {
      setTableId(item?.pk);
      setOpenSplitSubTableList(true);
    } else {
      const valueResponse: any = await handleSelectTableToggle(
        true,
        item?.mergeInstance ? item?.mergeInstance : item?.pk
      );
      if (valueResponse) {
        handleSelectTable(item);
      }
    }
  };
  useEffect(() => {
    if (tableWiseData) {
      let itemToCart: any[] = [];

      let previousOrderItemsList =
        tableWiseData?.restaurantTable?.currentKitchenOrder ?? [];

      const ordeTypeList = {
        pk: previousOrderItemsList?.orderMethod?.pk,
        title: previousOrderItemsList?.orderMethod?.title,
      };
      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar(ordeTypeList),
        },
      });

      previousOrderItemsList?.kitchenOrderItems?.map((item: any) => {
        let modItems: any = [];
        item?.modifiers?.edges?.map((modItem: any) => {
          modItems.push({
            id: modItem?.node?.product?.id,
            pk: modItem?.node?.product?.pk,
            contentTypeId: modItem?.node?.product?.contentTypeId,
            title: modItem?.node?.product?.title,
            quantity: modItem?.node?.quantity,
            price: modItem?.node?.product?.finalPrice ?? 0,
            total:
              parseFloat(modItem?.node?.quantity) *
              parseFloat(modItem?.node?.product?.finalPrice),
            tax:
              parseFloat(modItem?.node?.quantity) *
              parseFloat(modItem?.node?.product?.taxAmount),
            taxRate: {
              rate: modItem?.node?.product?.tax?.rate,
              isIncluded: modItem?.node?.product?.tax?.isIncluded,
            },
            isSelected: false,
            isDeleted: false,
            discount: modItem?.node?.product?.discount,
          });
        });
        itemToCart.push({
          id: item?.product?.id,
          pk: item?.product?.pk,
          contentTypeId: item?.product?.contentTypeId,
          title: item?.product?.title,
          quantity: item?.quantity,
          price: item?.product?.finalPrice,
          total:
            parseFloat(item?.quantity) * parseFloat(item?.product?.finalPrice),
          tax:
            parseFloat(item?.quantity) * parseFloat(item?.product?.taxAmount),
          taxRate: {
            rate: item?.product?.tax?.rate,
            isIncluded: item?.product?.tax?.isIncluded,
          },
          isSelected: false,
          isDeleted: false,
          idToModifyKitchenOrder: item?.pk,
          modifier: modItems,
        });

        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar(itemToCart),
          },
        });
        let kitchenOrderItems = {
          kitchenOrderPk: previousOrderItemsList,
        };
        modifiedKitchenOrderCache.writeQuery({
          query: GET_MODIFIED_KITCHEN_CARD_ID,
          data: {
            modifiedKitchenOrderId:
              modifiedKitchenOrderItemVar(kitchenOrderItems),
          },
        });
        const kitchenCardItemId: any = {
          id: previousOrderItemsList?.id,
          pk: previousOrderItemsList?.pk,
        };
        kitchenOrdreCache.writeQuery({
          query: GET_KITCHEN_CARD_ID,
          data: {
            kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
          },
        });
        let customerItems = {
          id: previousOrderItemsList?.customer?.id ?? "",
          name: `${previousOrderItemsList?.customer?.user?.fullName ?? ""}`,
          pk: previousOrderItemsList?.customer?.pk ?? "",
        };
        customerCache.writeQuery({
          query: GET_CUSTOMER_ITEMS,
          data: {
            customerItems: CustomerItemsVar(customerItems),
          },
        });
      });
    }
  }, [tableWiseData?.restaurantTable]);

  const [getTableDataByRes, { data: selectedRestaurantFloor }] = useLazyQuery(
    GET_RESTAURANT_FLOOR,
    {
      fetchPolicy: "network-only",
      pollInterval: 1000,
    }
  );

  useEffect(() => {
    if (floorId?.length) {
      getTableDataByRes({
        variables: {
          id: floorId,
        },
        fetchPolicy: "network-only",
      });
    }
  }, [floorId]);

  const [updateTablePopup, setUpdateTablePopup] = useState(false);
  return (
    <div>
      {isFloorLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <div
            key={randomString(20)}
            className="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <div className="grid grid-rows-1 grid-cols-7 gap-2 p-2">
              {data?.floors?.edges?.map((item: any) => (
                <div
                  key={item?.node?.id}
                  className={`flex text-center min-h-full max-h-[60px] cursor-pointer rounded-lg items-center shadow-sm 
                           ${
                             item.node.id === floorId
                               ? "bg-[#BABABB] text-white"
                               : "bg-[#efefef] text-gray-800"
                           }`}
                >
                  <div
                    className="flex py-3 w-full justify-center"
                    onClick={() => {
                      setFloorId(item?.node?.id);
                      setFloorPk(item?.node?.pk);
                    }}
                  >
                    <p className="">{item?.node?.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br />
          <div className="h-[820px]">
            <DropDrag
              tables={
                selectedRestaurantFloor?.floor?.tablesByFloor?.edges || []
              }
              layout={
                selectedRestaurantFloor?.floor?.layout?.length === 0
                  ? null
                  : selectedRestaurantFloor?.floor?.layout
              }
              floorPk={floorPk}
              floorID={floorId}
              handleTable={handleTable}
              handleUnmergeOption={handleUnmergeOption}
              handleSwapOption={handleSwapOption}
              handleMergeOption={handleMergeOption}
              handleSplitOption={handleSplitOption}
              handleDisableSplitOption={handleDisableSplitOption}
              updateTablePopup={updateTablePopup}
              setUpdateTablePopup={setUpdateTablePopup}
            />
          </div>
        </>
      )}

      {/* openMergeTable */}
      <Modal
        open={openMergeTable}
        setOpen={setOpenMergeTable}
        title={selectedRestaurantFloor?.floor?.tablesByFloor?.edges
          ?.filter((item: any) => {
            return (item?.node?.pk).toString() === tableId;
          })
          .map((elem: any, index: number) => (
            <p
              key={index}
              className="flex items-center justify-center text-sm font-semibold"
            >
              Merge
              <span className="text-[#ED4C5C] mx-1">
                {elem?.node?.title} (0/{elem?.node?.capacity})
              </span>
              With
            </p>
          ))}
        size={"max-w-7xl"}
        onCloseModal={() => setSelectedTableArray([])}
      >
        <PosScreenMergeTable
          setSelectedTableArray={setSelectedTableArray}
          selectedTableArray={selectedTableArray ? selectedTableArray : []}
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleMergeTable={handleMergeTable}
        />
      </Modal>

      {/* openSwapTable */}
      <Modal
        open={openSwapTable}
        setOpen={setOpenSwapTable}
        title={selectedRestaurantFloor?.floor?.tablesByFloor?.edges
          ?.filter((item: any) => {
            return (item?.node?.pk).toString() === tableId;
          })
          .map((elem: any, index: number) => (
            <p
              key={index}
              className="flex items-center justify-center text-sm font-semibold"
            >
              Swap{" "}
              <span className="text-[#ED4C5C] mx-1">
                {elem?.node?.title} (0/{elem?.node?.capacity})
              </span>{" "}
              With
            </p>
          ))}
        size={"max-w-7xl"}
        onCloseModal={() => setSelectedSwapTable(false)}
      >
        <PosScreenSwapTable
          selectedSwapTable={selectedSwapTable}
          setSelectedSwapTable={setSelectedSwapTable}
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleSwapTable={handleSwapTable}
        />
      </Modal>

      {/* openSplitTable */}
      <Modal
        open={openSplitTable}
        setOpen={setOpenSplitTable}
        title={selectedRestaurantFloor?.floor?.tablesByFloor?.edges
          ?.filter((item: any) => {
            return (item?.node?.pk).toString() === tableId;
          })
          .map((elem: any, index: number) => (
            <p
              key={index}
              className="flex items-center justify-center text-sm font-semibold"
            >
              Split{" "}
              <span className="text-[#ED4C5C] mx-1">
                {elem?.node?.title} (0/{elem?.node?.capacity})
              </span>{" "}
              In
            </p>
          ))}
        size={"max-w-md"}
        onCloseModal={() => setOpenSplitTable(false)}
      >
        <PosScreenSplitTable
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          debouncedHandleClick={debouncedHandleClick}
          splitloading={splitloading}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      </Modal>

      {/*openSplitSubTableList  */}
      <Modal
        open={openSplitSubTableList}
        setOpen={setOpenSplitSubTableList}
        title={selectedRestaurantFloor?.floor?.tablesByFloor?.edges
          ?.filter((item: any) => {
            return (item?.node?.pk).toString() === tableId;
          })
          .map((elem: any, index: number) => (
            <p
              key={index}
              className="flex items-center justify-center text-sm font-semibold"
            >
              Sub List of{" "}
              <span className="text-[#ED4C5C] mx-1">
                {elem?.node?.title} (0/{elem?.node?.capacity})
              </span>
            </p>
          ))}
        size={"max-w-7xl"}
        onCloseModal={() => setOpenSplitSubTableList(false)}
      >
        <PosScreenSplitSubTableList
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleSelectTable={handleSelectTable}
        />
      </Modal>
    </div>
  );
};
export default PosScreenTableList;
