import { useState, useMemo, useEffect, Fragment } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import useContextMenu from "../../../customhooks/useContext";
import { Menu } from "@headlessui/react";
import "./a.css";
import classNames from "../../../utils/classNames";
// import FloorSwitchCase from "../../reusable/FloorSwitchCase";

const GridLayout = WidthProvider(RGL);

const DropDrag = (props: any) => {
  const {
    layout: databaseLayouts,
    tables: floorTables,
    floorPk,
    floorID,
  } = props;
  const {
    handleTable,
    handleUnmergeOption,
    handleSwapOption,
    handleMergeOption,
    handleSplitOption,
    handleDisableSplitOption,
  } = props;
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  let parsedLay = databaseLayouts ? JSON.parse(databaseLayouts) : [];
  const handleClickRight = (e: any, val: any) => {
    setClicked(true);
    setClickedIndex(val?.pk);
  };
  // Match and arrange array2 based on array1
  const map = new Map(
    floorTables.map((item: any) => [item.node.pk, item.node])
  );
  const mergedArray = parsedLay.map((item: any) => {
    const node: any = map.get(item.tableId);
    return node ? { ...node, ...item } : item;
  });

  const generateDOM = () => {
    return _.map(mergedArray, function (l, i: any) {
      const tableCount = mergedArray[i];
      const tableNode = mergedArray[i];
      let tableShapeImage: any;
      const color = tableNode?.mergedColorCode;
      const myComponentClassName = classNames(
        "relative flex justify-between cursor-pointer",
        tableNode && !tableNode?.isFunctional ? "pushBack" : ""
      );
      const pClassName = classNames(
        "absolute flex justify-center items-center w-full h-full text-center p-2 right-1 text-sm z-50",
        tableNode?.isSelected ? "text-red-600" : "",
        tableNode?.status === "OCCUPIED" && !tableNode?.isSelected
          ? "text-white"
          : ""
      );

      return (
        <div
          key={i}
          style={{
            border: `${tableNode?.isMerged ? `4px solid ${color}` : ""}`,
            borderColor: `${tableNode?.isMerged ? color : ""}`,
          }}
          data-grid={l}
          className={myComponentClassName}
          onContextMenu={(e) => {
            if (e.type === "contextmenu") {
              handleClickRight(e, tableCount);
            }
          }}
        >
          {/* <FloorSwitchCase
            parameter={tableCount?.shape}
            tableShapeImage={tableShapeImage}
            one={false}
            color={tableNode?.status === "OCCUPIED" ? "#4338CA" : ""}
          /> */}
          {!tableCount?.isFunctional ? (
            tableCount?.shape === "textField" ? (
              <p>{tableNode?.title}</p>
            ) : (
              ""
            )
          ) : (
            <p
              className={pClassName}
              onClick={(e: any) => {
                handleTable(tableNode);
              }}
            >
              {tableNode?.title}
            </p>
          )}
          {clicked && clickedIndex === tableCount?.tableId && (
            <Menu as={Fragment}>
              <Menu.Items
                static
                className="absolute right-[-50px] z-50 w-full p-0.5 bg-white border rounded shadow"
                style={{
                  width: "100px",
                  height:
                    `${tableNode?.status === "OCCUPIED" ? "p-4" : "120px"}` &&
                    "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {tableNode?.isMerged ? (
                  <Menu.Item as="div">
                    <button
                      className={`  text-gray-800 flex justify-center w-full p-3 rounded-md text-sm hover:bg-slate-200`}
                      data-table-id={tableNode?.mergeInstance}
                      onClick={(e) =>
                        handleUnmergeOption(e, tableNode?.mergeInstance)
                      }
                    >
                      Unmerge
                    </button>
                  </Menu.Item>
                ) : null}

                {tableNode?.isSubTableVisible ? (
                  <Menu.Item as="div">
                    <button
                      className={`  text-gray-800 flex justify-center w-full  rounded-md p-3 text-sm hover:bg-slate-200`}
                      data-table-id={tableNode?.pk}
                      onClick={(e) => handleDisableSplitOption(e)}
                    >
                      Disable Split
                    </button>
                  </Menu.Item>
                ) : null}

                {tableNode &&
                tableNode?.status === "OCCUPIED" &&
                !tableNode?.isSelected ? (
                  <span>
                    <Menu.Item as="div">
                      <button
                        className={`  text-gray-800 flex justify-center w-full  rounded-md p-3 text-sm hover:bg-slate-200`}
                        data-table-id={
                          tableNode?.mergeInstance
                            ? tableNode?.mergeInstance
                            : tableNode?.pk
                        }
                        onClick={(e) => handleSwapOption(e)}
                      >
                        Swap
                      </button>
                    </Menu.Item>
                  </span>
                ) : null}

                {!tableNode?.isMerged && !tableNode?.isSubTableVisible ? (
                  <Menu.Item as="div">
                    <button
                      className={`  text-gray-800 flex justify-center w-full  rounded-md p-3 text-sm hover:bg-slate-200`}
                      data-table-id={
                        tableNode?.mergeInstance
                          ? tableNode?.mergeInstance
                          : tableNode?.pk
                      }
                      onClick={(e) => handleMergeOption(e)}
                    >
                      Merge
                    </button>
                  </Menu.Item>
                ) : null}
                {!tableNode?.isMerged && !tableNode?.isSubTableVisible ? (
                  <Menu.Item as="div">
                    <button
                      className={` text-gray-800 flex justify-center w-full rounded-md p-3 text-sm hover:bg-slate-200`}
                      data-table-id={tableNode?.pk}
                      onClick={(e) => handleSplitOption(e)}
                    >
                      Split
                    </button>
                  </Menu.Item>
                ) : null}
              </Menu.Items>
            </Menu>
          )}
        </div>
      );
    });
  };
  return (
    <div className="h-[720px]">
      <GridLayout
        className="layout"
        style={{ height: "720px" }}
        cols={16}
        rowHeight={10}
        width={1200}
        measureBeforeMount={false}
        compactType={null}
        allowOverlap={true}
        isDroppable={false}
        containerPadding={[0, 0]}
        margin={[0, 0]}
        isBounded={true}
        isDraggable={false}
        isResizable={false}
      >
        {generateDOM()}
      </GridLayout>
    </div>
  );
};

export default DropDrag;

DropDrag.defaultProps = {
  className: "layout",
  onLayoutChange: (layout: any, layouts: any) => {},
  isDraggable: false,
};
