import moment from "moment";

const productInitialValues = {
  title: "",
  category: [],
  description: "",
  unit: "",
};

const categoryInitialValues = {
  name: "",
  image: "",
  status: false,
};
const dataSyncInitialValues = {
  domainName: "",
};

const detailSaleReportInitialValues = {
  user: [],
  category: [],
  items: [],
  table: [],
  modeOfPayment: [],
  isTaxable: [],
  fromDate: moment(new Date()).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
};

const hourlySaleReportInitialValues = {
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DD"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DD"),
};
const noSaleReportInitialValues = {
  user: [],
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DDThh:mm:ss"),
};

const quickSaleReportInitialValues = {
  modeOfPayment: [2],
  isTaxable: [],
  fromDate: moment(new Date()).startOf("day").format("YYYY-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("YYYY-MM-DDThh:mm:ss"),
};

const employeeReportInitialValues = {
  user: [],
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DDThh:mm:ss"),
};

const stockReportInitialValues = {
  category: [],
  items: [],
  isTaxable: [],
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DDThh:mm:ss"),
};

const membersReportInitialValues = {
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DDThh:mm:ss"),
};

const wastageAndLossReportInitialValues = {
  category: [],
  items: [],
  user: [],
  fromDate: moment(new Date()).startOf("day").format("yyyy-MM-DDThh:mm:ss"),
  toDate: moment(new Date()).endOf("day").format("yyyy-MM-DDThh:mm:ss"),
};

const productAttributeinitialValues = {
  label: "",
  isRequired: "",
};

const attributeValueInitialValues = {
  label: "",
  value: "",
};
const posScreenProductInitialValues = {
  productName: "",
  category: [],
  description: "",
  unit: "",
  taxType: "",
  isActive: false,
};
const modifierInitialValues = {
  modifierName: "",
  unit: "",
  category: [],
};

const posScreenBarCodeInitialValues = {
  barCode: "",
  plu: "",
};
const posScreenPriceInitialValues = {
  sellingPrice: "",
  costPrice: 0,
};
const posScreenStockInitialValues = {
  stockKeepingUnit: "",
  quantity: "",
  warehouse: [],
};
const wareHouseInitialValues = {
  name: "",
};

const taxInitialValues = {
  title: "",
  rate: "",
  isIncluded: false,
};

const serviceChargeInitialValues = {
  title: "",
  rate: "",
};

const supplierInitialValues = {
  firstName: "",
  primaryEmail: "",
  primaryContact: "",
  fullAddress: "",
};

const userInitialValues = {
  fullName: "",
  username: "",
  email: "",
  mobileNumber: "",
  isStaff: "",
  isSuperuser: "",
  password: "",
  confirmPassword: "",
};

const purchaseStockInitialValues = {
  supplier: "",
  product: "",
  costPrice: 0,
  quantity: "",
  discount: "",
  taxAmount: "",
};

const supplierPaymentInitialValues = {
  user: "",
  amount: "",
  note: "",
  transactionAccounts: "",
};

const addExpenseInitialValues = {
  amount: "",
  note: "",
};

const acceptPaymentInitialValues = {
  account: "",
  amount: "",
  discount: "",
  user: "",
  note: "",
};

const carouselInitialValues = {
  name: "",
  isVisible: false,
};

const salesDiscountInitialValues = {
  title: "",
  discountType: "",
  rateAmount: "",
};
const voucherDiscountInitialValues = {
  couponCode: "",
  title: "",
  discountType: "",
  rateAmount: "",
};

const giftCardDiscountInitialValues = {
  couponCode: "",
  title: "",
  discountType: "",
  rateAmount: "",
};

const posScreenInitialValues = {
  title: "",
  discountType: "",
  rateAmount: "",
};

const appPageInitialValues = {
  name: "",
  content: "",
  isVisible: "",
  isExternal: "",
};
const createPeriodicTasksInitialValues = {
  name: "",
  task: "",
  description: "",
  interval: "",
  startTime: "",
  expires: "",
  enabled: false,
};
const emailInitialValues = {
  title: "",
  email: "",
  isVerified: false,
  newEmails: [],
};

const testimonialInitialValues = {
  fullName: "",
  profession: "",
  content: "",
  image: "",
};

const esewaPaymentServicesInitialValues = {
  endPoint: "",
  successfulPaymentRedirectURL: "",
  paymentFailureRedirectURL: "",
  paymentVerificationURL: "",
  merchentCode: "",
};

const socialMediaInitialValues = {
  name: "",
  url: "",
  logo: "",
};

const restaurantTableInitialValues = {
  title: "",
  capacity: "",
  floor: "",
  note: "",
};
const restaurantFloorInitialValues = {
  title: "",
  note: "",
};
const terminalInitialValues = {
  name: "",
  printer: "",
  note: "",
  ip: "",
};
const screenInitialValues = {
  name: "",
  noOfCats: "",
  noOfProducts: "",
};
const productPrintOrdersInitialValues = {
  name: "",
  productOrder: "",
};

const promotionInitialValues = {
  title: "",
  regularPrice: 0,
  promoPrice: "",
  products: [],
};

const bookingTableInitialValue = {
  bookingDate: "",
  bookingTime: "",
  noOfPeople: "",
  selectManuallyPerson: "",
  table: [],
};

const customerTableInitialValue = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  note: "",
  acceptTermsAndConditions: true,
  receiveEmails: true,
  customer: "",
};

export {
  bookingTableInitialValue,
  customerTableInitialValue,
  productInitialValues,
  categoryInitialValues,
  productAttributeinitialValues,
  quickSaleReportInitialValues,
  wastageAndLossReportInitialValues,
  noSaleReportInitialValues,
  employeeReportInitialValues,
  attributeValueInitialValues,
  stockReportInitialValues,
  membersReportInitialValues,
  posScreenProductInitialValues,
  modifierInitialValues,
  taxInitialValues,
  posScreenBarCodeInitialValues,
  posScreenPriceInitialValues,
  posScreenStockInitialValues,
  wareHouseInitialValues,
  supplierInitialValues,
  userInitialValues,
  purchaseStockInitialValues,
  supplierPaymentInitialValues,
  addExpenseInitialValues,
  acceptPaymentInitialValues,
  carouselInitialValues,
  salesDiscountInitialValues,
  voucherDiscountInitialValues,
  giftCardDiscountInitialValues,
  appPageInitialValues,
  createPeriodicTasksInitialValues,
  emailInitialValues,
  testimonialInitialValues,
  esewaPaymentServicesInitialValues,
  socialMediaInitialValues,
  restaurantTableInitialValues,
  restaurantFloorInitialValues,
  terminalInitialValues,
  posScreenInitialValues,
  serviceChargeInitialValues,
  detailSaleReportInitialValues,
  productPrintOrdersInitialValues,
  promotionInitialValues,
  dataSyncInitialValues,
  hourlySaleReportInitialValues,
  screenInitialValues,
};
