import React, { useEffect, useState } from "react";
import Modal from "../reusable/Modal";
import DiscountForm from "../PosScreenType/AustraliaPosScreen/PosScreenCartSection/DiscountUI/DiscountForm";
import {
  totalCalculationVar,
  totalDiscountValueCache,
  totalDiscountValueVar,
} from "../localstate/Cache";
import { useReactiveVar } from "@apollo/client";
import { GET_TOTAL_DISCOUNT } from "../localstate/Cart";

type Props = {
  openDiscountPopup: boolean;
  setOpenDiscountPopup: any;
  setSelectDiscountType: any;
  selectDiscountType: any;
};

const PosScreenDiscountFunction = (props: Props) => {
  const {
    openDiscountPopup,
    setOpenDiscountPopup,
    setSelectDiscountType,
    selectDiscountType,
  } = props;
  const totalAmtCalculations: any = useReactiveVar(totalCalculationVar);

  useEffect(() => {
    totalDiscountValueCache.writeQuery({
      query: GET_TOTAL_DISCOUNT,
      data: {
        totalDiscountSurChargeAmount: totalDiscountValueVar(selectDiscountType),
      },
    });
  }, [selectDiscountType]);
  return (
    <Modal
      title={""}
      open={openDiscountPopup}
      setOpen={setOpenDiscountPopup}
      children={
        <DiscountForm
          selectDiscountType={selectDiscountType}
          setSelectDiscountType={setSelectDiscountType}
          open={openDiscountPopup}
          setOpen={setOpenDiscountPopup}
          totalAmtCalculations={totalAmtCalculations}
        />
      }
    />
  );
};

export default PosScreenDiscountFunction;
