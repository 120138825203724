import { useEffect, useState } from "react";

import PosScreenTableList from "../PosScreenType/PosScreenTableList/PosScreenTableList";
import Modal from "../reusable/Modal";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  cartItemsVar,
  kitchenOrderItemId,
  openTableCache,
  openTableVar,
  orderTypeCache,
  orderTypeVar,
  tableCache,
  tableItemsVar,
} from "../localstate/Cache";
import { GET_ORDER_TYPE, GET_TABLE_ITEMS } from "../localstate/Cart";

import {
  CREATE_SUB_TABLE,
  DISABLE_SUB_TABLE,
  MEREGE_RESTAURANT_TABLE,
  SPLIT_RESTAURANT_TABLE,
  SWAP_RESTAURANT_TABLE,
} from "../../pages/mutations";
import { toast } from "react-toastify";
import { backendErrorDisplay } from "../../utils/backendErrorDisplay";
import TableIcon from "../../assets/table.svg";
import OrderType from "../../assets/orderType.svg";
import {
  DELIVER_METHODS,
  GET_RESTAURANT_FLOOR,
  QUERY_RESTAURANT_FLOOR,
} from "../../pages/queries";
import usePosScreenHandleClear from "../../customhooks/usePosScreenHandleClear";
import { useNavigate } from "react-router";
import {
  getLocalKey,
  removeLocalKey,
  setLocalKey,
} from "../../helpers/sessionKey";
import useHandleToggleSelectedTable from "../../customhooks/useHandleToggleSelectedTable";
import classNames from "../../utils/classNames";
import { debounce } from "lodash";
import PosScreenOrderList from "../OrderType/PosScreenOrderList";
import { QUERY_ORDER_TYPE_LAYOUT } from "../../pages/PosScreen/queries";
import DataLoadSpinner from "../reusable/Spinner/DataLoadSpinner";

type Props = {
  selectedTerminal: any;
  openOrderType: any;
  setOpenPopup: any;
};
const PosScreenTableFunction = (props: Props) => {
  const { selectedTerminal, openOrderType, setOpenPopup } = props;
  const navigate = useNavigate();

  const [openMergeTable, setOpenMergeTable] = useState<boolean>(false);
  const [openSwapTable, setOpenSwapTable] = useState<boolean>(false);
  const [openSplitTable, setOpenSplitTable] = useState<boolean>(false);
  const [openSplitSubTableList, setOpenSplitSubTableList] =
    useState<boolean>(false);

  //Floor
  const { data, loading: isFloorLoading } = useQuery(QUERY_RESTAURANT_FLOOR);
  const [floorId, setFloorId] = useState<string>(
    data?.floors?.edges[0]?.node?.id,
  );
  const [floorPk, setFloorPk] = useState<string>(
    data?.floors?.edges[0]?.node?.pk,
  );
  const [quantity, setQuantity] = useState<any>();

  const [tableId, setTableId] = useState<string>("");
  const [selectedTableArray, setSelectedTableArray] = useState<any>([]);
  const [selectedSwapTable, setSelectedSwapTable] = useState<string>("");

  const [openOrderTypeList, setOpenOrderTypeList] = useState<boolean>(false);

  const { handleClearPosScreen } = usePosScreenHandleClear();
  const [kitchenPrintAlertPopup, setKitchenPrintAlertPopup] = useState(false);

  const [mergeTable] = useMutation(MEREGE_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [createSubTable, { loading: splitloading }] = useMutation(
    CREATE_SUB_TABLE,
    {
      refetchQueries: [
        {
          query: GET_RESTAURANT_FLOOR,
          variables: {
            id: floorId,
          },
        },
      ],
    },
  );

  const [disableSubTable] = useMutation(DISABLE_SUB_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [unmergeTable] = useMutation(SPLIT_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [swapTable] = useMutation(SWAP_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const openTable: any = useReactiveVar(openTableVar);
  let modItems: any = cartItems?.filter(
    (item: any) => item?.isModified === true,
  );
  const handleOpenTable = () => {
    if (
      modItems?.length &&
      selectedTerminal?.terminal?.terminalsetting?.compulsoryKitchenPrint ===
        true
    ) {
      setKitchenPrintAlertPopup(true);
      setOpenPopup(false);
    } else {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(false),
        },
      });
      setOpenPopup(false);
    }
  };
  const handleKitchenClear = (e: any) => {
    handleClearPosScreen();
    e.stopPropagation();
    setKitchenPrintAlertPopup(false);
  };

  const handleMergeTable = async () => {
    if (selectedTableArray?.length >= 2) {
      const response = await mergeTable({
        variables: {
          toBeMergeTableId: selectedTableArray,
        },
      });
      if (response.data?.mergeRestaurantTable?.success) {
        toast("Tables Merged Successfully");
        setSelectedTableArray([]);
        setOpenMergeTable(false);
        handleClearPosScreen();
      } else {
        backendErrorDisplay(response.data?.mergeRestaurantTable?.errors);
      }
    } else {
      toast.error("Select more than one table to merge");
    }
  };

  const handleSwapTable = async () => {
    const response = await swapTable({
      variables: {
        fromTable: tableId,
        toTable: selectedSwapTable,
      },
    });
    if (response.data?.swapRestaurantTable?.success) {
      toast("Tables Swapped Successfully");
      setOpenSwapTable(false);
    } else {
      backendErrorDisplay(response.data?.swapRestaurantTable?.errors);
    }
  };

  const handleSplitTable = async (tableId: any) => {
    const response = await unmergeTable({
      variables: {
        toBeSplitTableId: tableId,
      },
    });

    if (response.data?.splitRestaurantTable?.success) {
      toast("Tables Splited Successfully");
      handleClearPosScreen();
    } else if (response.data?.splitRestaurantTable?.errors) {
      backendErrorDisplay(response.data?.splitRestaurantTable?.errors);
    } else {
      toast.info(response.data?.splitRestaurantTable?.messages);
    }
  };
  const handleSplitSubTable = async (tableId: any, quantity: any) => {
    setOpenSplitTable(false);
    const response = await createSubTable({
      variables: {
        id: tableId,
        quantity: parseInt(quantity),
      },
    });
    if (response.data?.createSubTable?.success) {
      toast("Tables Splited in Sub Table Successfully");
      setQuantity("");
      handleClearPosScreen();
    } else if (response.data?.createSubTable?.errors) {
      backendErrorDisplay(response.data?.createSubTable?.errors);
    } else {
      toast.info(response.data?.createSubTable?.messages);
    }
  };
  const handleSplitDisableSubTable = async (tableId: any) => {
    const response = await disableSubTable({
      variables: {
        id: tableId,
      },
    });

    if (response.data?.disableSubTable?.success) {
      toast("Tables Splited in Sub Table Disabled Successfully");
      handleClearPosScreen();
    } else {
      backendErrorDisplay(response.data?.createSubTable?.errors);
    }
  };
  const debouncedHandleClick = debounce(handleSplitSubTable, 300);

  //Deliver Methods
  const { data: deliveryMethods } = useQuery(DELIVER_METHODS, {
    variables: {
      first: 98,
      offset: 0,
      title_Icontains: "",
    },
    fetchPolicy: "network-only",
  });
  const [customerPopup, setCustomerPopup] = useState<any>(false);

  const { data: orderLayoutData, loading: orderLayoutLoading } = useQuery(
    QUERY_ORDER_TYPE_LAYOUT,
    {
      variables: {},
      fetchPolicy: "network-only",
    },
  );

  const handleSelectOrderType = (item: any) => {
    setLocalKey("disableRemotePrint", "false");
    if (item?.isRedirectToCustomers) {
      navigate("/pos-screen-customers", {
        state: "fromOrderTypeMustSelectCustomerCondition",
      });
    }
    const ordeTypeList = {
      pk: item?.pk,
      title: item?.title,
    };
    orderTypeCache.writeQuery({
      query: GET_ORDER_TYPE,
      data: {
        orderType: orderTypeVar(ordeTypeList),
      },
    });
    setOpenOrderTypeList(false);
    setOpenPopup(false);
  };

  return (
    <>
      {openOrderType === "table" && (
        <PosScreenTableList
          setOpenTableList={handleOpenTable}
          openSplitTable={openSplitTable}
          setOpenSplitTable={setOpenSplitTable}
          openMergeTable={openMergeTable}
          setOpenMergeTable={setOpenMergeTable}
          selectedSwapTable={selectedSwapTable}
          setSelectedSwapTable={setSelectedSwapTable}
          openSwapTable={openSwapTable}
          setOpenSwapTable={setOpenSwapTable}
          selectedTableArray={selectedTableArray}
          setSelectedTableArray={setSelectedTableArray}
          setTableId={setTableId}
          tableId={tableId}
          handleMergeTable={handleMergeTable}
          handleSwapTable={handleSwapTable}
          handleSplitTable={handleSplitTable}
          debouncedHandleClick={debouncedHandleClick}
          splitloading={splitloading}
          quantity={quantity}
          setQuantity={setQuantity}
          handleSplitDisableSubTable={handleSplitDisableSubTable}
          openSplitSubTableList={openSplitSubTableList}
          setOpenSplitSubTableList={setOpenSplitSubTableList}
          floorId={floorId}
          setFloorId={setFloorId}
          setFloorPk={setFloorPk}
          floorPk={floorPk}
          isFloorLoading={isFloorLoading}
          data={data}
        />
      )}
      {openOrderType === "order_type" && (
        <div className="w-full p-0 m-0">
          {orderLayoutLoading ? (
            <DataLoadSpinner />
          ) : (
            <div>
              <PosScreenOrderList
                deliveryMethods={deliveryMethods}
                handleSelectOrderType={handleSelectOrderType}
                orderType={orderType}
                orderLayoutData={orderLayoutData?.orderTypeLayout?.layout}
              />
            </div>
          )}
        </div>
      )}

      <Modal
        open={customerPopup}
        setOpen={setCustomerPopup}
        title={"Select Customer"}
        size={"max-w-7xl"}
        children={"Customer Section"}
      />
      <Modal
        title={"Kitchen Print Alert !!"}
        open={kitchenPrintAlertPopup}
        setOpen={setKitchenPrintAlertPopup}
        children={
          <>
            <p className="p-3">Do You Want to Clear??</p>
            <button
              className="bg-primary text-white w-20 h-10 rounded mt-2"
              onClick={(e: any) => handleKitchenClear(e)}
            >
              Clear
            </button>
          </>
        }
      />
    </>
  );
};
export default PosScreenTableFunction;
