import React, { useEffect, useState } from "react";
import Modal from "../reusable/Modal";
import SuchargeForm from "../PosScreenType/AustraliaPosScreen/PosScreenCartSection/SuchargeUI/SuchargeForm";
import { useReactiveVar } from "@apollo/client";
import {
  totalCalculationVar,
  totalSurchargeValueCache,
  totalSurchargeValueVar,
} from "../localstate/Cache";
import { GET_TOTAL_SURCHARGE } from "../localstate/Cart";

type Props = {
  openSuchargePopup: any;
  setOpenSuchargePopup: any;
};

const PosScreenSurchargeFunction = (props: Props) => {
  const { openSuchargePopup, setOpenSuchargePopup } = props;
  const [selectSuchargeType, setSelectSuchargeType] = useState("surchargePer");
  const totalAmtCalculations = useReactiveVar(totalCalculationVar);

  useEffect(() => {
    totalSurchargeValueCache.writeQuery({
      query: GET_TOTAL_SURCHARGE,
      data: {
        totalDiscountSurChargeAmount:
          totalSurchargeValueVar(selectSuchargeType),
      },
    });
  }, [selectSuchargeType]);
  
  return (
    <Modal
      title={""}
      open={openSuchargePopup}
      setOpen={setOpenSuchargePopup}
      children={
        <SuchargeForm
          selectSuchargeType={selectSuchargeType}
          setSelectSuchargeType={setSelectSuchargeType}
          open={openSuchargePopup}
          setOpen={setOpenSuchargePopup}
          totalAmtCalculations={totalAmtCalculations}
        />
      }
    />
  );
};

export default PosScreenSurchargeFunction;
