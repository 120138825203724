import { Form, Formik } from "formik";
import React, { useState } from "react";
import { posScreenQuickProductFormValidationSchema } from "../../../utils/formValidation";
import PosScreenProductForm from "./PosScreenProductForm";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
import { POS_SCREEN_CREATE_PRODUCT } from "../../../pages/Product/mutation";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { toast } from "react-toastify";
import {
  cache,
  cartItemsVar,
  disableFocusCache,
  disableFocusVar,
  searchCache,
  searchItemsVar,
  searchSelectedItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_DISABLE_FOCUS,
  GET_SEARCH_ITEMS,
} from "../../localstate/Cart";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { setLocalKey } from "../../../helpers/sessionKey";
import { CURRENT_TERMINAL } from "../../../pages/PosScreen/queries";

const PosScreenSearchPluTab = (props: any) => {
  const {
    noOfColumns,
    searchProductPluData,
    categoryDropdown,
    taxTypeDropdown,
    unitDropdown,
    printerListsDropdown,
    modifierCategoryDropdown,
  } = props;
  const cartItems: [] = useReactiveVar(cartItemsVar);
  const [productWithModCategoryList, setProductWithModCategoryList] = useState(
    []
  );
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL);

  const [scaleValues, setScaleValues] = useState();
  const [openProductWithModCategory, setOpenProductWithModCategory] =
    useState<boolean>(false);
  const enableWightScale: any =
    currentTerminalData?.currentTerminal?.terminalsetting?.isActiveScale;
  const [isWeightableProduct, setIsWeightableProduct] =
    useState<boolean>(false);
  const [isScaleNotConnected, setIsScaleNotConnected] =
    useState<boolean>(false);
  const [scaleErrorMessage, setScaleErrorMessage] = useState<string>();
  const scaleInitialValues = {
    baudRate:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleBaudRate,
    dataBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleDateBits,
    parity: currentTerminalData?.currentTerminal?.terminalsetting?.scaleParity,
    port: currentTerminalData?.currentTerminal?.terminalsetting?.scalePort,
    stopBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleStopBits,
  };
  const [isOpenPriceProducts, setIsOpenPriceProducts] =
    useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const scaleValue = JSON.stringify(scaleInitialValues);
  const handleClick = (item: any) => {
    if (item?.isWeightable) {
      if (item?.isOpenPriceProduct) {
        setIsOpenPriceProducts(true);
        setItem(item);
      }
      if (!enableWightScale) {
        setItem(item);
        setIsWeightableProduct(true);
      } else {
        setItem(item);
      }
      setLocalKey("disableRemotePrint", "false");
    } else if (item?.isOpenPriceProduct) {
      setIsOpenPriceProducts(true);
      setItem(item);
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        contentTypeId: item?.contentTypeId,
        title: item?.title,
        quantity: 1,
        price: item?.finalPrice ?? 0,
        total: item?.finalPrice ?? 0,
        tax: item?.taxAmount,
        taxRate: {
          rate: item?.tax?.rate ?? 0,
          isIncluded: item?.tax?.isIncluded,
        },
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
      };
      let previousObject: any = cartItems.find(
        (x: any) => x.id === item?.id && x.isSelected === true
      );

      if (previousObject && previousObject.isSelected) {
        let newItemList: any = cartItems;

        let foundIndex: any = cartItems.findIndex(
          (x: any) => x.id === item?.id && x.isSelected === true
        );

        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseInt(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].total =
          item?.finalPrice > 0
            ? parseFloat(item?.finalPrice) * parseFloat(previousObject.quantity)
            : 0;
        newItemList[foundIndex].tax =
          parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);
        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                  ...item,
                  isSelected: false,
                }
              : item
        );
        handlePromoItem(updateNewItemList, item);
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([...updateNewItemList]),
          },
        });
        const searchList = {
          searchInput: "",
          name: "",
        };
        searchCache.writeQuery({
          query: GET_SEARCH_ITEMS,
          data: {
            searchItems: searchItemsVar(searchList),
          },
        });
      } else {
        let nonPreviousItemList = cartItems?.map(
          (item: any, index: number) => ({
            ...item,
            isSelected: false,
          })
        );
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([
              ...nonPreviousItemList,
              productInCartList,
            ]),
          },
        });
        handlePromoItem([...nonPreviousItemList, productInCartList], item);
        const searchList = {
          searchInput: "",
          name: "",
        };
        searchCache.writeQuery({
          query: GET_SEARCH_ITEMS,
          data: {
            searchItems: searchItemsVar(searchList),
          },
        });
      }
    }
  };
  const handlePromoItem = (updateNewItemList: any, newItem: any) => {
    let finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
    const promoInfo = newItem?.promotionInfo;
    let match: boolean = false;
    let promotion: any = null;
    let applicablePromotion: any = [];
    if (promoInfo.length) {
      for (
        let promoInfoIndex = 0;
        promoInfoIndex < promoInfo.length;
        promoInfoIndex++
      ) {
        const promotionItem = promoInfo[promoInfoIndex];
        if (promotionItem.compound.length) {
          const everyItemExists = promotionItem.compound.every((item: any) =>
            finalCartItems.some((elem: any) => elem.id === item.id)
          );
          if (everyItemExists) {
            promotion = { ...promotionItem };
            applicablePromotion.push(promotionItem?.compound);
          }
        }
      }
    }

    if (applicablePromotion.length > 0) {
      let lastMatchCompountItem = applicablePromotion.pop();
      lastMatchCompountItem?.map((item: any) => {
        let foundItemIndex = finalCartItems.findIndex(
          (cartItem: any) => cartItem.id == item.id && cartItem.quantity > 0
        );
        if (foundItemIndex >= 0) {
          finalCartItems[foundItemIndex]["quantity"] -= 1;
          finalCartItems[foundItemIndex]["total"] =
            finalCartItems[foundItemIndex]["quantity"] *
            parseFloat(finalCartItems[foundItemIndex]["price"]);
          match = true;
        } else {
          match = false;
        }
      });
    }

    if (match) {
      let productInCartList: any = {
        id: promotion?.id,
        pk: promotion?.pk,
        title: promotion?.title,
        quantity: 1,
        price: promotion?.sellingPrice,
        total: promotion?.sellingPrice,
        tax: promotion?.taxAmount ?? "0.00",
        taxRate: {
          rate: promotion?.tax?.rate ?? 0,
          isIncluded: promotion?.tax?.isIncluded,
        },
        isSelected: false,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierCategory: promotion?.modifierCategory,
      };
      let finalItems = finalCartItems?.filter((item: any) => item.quantity > 0);
      toast.success("Promotion Found");
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...finalItems, productInCartList]),
        },
      });
    } else {
      finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...updateNewItemList]),
        },
      });
    }
  };
  const handleModifierClick = (item: any) => {
    if (!item) {
      toast.info("Please select non-empty modifiers");
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === item?.id
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === item?.id
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) + parseFloat(previousObject.total)
              : 0;
          perviousModifierItemList[itemIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        } else {
          let productInCartList: any = cartItems;
          prevModifierList.push({
            id: item?.id,
            pk: item?.pk,
            contentTypeId: item?.contentTypeId,
            title: item?.title,
            price: item?.finalPrice ?? 0,
            quantity: 1,
            total: item?.finalPrice ?? 0,
            tax: item?.taxAmount,
            taxRate: {
              rate: item?.tax?.rate ?? 0,
              isIncluded: item?.tax?.isIncluded,
            },
            isDeleted: false,
            randId: Math.random().toString(36).substr(2, 5),
          });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        }
      } else {
        if (item) {
          toast.info("Please select product");
        } else {
          toast.info("Please select non-empty modifiers");
        }
      }
    }
  };

  const handleProductWithModCategory = (item: any) => {
    setOpenProductWithModCategory(true);
    setProductWithModCategoryList(item);
  };
  const searchSelectedItems: any = useReactiveVar(searchSelectedItemsVar);

  const handleProductClick = async (item: any) => {
    if (item)
      if (!item?.isModifier) {
        if (item?.modifierCategories?.edges?.length) {
          handleProductWithModCategory(item);
        } else {
          handleClick(item);
        }
      } else {
        handleModifierClick(item);
      }
  };

  //Add quick Product
  const [addProductPopup, setAddProductPopup] = useState<boolean>(false);
  const productInitialValues = {
    title: "",
    category: [],
    unit: unitDropdown[0]?.id,
    isActive: true,
    costPrice: 0,
    sellingPrice: "",
    taxType: taxTypeDropdown[0]?.id,
    barcode: "",
    plu: "",
    printer: [],
    isWeightable: false,
    modifierCategories: [],
    isOpenPriceProduct: false,
    isModifierCategoryGrid: false,
  };

  //Handle Quick Add Product
  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT
  );
  const handleCreateProduct = async (values: any, props: any) => {
    const response = await productCreate({
      variables: {
        // posScreenProductId: productId.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType === "0" ? null : values?.taxType,
        isModifier: false,
        isWeightable: values?.isWeightable,
        printOrder: null,
        threshold: parseFloat(values?.threshold) ?? 0,
        stock: parseFloat(values?.stock) ?? 0,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategories ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        promotionCategory: values?.subCategory,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      setAddProductPopup(false);
      let productInCartList = {
        id: response?.data?.createProduct?.product?.id,
        pk: response?.data?.createProduct?.product?.pk,
        contentTypeId: response?.data?.createProduct?.product?.contentTypeId,
        title: response?.data?.createProduct?.product?.title,
        quantity: 1,
        price: response?.data?.createProduct?.product?.finalPrice ?? 0,
        total: response?.data?.createProduct?.product?.finalPrice ?? 0,
        tax: response?.data?.createProduct?.product?.taxAmount,
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
      };

      let nonPreviousItemList = cartItems?.map((item: any, index: number) => ({
        ...item,
        isSelected: false,
      }));
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...nonPreviousItemList, productInCartList]),
        },
      });
      const searchList = {
        searchInput: "",
        name: "",
      };
      searchCache.writeQuery({
        query: GET_SEARCH_ITEMS,
        data: {
          searchItems: searchItemsVar(searchList),
        },
      });
      disableFocusCache.writeQuery({
        query: GET_DISABLE_FOCUS,
        data: {
          disableFocus: disableFocusVar(true),
        },
      });
    } else {
      const errors = backendErrorDisplay(response?.data?.createProduct?.errors);
      props.setErrors(errors);
    }
  };

  const handleNavigate = () => {
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };
  return (
    <>
      {searchProductPluData?.searchUsingPlu !== null ? (
        <div
          className={`grid w-full grid-cols-${noOfColumns} p-4 grid-rows-7 gap-2 border-2`}
        >
          <React.Fragment key={searchProductPluData?.searchUsingPlu?.id}>
            <div
              id={`selectedItem_${searchProductPluData?.searchUsingPlu?.id}`}
              className={`relative h-[80px] cursor-pointer border-2 border-solid border-gray-300 rounded-lg
                  `}
              onClick={() =>
                handleProductClick(searchProductPluData?.searchUsingPlu)
              }
            >
              {searchProductPluData?.searchUsingPlu?.image ? (
                <>
                  <img
                    src={searchProductPluData?.searchUsingPlu?.image}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <span
                    title={searchProductPluData?.searchUsingPlu?.title}
                    className={`${
                      !searchProductPluData?.searchUsingPlu?.isModifier
                        ? ""
                        : "text-red-500"
                    } textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs  text-left px-1`}
                  >
                    {searchProductPluData?.searchUsingPlu?.title}
                  </span>
                </>
              ) : (
                <div>
                  <img
                    src={searchProductPluData?.searchUsingPlu?.image}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <span
                    title={searchProductPluData?.searchUsingPlu?.title}
                    className={`${
                      !searchProductPluData?.searchUsingPlu?.isModifier
                        ? ""
                        : "text-red-500"
                    } textWithTwoLine absolute top-[20%] w-full bg-gray-200  text-left font-bold px-1`}
                  >
                    {searchProductPluData?.searchUsingPlu?.title}
                  </span>
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
      ) : (
        <div className="w-full px-6 shadow bg-[#ffffff]">
          <div className="text-lg font-semibold text-black text-center p-4">
            Add Product
          </div>
          <Formik
            initialValues={productInitialValues}
            validationSchema={posScreenQuickProductFormValidationSchema}
            onSubmit={handleCreateProduct}
          >
            {({ touched, values, setFieldValue, errors, handleChange }) => (
              <Form>
                <PosScreenProductForm
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  unitData={unitDropdown ?? []}
                  taxTypeDropdown={taxTypeDropdown ?? []}
                  printerListsDropdown={printerListsDropdown ?? []}
                  modifierCategoryDropdown={modifierCategoryDropdown ?? []}
                />
                <div className="flex flex-wrap justify-between mb-4 p-4">
                  <PrimaryButton
                    type={"button"}
                    onClick={handleNavigate}
                    text={"Back"}
                    loading={false}
                    disable={false}
                  />
                  <PrimaryButton
                    text={"Submit"}
                    loading={loadingCreateProduct ? true : false}
                    disable={loadingCreateProduct}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default PosScreenSearchPluTab;
