import _ from "lodash";
import React, { useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { toast } from "react-toastify";
import OrderTypeColorPicker from "../reusable/ColorPicker/OrderTypeColorPicker";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER_TYPE_LAYOUT } from "../../pages/PosScreen/mutation";
import { QUERY_ORDER_TYPE_LAYOUT } from "../../pages/PosScreen/queries";

type Props = {
  deliveryMethods: any;
  orderType: any;
  handleSelectOrderType: any;
  orderLayoutData: any;
};

const PosScreenOrderList = (props: Props) => {
  const { deliveryMethods, orderType, handleSelectOrderType, orderLayoutData } =
    props;
  const [editAbleState, setEditAbleState] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [openContextPopup, setOpenContextPopup] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");

  const orderTypeLayout = JSON.parse(orderLayoutData ?? "[]");

  const initialLayout = React.useMemo(
    () =>
      deliveryMethods?.deliveryMethods?.edges?.map(
        (item: any, index: number) => {
          return {
            i: item?.node?.id,
            x: 1,
            y: 3,
            w: 2,
            h: 1,
            title: item?.node?.title,
            pk: item?.node?.pk,
            isRedirectToCustomers: item?.node?.isRedirectToCustomers,
            color: "",
          };
        },
      ) || [],
    [deliveryMethods],
  );

  const [layout, setLayout] = useState(
    orderTypeLayout.length > 0 ? orderTypeLayout : initialLayout,
  );
  useEffect(() => {
    if (deliveryMethods) {
      const newLayout = deliveryMethods.deliveryMethods.edges.map(
        (item: any, index: number) => ({
          i: item.node.id,
          x: 1,
          y: 3,
          w: 2,
          h: 1,
          title: item.node.title,
          pk: item.node.pk,
          isRedirectToCustomers: item.node.isRedirectToCustomers,
        }),
      );
      const filteredLayout = orderTypeLayout.filter((existingItem: any) =>
        newLayout.some((item: any) => item?.i === existingItem?.i),
      );
      const updatedLayout = [
        ...filteredLayout,
        ...newLayout?.filter(
          (newItem: any) =>
            !filteredLayout?.some(
              (existingItem: any) => existingItem?.i === newItem?.i,
            ),
        ),
      ];
      setLayout(updatedLayout);
    }
  }, [deliveryMethods]);

  useEffect(() => {
    if (orderTypeLayout.length === 0 && initialLayout) {
      setLayout(initialLayout);
    }
  }, [initialLayout]);

  const [createOrderTypeLayout] = useMutation(CREATE_ORDER_TYPE_LAYOUT, {
    refetchQueries: [
      {
        query: QUERY_ORDER_TYPE_LAYOUT,
        variables: {},
      },
    ],
  });

  const handleLayoutChange = (newLayout: any, selectColor: any = "") => {
    const selectColorType = typeof selectColor === "object";
    const updatedLayout = newLayout.map((item: any) => {
      const existingLayoutItem = layout?.find((l: any) => l?.i === item?.i);
      return {
        ...existingLayoutItem,
        ...item,
        maxH: 3,
        maxW: 3,
        minH: 1,
        minW: 1,
        color:
          !selectColorType && existingLayoutItem?.pk === selectedOrderId
            ? selectColor || existingLayoutItem?.color
            : existingLayoutItem?.color,
      };
    });
    setLayout(updatedLayout);
  };

  const handleAddColor = (color: any) => {
    handleLayoutChange(layout, color);
    setDisplayColorPicker(false);
  };

  const handleEditLayout = async () => {
    setOpenContextPopup(false);
    setEditAbleState((prev) => !prev);
    if (editAbleState) {
      const response = await createOrderTypeLayout({
        variables: {
          layout: JSON.stringify(layout),
        },
      });
      if (response?.data?.createUpdateOrderLayout?.success) {
        toast.success("Update Order Type Layout Successfully");
      } else {
        toast.error("failed");
      }
    }
  };

  const handleClickContext = (e: any, value: any) => {
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      setSelectedOrderId(value);
      setOpenContextPopup((pre: any) => !pre);
    }
  };

  const handleQuickAddColor = (e: any) => {
    e.stopPropagation();
    setDisplayColorPicker(true);
    setOpenContextPopup(false);
  };

  const generateDOM = () => {
    return _.map(layout, (value: any) => {
      const isSelected = orderType?.pk === value?.pk;
      return (
        <div
          key={value?.i}
          className="flex py-3 w-full justify-center items-center rounded cursor-pointer"
          style={{
            background: isSelected
              ? "#4338CA"
              : value?.color
              ? value?.color
              : "#efefef",
            color: isSelected ? "#ffffff" : "",
          }}
          onContextMenu={(e) => {
            handleClickContext(e, value?.pk);
          }}
          onClick={() => {
            !editAbleState && handleSelectOrderType(value);
          }}
          onMouseLeave={() => setOpenContextPopup(false)}
        >
          {value?.title}
          {editAbleState &&
            selectedOrderId === value?.pk &&
            openContextPopup && (
              <div
                className="absolute top-0 right-[-110px] bg-[#e0e0e0] px-6 py-2 shadow-md rounded"
                onClick={(e) => {
                  handleQuickAddColor(e);
                }}
              >
                Add Color
              </div>
            )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="w-full  relative bg-white shadow border-2">
        <GridLayout
          className="layout w-full h-full"
          layout={layout}
          style={{ height: "540px" }}
          rowHeight={80}
          width={1400}
          isResizable={editAbleState}
          isDraggable={editAbleState}
          compactType={null}
          isDroppable={true}
          isBounded={true}
          onDragStop={handleLayoutChange}
          onResizeStop={handleLayoutChange}
          preventCollision={true}
        >
          {generateDOM()}
        </GridLayout>
      </div>
      {!orderType?.title && (
        <span className="flex justify-end">
          <button
            onClick={handleEditLayout}
            className="bg-[#4338CA] p-2 text-white rounded mt-4"
          >
            {editAbleState ? "Save layout" : "Edit layout"}
          </button>
        </span>
      )}
      <OrderTypeColorPicker
        displayColorPicker={displayColorPicker}
        setDisplayColorPicker={setDisplayColorPicker}
        handleAddColor={handleAddColor}
      />
    </>
  );
};

export default PosScreenOrderList;
