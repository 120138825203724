import React, { useEffect, useState } from "react";
import randomString from "../../../utils/randomString";
import { IHTMLFieldProps } from "./interface";
import CountryPhoneField from "./countryPhoneField";
// @ts-ignore
import Cleave from "cleave.js/react";
import { parsePhoneNumber } from "libphonenumber-js";
import { countryPhoneNumber } from "../../../utils/countryPhoneNumbers";

const MobileNumberField: React.FC<IHTMLFieldProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    helperText,
    disabled = false,
    placeHolder,
    onBlur,
  } = props;

  const id = randomString(10, "-password-field");
  const [countryPhoneCode, setCountryPhoneCode] = useState<any>("NP");
  const [rawPhoneNumber, setRawPhoneNumber] = useState<any>(null);

  const handleOnChange = (value: string) => {
    setRawPhoneNumber(value);
  };

  useEffect(() => {
    setFieldValue(name, "");
    if (rawPhoneNumber) {
      setFieldValue(
        name,
        `${countryPhoneCode?.dial_code}${rawPhoneNumber ?? ""}`,
      );
    }
  }, [rawPhoneNumber, countryPhoneCode]);

  useEffect(() => {
    try {
      const phoneNumber = parsePhoneNumber(values[name]);
      setRawPhoneNumber(phoneNumber.nationalNumber);
      if (phoneNumber?.country) {
        const found: any = countryPhoneNumber.find(
          (elem: any) => elem.code === phoneNumber?.country,
        );
        if (found !== "undefined") {
          setCountryPhoneCode(found);
        }
      }
    } catch (e) {}
  }, [values]);

  return (
    <div className="mb-6 w-full">
      <div className="relative flex justify-left">
        <div>
          <CountryPhoneField
            disabled={disabled}
            selectState={countryPhoneCode}
            setSelectState={setCountryPhoneCode}
            values={values}
          />
        </div>
        <Cleave
          id={id}
          type="tel"
          name={name}
          options={{
            numericOnly: true,
          }}
          className="relative peer bg-gray-200 p-2 rounded w-full ml-4 text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
          placeholder={placeHolder ?? " "}
          value={rawPhoneNumber}
          disabled={disabled}
          onChange={(e: any) => handleOnChange(e.target.value)}
          onBlur={onBlur}
          autoComplete={"off"}
        />
        <label
          htmlFor={id}
          className="absolute left-[125px] -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          {label}
        </label>
      </div>
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500 text-left">{errors[name]}</p>
      )}

      <p className="text-xs text-blue-500 absolute  -bottom-[18px]">
        {helperText}
      </p>
    </div>
  );
};

export default MobileNumberField;
