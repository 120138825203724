import { useQuery } from "@apollo/client";
import { useState } from "react";
import Loadable from "react-loadable";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { POS_KITCHEN_ORDER_LISTS } from "../../Orders/queries";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const PosScreenKitchenOrderComponent: any = Loadable({
  loader: () =>
    import("../../../components/Orders/KitchenOrdersList/KitchenOrderList"),
  loading: () => <TableLoader showRightBar={false} />,
});

const PosScreenKitchenOrder = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const [state, setState] = useState("");
  const { loading: kitchenOrderLoading, data } = useQuery(
    POS_KITCHEN_ORDER_LISTS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        orderStatus: state,
        first: pageSize,
        offset: offset,
      },
    },
  );

  return (
    <div className="w-full">
      <PosScreenKitchenOrderComponent
        kitchenOrderLoading={kitchenOrderLoading}
        data={data?.kitchenOrders ?? []}
        isPosScreenKitchenOrder={true}
        state={state}
        setState={setState}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenKitchenOrder;
