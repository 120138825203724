import React from "react";
import randomString from "../../../utils/randomString";

type Props = {
  name: string;
  label?: string;
  values: any;
  touched?: any;
  errors?: any;
  setFieldValue: any;
  onChange?: any;
  handleChange?: any;
  helperText?: string;
};

const CheckBoxField: React.FC<any> = (props: Props) => {
  const { name, label, values, handleChange, errors, touched, helperText } =
    props;
  const id = randomString(10, "-text-field");
  return (
    <>
      <div className="w-full relative rounded flex items-center gap-2">
        <input
          id={name}
          type="checkbox"
          name={name}
          className="w-6 h-4"
          placeholder=" "
          // value={values?.name}
          checked={values[name]}
          onChange={handleChange}
        />
        <label htmlFor={name} className="text-gray-900 text-base">
          {label}
        </label>
      </div>
      {errors && <p className="text-sm text-red-500">{errors[name]}</p>}
      <p className="text-xs text-blue-500 ">{helperText}</p>
    </>
  );
};

export default CheckBoxField;
