import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { POS_KITCHEN_ORDER_LISTS } from "../../Orders/queries";
import { searchItemsVar } from "../../../components/localstate/Cache";

const PosScreenHoldCartComponent: any = Loadable({
  loader: () =>
    import("../../../components/PosScreenHoldCard/PosScreenHoldCard"),
  loading: () => <></>,
});

const PosScreenHoldCart = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);
  const { loading: holdKitchenOrderLoading, data: holdKitchenOrderData } =
    useQuery(POS_KITCHEN_ORDER_LISTS, {
      fetchPolicy: "no-cache",
      variables: {
        orderStatus: "hold",
        first: pageSize,
        offset: offset,
        search: searchItems?.searchInput,
      },
    });

  return (
    <div className="w-full">
      <PosScreenHoldCartComponent
        holdKitchenOrderData={holdKitchenOrderData?.kitchenOrders?.edges ?? []}
        holdKitchenOrderLoading={holdKitchenOrderLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenHoldCart;
