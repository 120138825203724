import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import {
  customerCache,
  CustomerItemsVar,
} from "../../../components/localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../../components/localstate/Cart";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import { ADD_EXPENSES, MAKE_SUPPLIER_PAYMENT } from "../../mutations";
import {
  QUERY_SUPPLIER,
  QUERY_TRANSACTION_ACCOUNTS,
} from "../../queries";
import { QUERY_SETTINGS } from "../../Settings/graphql";
import AccessControl from "../../../helpers/accessControl";
import UserPermissionRequired from "../../Error/UserPermisssionRequired";
import TableLoader from "../../../components/reusable/Loader/TableLoader";
import { GET_CUSTOMER_CREDIT_AMOUNT, QUERY_TRANSACTIONS_LIST } from "../queries";

// import { GET_CUSTOMER_CREDIT_AMOUNT } from "../Customer/grapql";
const TransactionsComponent: any = Loadable({
  loader: () =>
    import("../../../components/PosScreenTransaction/PosScreenTransaction"),
  loading: () => <TableLoader />,
});
const Transactions = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const [value, setValue] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [selecteFilter, setSelecteFilter] = useState<any>("");
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const [selected, setSelected] = useState({
    id: "",
    pk: "",
    name: "",
  });

  const [customerCreditAmount, { data: selectedCustomer }] = useLazyQuery(
    GET_CUSTOMER_CREDIT_AMOUNT,
  );

  // Used for customer cache
  useEffect(() => {
    if (selected?.id) {
      customerCreditAmount({
        variables: {
          id: selected?.id,
        },
      });
      let customerItem = {
        id: selected.id,
        name: selected.name,
        pk: selected.pk,
      };
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar(customerItem),
        },
      });
    }
  }, [selected]);

  const { data, loading: transactionsLoading } = useQuery(
    QUERY_TRANSACTIONS_LIST,
    {
      variables: {
        first: pageSize,
        offset: offset,
        createdGte: value?.startDate
          ? moment(value?.startDate)
              .startOf("day")
              .format("yyyy-MM-DDTHH:mm:ss")
          : null,
        createdLte: value?.endDate
          ? moment(value?.endDate).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
          : null,
        account: selecteFilter ?? "",
        account_IsVisible: true,
      },
    },
  );

  const { data: transactionAccountsData } = useQuery(
    QUERY_TRANSACTION_ACCOUNTS,
    {
      variables: {
        isVisible: true,
      },
    },
  );

  // FOR TRANSACTION ACCOUNT DROP DOWN
  const transactionAccountsList: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsList.push({
      id: elem?.node?.pk,
      title: elem?.node?.name,
    });
  });

  //  FOR TRANSACTION ACCOUNT FILTER
  const transactionAccountsFilter: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsFilter.push({
      value: elem?.node?.id,
      label: elem?.node?.name,
    });
  });

  const [expenses, setExpenses] = useState<boolean>(false);
  const [openAcceptPayment, setOpenAcceptPayment] = useState<boolean>(false);
  const [makePayment, setMakePayment] = useState<boolean>(false);

  const [addExpenses] = useMutation(ADD_EXPENSES, {
    refetchQueries: ["transactions"],
  });

  const handleAddExpenses = async (values: any, props: any) => {
    const response = await addExpenses({
      variables: {
        amount: values.amount,
        note: values.note,
        transactionSource: "pos",
      },
    });
    if (response?.data?.addExpense?.success) {
      setExpenses(false);
      toast.success(response?.data?.addExpense?.message);
    } else {
      let errors = backendErrorDisplay(response.data?.addExpense?.errors);
      props.setErrors(errors);
    }
  };

  const { data: basicSettings } = useQuery(QUERY_SETTINGS);

  return (
    <AccessControl
      allowedPermissions={["can_view_transaction"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <div>
          <TransactionsComponent
            data={data?.transactions}
            transactionsLoading={transactionsLoading}
            setExpenses={setExpenses}
            expenses={expenses}
            openAcceptPayment={openAcceptPayment}
            setOpenAcceptPayment={setOpenAcceptPayment}
            setMakePayment={setMakePayment}
            makePayment={makePayment}
            handleAddExpenses={handleAddExpenses}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setOffset={setOffset}
            offset={offset}
            transactionAccountsList={transactionAccountsList}
            transactionAccountsFilter={transactionAccountsFilter}
            selecteFilter={selecteFilter}
            setSelecteFilter={setSelecteFilter}
            setValue={setValue}
            value={value}
            customerItems={customerItems}
            selected={selected}
            setSelected={setSelected}
            selectedCustomer={selectedCustomer}
            calenderType={
              basicSettings?.basicWebsiteConfigurations?.calendarType
            }
          />
        </div>
      }
    />
  );
};

export default Transactions;
