import {gql} from "@apollo/client";

const GET_EDIT_PRODUCT_TAX_RATE = gql`
  query getEditTaxRateCache {
    taxRate @client
  }
`;

const GET_EDIT_PRODUCT_CALCULATED_PRICE = gql`
  query getCalculatedSellingPrice {
    calculatedSellingPrice @client
  }
`;

const GET_EDIT_MODIFIER_TAX_RATE = gql`
  query getEditTaxRateCache {
    taxRate @clientPosScreenProductForm
  }
`;

const GET_EDIT_MODIFIER_CALCULATED_PRICE = gql`
  query getCalculatedSellingPrice {
    calculatedSellingPrice @client
  }
`;


export {
  GET_EDIT_PRODUCT_TAX_RATE,
  GET_EDIT_PRODUCT_CALCULATED_PRICE,
  GET_EDIT_MODIFIER_TAX_RATE,
  GET_EDIT_MODIFIER_CALCULATED_PRICE
}