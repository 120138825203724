import { Link, Outlet, useLocation } from "react-router-dom";
import DualScreenIcon from "../assets/dualscreen.svg";
import OrderIcon from "../assets/order.svg";
import TransactionIcon from "../assets/transaction.svg";
import CustomerIcon from "../assets/customer.svg";
import RemoteOrderIcon from "../assets/remoteorder.svg";
import CartIcon from "../assets/cart.svg";
import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_SETTINGS } from "../pages/Settings/graphql";
import MiddleNav from "../components/NavigationBar/MiddleNav";
import SearchBar from "../components/NavigationBar/SearchBar";
import isElectron from "../helpers/checkElectron";
import DIcon from "../assets/d.svg";
import SelectLayout from "./SelectLayout";
import PosScreenButtons from "../components/reusable/PosScreenButtons";

const PosScreenLayout = () => {
  const navigation = [
    {
      name: "Pos",
      href: "/",
      icon: (
        <img
          alt=""
          src={DualScreenIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Hold Cart",
      href: "/pos-screen-hold-card",
      icon: (
        <img
          alt=""
          src={CartIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Remote Orders",
      href: "/pos-screen-kitchen-card",
      icon: (
        <img
          alt=""
          src={RemoteOrderIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Orders",
      href: "/pos-screen-order",
      icon: (
        <img
          alt=""
          src={OrderIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Transactions",
      href: "/pos-screen-transaction",
      icon: (
        <img
          alt=""
          src={TransactionIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Customers",
      href: "/pos-screen-customers",
      icon: (
        <img
          alt=""
          src={CustomerIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
  ];
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const location = useLocation();
  return (
    <>
      <div
        className={`${
          isElectron() ? "h-[94vh]" : "h-screen"
        } font-serif text-sm  flex overflow-hidden bg-gray-100`}
      >
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-[50px] bg-[#4338CA] shadow">
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <Link to={window.location.origin}>
                  <img
                    className="h-6 w-auto m-3  cursor-pointer"
                    src={DIcon}
                    alt={basicSetting?.basicWebsiteConfigurations?.title}
                  />
                </Link>
              </div>
              <div className="flex items-center">
                {location.pathname === "/" && (
                  <div className="relative w-full flex gap-2 md:ml-0">
                    <SearchBar
                      name="searchProduct"
                      placeholder="Search Product"
                    />
                    <SearchBar
                      name="searchBarcode"
                      placeholder="Scan Barcode"
                    />
                    <SearchBar name="searchPlu" placeholder="Enter PLU" />
                    <SelectLayout />
                  </div>
                )}
              </div>
            </div>
          </div>
          <main className="flex justify-between relative h-full overflow-y-hidden focus:outline-none">
            <div
              className={
                "flex h-full flex-col text-center items-center justify-evenly bg-[#4338CA] text-xs text-white"
              }
            >
              <PosScreenButtons startWith={"L"} />
            </div>

            <div className="flex-[92%] py-2 overflow-y-auto">
              <div className="w-[96%] h-full mx-auto px-0 sm:px-0 md:px-2">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default PosScreenLayout;
