import { useEffect, useState } from "react";
import randomString from "../../utils/randomString";

import {
  cache,
  cartItemsVar,
  searchCache,
  searchItemsVar,
} from "../localstate/Cache";
import { GET_CART_ITEMS, GET_SEARCH_ITEMS } from "../localstate/Cart";
import PrimaryButton from "../reusable/Buttons/PrimaryButton";
import Modal from "../reusable/Modal";
import { setLocalKey } from "../../helpers/sessionKey";
type Props = {
  item?: any;
  open?: boolean;
  setOpen?: any;
};
export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const PosScreenProWithModCatTrue = (props: Props) => {
  const { item, setOpen } = props;

  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const [openAddQuantityPopup, setOpenAddQuantityPopup] =
    useState<boolean>(false);

  const [enteredNumber, setEnteredNumber] = useState<any>("1");

  const handleCalculate = (data: string) => {
    switch (data) {
      case "backspace":
        if (enteredNumber?.length - 1) {
          setEnteredNumber(
            enteredNumber
              ?.toString()
              .substring(0, enteredNumber?.toString().length - 1),
          );
        } else {
          setEnteredNumber("");
        }
        break;
      case "clear":
        setEnteredNumber("");
        break;
      default:
        setEnteredNumber(enteredNumber + data);
    }
  };

  let cartItems = [...cartItemsVar()];

  const [productInCartList, setProductInCartList] = useState<any>({
    id: item?.id,
    pk: item?.pk,
    contentTypeId: item?.contentTypeId,
    title: item?.title,
    quantity: 1,
    price: item?.finalPrice ?? 0,
    total: parseFloat(item?.finalPrice) ?? 0,
    tax: item?.taxAmount,
    taxRate: { rate: item?.tax?.rate ?? 0, isIncluded: item?.tax?.isIncluded },
    isSelected: true,
    isDeleted: false,
    modifier: [],
    isModified: true,
    isUpdated: false,
  });

  useEffect(() => {
    setProductInCartList({
      id: item?.id,
      pk: item?.pk,
      contentTypeId: item?.contentTypeId,
      title: item?.title,
      quantity: item?.isWeightable ? parseFloat(enteredNumber) : 1,
      price: item?.finalPrice ?? 0,
      total: parseFloat(item?.finalPrice) ?? 0,
      tax: item?.taxAmount,
      taxRate: {
        rate: item?.tax?.rate ?? 0,
        isIncluded: item?.tax?.isIncluded,
      },
      isSelected: true,
      isDeleted: false,
      modifier: [],
      isModified: true,
      isUpdated: false,
    });
    //Clean up the data before rendering
    return () => {
      setProductInCartList({});
    };
  }, [item]);
  useEffect(() => {
    let modItem: any = item?.modifierCategories?.edges?.map(
      (modCatItem: any, modCatIndex: number) => ({
        isUpdated: false,
        title: modCatItem?.node?.category?.name,
        subModifier: modCatItem?.node?.category?.productsByCategory?.edges?.map(
          (subModCatItem: any, subModCatIndex: number) => ({
            id: subModCatItem?.node?.id,
            pk: subModCatItem?.node?.pk,
            contentTypeId: subModCatItem?.node?.contentTypeId,
            title: subModCatItem?.node?.title,
            quantity: 0,
            price: subModCatItem?.node?.sellingPrice ?? 0,
            total: parseFloat(subModCatItem?.node?.finalPrice) ?? 0,
            tax: subModCatItem?.node?.taxAmount,
            taxRate: {
              rate: subModCatItem?.node?.tax?.rate ?? 0,
              isIncluded: subModCatItem?.node?.tax?.isIncluded,
            },
            isSelected: true,
            isDeleted: false,
            modifier: [],
            isModified: true,
            isUpdated: false,
            media: subModCatItem?.node?.media ?? [],
          }),
        ),
      }),
    );
    productInCartList["modifier"] = modItem;
    setProductInCartList({ ...productInCartList });

    //Clean up the data before rendering
    return () => {
      productInCartList["modifier"] = [];
      setProductInCartList({});
    };
  }, [productInCartList?.id]);

  const getTotalTax = (taxRate: any, price: any) => {
    if (parseFloat(taxRate) > parseFloat("0.00")) {
      let taxAmount = price - (price / (1 + taxRate)) * 100;
      return taxAmount;
    }
    return 0;
  };
  const handleAddProductWithModCategoryClick = () => {
    let modifierItem: any = [];
    setLocalKey("disableRemotePrint", "false");
    productInCartList?.modifier?.map((modItem: any) =>
      modItem?.subModifier?.map(
        (subModItem: any) =>
          subModItem?.quantity > 0 &&
          modifierItem.push({
            id: subModItem?.id,
            pk: subModItem?.pk,
            contentTypeId: subModItem?.contentTypeId,
            title: subModItem?.title,
            quantity: subModItem?.quantity,
            price: subModItem?.price ?? 0,
            total: parseFloat(subModItem?.price) * subModItem?.quantity ?? 0,
            tax: parseFloat(subModItem?.tax) * parseFloat(subModItem?.quantity),
            taxRate: subModItem?.taxRate,
            isSelected: true,
            isDeleted: false,
            modifier: [],
            isModified: true,
            isUpdated: false,
          }),
      ),
    );

    let cartList: any = {
      id: productInCartList?.id,
      pk: productInCartList?.pk,
      contentTypeId: productInCartList?.contentTypeId,
      title: productInCartList?.title,
      quantity: item?.isWeightable
        ? parseFloat(enteredNumber)
        : productInCartList?.quantity,
      price: productInCartList?.price ?? 0,
      total: item?.isWeightable
        ? parseFloat(enteredNumber) * parseFloat(productInCartList?.price ?? 0)
        : parseFloat(productInCartList?.price) ?? 0,
      tax: productInCartList?.tax,
      taxRate: productInCartList?.taxRate,
      isSelected: true,
      isDeleted: false,
      modifier: modifierItem,
      isModified: true,
      isUpdated: false,
    };
    let previousObject: any = cartItems.find(
      (x: any) => x.id === item?.id && x.isSelected === true,
    );
    if (previousObject && previousObject.isSelected) {
      let newItemList: any = cartItems;
      let foundIndex: any = cartItems.findIndex(
        (x: any) => x.id === item?.id && x.isSelected === true,
      );
      newItemList[foundIndex].quantity =
        previousObject?.quantity >= 0
          ? item?.isWeightable
            ? parseFloat(previousObject.quantity) + parseFloat(enteredNumber)
            : parseFloat(previousObject.quantity) +
              parseFloat(productInCartList?.quantity)
          : 1;
      newItemList[foundIndex].tax =
        parseFloat(item?.taxAmount) * parseFloat(previousObject?.quantity);

      newItemList[foundIndex].total =
        item?.finalPrice > 0
          ? parseFloat(item?.finalPrice) * parseFloat(previousObject?.quantity)
          : 0;

      //Modifier
      modifierItem.map((item: any, index: number) => {
        let indexI: any = previousObject?.modifier?.findIndex(
          (preMod: any, preModInd: number) =>
            preMod.id === item.id && preMod.isDeleted === false,
        );
        if (indexI < 0) {
          cartItems[foundIndex].modifier.push({
            id: item?.id,
            pk: item?.pk,
            contentTypeId: item?.contentTypeId,
            title: item?.title,
            quantity: item?.quantity,
            price: item?.price ?? 0,
            total: parseFloat(item?.price) * item?.quantity ?? 0,
            tax: getTotalTax(
              item.taxRate.rate,
              parseFloat(item?.price) * item?.quantity,
            ),
            taxRate: item?.taxRate,
            isSelected: true,
            isDeleted: false,
            modifier: [],
            isModified: true,
            isUpdated: false,
          });
        } else {
          cartItems[foundIndex].modifier[indexI]["quantity"] += item?.quantity;
          cartItems[foundIndex].modifier[indexI]["total"] =
            cartItems[foundIndex].modifier[indexI]["quantity"] * item?.price;
          cartItems[foundIndex].modifier[indexI]["tax"] = getTotalTax(
            item.taxRate.rate,
            cartItems[foundIndex].modifier[indexI]["total"],
          );
        }
      });

      let updateNewItemList: any = newItemList.map((item: any, index: number) =>
        !index === foundIndex
          ? {
              ...item,
              isSelected: false,
            }
          : item,
      );

      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...updateNewItemList]),
        },
      });
      const searchList = {
        searchInput: "",
        name: "",
      };
      searchCache.writeQuery({
        query: GET_SEARCH_ITEMS,
        data: {
          searchItems: searchItemsVar(searchList),
        },
      });
    } else {
      let nonPreviousItemList = cartItems?.map((item: any, index: number) => ({
        ...item,
        isSelected: false,
      }));

      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([cartList, ...nonPreviousItemList]),
        },
      });
    }
    setOpen(false);
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
  };

  const handleOnModIncreasingCounter = (item: any, index: any) => {
    productInCartList["modifier"][index]["isUpdated"] =
      !productInCartList["modifier"][index]["isUpdated"];

    for (
      let i = 0;
      i < productInCartList["modifier"][index]["subModifier"]?.length;
      i++
    ) {
      if (productInCartList["modifier"][index]["isUpdated"]) {
        productInCartList["modifier"][index]["subModifier"][i]["quantity"] =
          productInCartList["modifier"][index]["subModifier"][i]["quantity"] +
          1;
      } else {
        productInCartList["modifier"][index]["subModifier"][i]["quantity"] = 0;
      }
    }
    setProductInCartList((prev: any) => ({ ...productInCartList }));
  };

  const handleAddProductQuantityWithModCategoryClick = () => {
    if (enteredNumber === "" || enteredNumber === null) {
      setOpenAddQuantityPopup(true);
    } else {
      setOpenAddQuantityPopup(false);
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (item?.isWeightable) {
      setOpenPopup(true);
    }
    return () => {
      setOpenPopup(false);
    };
  }, [item]);

  return (
    <div>
      <div
        key={randomString(20)}
        className="grid grid-rows-6 grid-cols-7 p-4  gap-2 w-full"
      >
        {productInCartList?.modifier?.map((item: any, index: number) => (
          <div
            key={randomString(20)}
            onClick={() => {
              handleOnModIncreasingCounter(item, index);
            }}
            style={{ background: "#E9B634" }}
            className={`relative cursor-pointer border-2 min-h-[80px] rounded-lg ${
              productInCartList["modifier"][index]["isUpdated"]
                ? "text-white bg-gray-800"
                : "bg-gray-200"
            }`}
          >
            <p
              className={` textWithTwoLine absolute bottom-[0%] w-full  text-left font-bold px-2`}
            >
              {item?.title}
            </p>
          </div>
        ))}
      </div>
      <div className={"flex justify-center flex-wrap items-center gap-4 mt-2"}>
        <PrimaryButton text={"Cancel"} onClick={() => setOpen(false)} />
        {!openPopup ? (
          <PrimaryButton
            text={"Done"}
            onClick={() => handleAddProductWithModCategoryClick()}
          />
        ) : (
          <PrimaryButton
            text={"Add Quantity"}
            onClick={() => setOpenAddQuantityPopup(true)}
          />
        )}
      </div>
      <Modal
        title={"Add Quantity"}
        open={openAddQuantityPopup}
        setOpen={setOpenAddQuantityPopup}
        size="max-w-sm"
      >
        <>
          <input
            type="text"
            name={"quantity"}
            className="relative peer bg-[#FFFFFF] p-3  w-full text-gray-700 mt-2 mb-3 rounded"
            style={{
              boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
            }}
            placeholder={"Enter Quantity "}
            value={enteredNumber}
            onChange={(e: any) => setEnteredNumber(e.target.value)}
            autoComplete="off"
          />
          <br />
          <div className="grid grid-cols-3 gap-4">
            {numberPad.map((item: any) => (
              <div
                key={item.id}
                className="col-span-1 w-full  p-2  cursor-pointer bg-[#ffffff] text-[18px] text-center rounded items-center justify-center"
                style={{
                  boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
                }}
                onClick={() => handleCalculate(item.value)}
              >
                <span>{item.label}</span>
              </div>
            ))}
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate(".")}
            >
              <span>.</span>
            </div>
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("0")}
            >
              <span>0</span>
            </div>
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("backspace")}
            >
              <span>x</span>
            </div>
          </div>
          <div className="text-right mt-4">
            <PrimaryButton
              text={"Add"}
              onClick={() => handleAddProductQuantityWithModCategoryClick()}
              loading={false}
              disable={false}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};

export default PosScreenProWithModCatTrue;
