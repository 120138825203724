import React from "react";
import randomString from "../../../utils/randomString";
import { IHTMLDropDownProps } from "./interface";

const DynamicDropDown: React.FC<IHTMLDropDownProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    list = [],
    disabled = false,
    helperText,
    select = false,
  } = props;
  const id = randomString(10, "-drop-down-field");

  const handleOnChange = (value: string) => {
    setFieldValue(name, value);
  };
  return (
    <div className="w-full rounded relative">
      <select
        id={id}
        name={name}
        className=" bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
        placeholder=" "
        value={values && values[name]}
        onChange={(e: any) => handleOnChange(e.target.value)}
        disabled={disabled}
      >
        {select ? (
          <option value="">Select</option>
        ) : (
          <option disabled value="">
            Select
          </option>
        )}
        {list?.map((elem: any) => (
          <option key={elem.id} value={elem.id}>
            {elem.title}
          </option>
        ))}
      </select>
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]}</p>
      )}
      <p className="text-xs text-blue-500 ">{helperText}</p>
      <label
        htmlFor={id}
        className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export default DynamicDropDown;
