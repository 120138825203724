import { useQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { QUERY_SETTINGS } from "../../../pages/Settings/graphql";
import { countryPhoneNumber } from "../../../utils/countryPhoneNumbers";

type Props = {
  selectState: any;
  setSelectState: any;
  disabled?: boolean;
  values?: any;
};

const CountryPhoneField = (props: Props) => {
  const { selectState, setSelectState, disabled = false } = props;
  const { data: settingData } = useQuery(QUERY_SETTINGS);

  useEffect(() => {
    const found = countryPhoneNumber.find(
      (elem: any) =>
        elem.code === settingData?.basicWebsiteConfigurations?.country,
    );
    setSelectState(found);
  }, [settingData]);
  const [filterLetter, setFilterLetter] = useState("");
  return (
    <>
      <Listbox
        value={selectState}
        onChange={setSelectState}
        disabled={disabled}
      >
        <div className="relative">
          <Listbox.Button className="relative bg-gray-200 p-2 rounded w-[100px] text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600">
            <span className="block truncate">
              {selectState?.name && selectState?.dial_code
                ? `${selectState?.dial_code}-${selectState?.name}`
                : "No Location"}
            </span>
            {selectState?.name && selectState?.dial_code ? null : (
              <span className="pointer-events-none absolute inset-y-0 flex pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              onKeyDown={(e: any) => {
                if (/^[a-zA-Z]$/.test(e.key)) {
                  setFilterLetter(e.key);
                }
              }}
              className="absolute z-20 mt-1 max-h-52 w-40 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {countryPhoneNumber
                .filter((item: any) =>
                  filterLetter
                    ? item.name.toLowerCase().startsWith(filterLetter)
                    : true,
                )
                .map((elem: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2  px-4 ${active
                        ? "bg-indigo-100 text-indigo-900"
                        : "text-gray-900"
                      }`
                    }
                    value={elem}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? "font-medium" : "font-normal"
                            }`}
                        >
                          {elem?.dial_code}-{elem?.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
        <label className="absolute left-1 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm">
          Country Code
        </label>
      </Listbox>
    </>
  );
};

export default CountryPhoneField;
