import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { DEFAULT_POS_SCREEN, GET_SCREENS } from "../pages/queries";
import { toast } from "react-toastify";
import { TOGGLE_SCREEN } from "../pages/PosScreen/mutation";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CURRENT_TERMINAL } from "../pages/PosScreen/queries";
type Props = {};

const SelectLayout = (props: Props) => {
  const [screenValue, setScreenValue] = useState<string>("DEFAULT");
  const { data: screenData } = useQuery(GET_SCREENS, {
    variables: {
      first: 100,
      offset: 0,
      name_Icontains: "",
    },
    fetchPolicy: "network-only",
  });
  const { data: currentTerminaldata, loading: terminalLoading } =
    useQuery(CURRENT_TERMINAL);

  const { data: defaultPOSScreen, loading: defaultScreenLoading } = useQuery(
    DEFAULT_POS_SCREEN,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (defaultPOSScreen?.defaultPosScreen?.screen?.pk) {
      setScreenValue(defaultPOSScreen?.defaultPosScreen?.screen?.pk);
    }
  }, [defaultPOSScreen?.defaultPosScreen?.screen?.pk]);

  const [toggleScreen] = useMutation(TOGGLE_SCREEN, {
    refetchQueries: [
      "ACTION_MENUS",
      "POS_SCREEN_PRODUCTS",
      "POS_SCREEN_CATEGORY",
    ],
  });
  const handleScreenChange = async (e: any) => {
    setScreenValue(e.target.value);
    const response = await toggleScreen({
      variables: {
        screenId: e.target.value,
      },
    });
    if (response.data?.setupDefaultScreen?.success) {
      toast.success(response.data?.setupDefaultScreen?.message);
    }
  };
  return (
    <>
      <div className=" w-full mr-2 min-w-[220px]">
        {defaultScreenLoading ? (
          <SkeletonTheme baseColor="#645bd3" highlightColor="#9188ff">
            <Skeleton height={32} borderRadius={40} />
          </SkeletonTheme>
        ) : (
          <>
            {screenData && (
              <select
                className="block w-full h-[32px] bg-[#FFFFFF2D] px-4 py-2 m-1 text-center rounded-[20px] border-transparent text-white placeholder-white focus:outline-none focus:placeholder-white focus:ring-0 focus:border-transparent sm:text-sm"
                value={screenValue ? screenValue : "DEFAULT"}
                onChange={(e) => handleScreenChange(e)}
              >
                <option disabled={true} value={"DEFAULT"}>
                  Select Layout
                </option>
                {screenData?.screens?.edges?.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                    <option
                      className="bg-[#FFFFFF2D] text-gray-600 p-2"
                      value={item?.node?.pk}
                    >
                      {item?.node?.name}
                    </option>
                  </React.Fragment>
                ))}
              </select>
            )}
          </>
        )}
      </div>
      <div className=" w-full my-auto">
        {terminalLoading ? (
          <SkeletonTheme baseColor="#645bd3" highlightColor="#9188ff">
            <Skeleton height={32} borderRadius={40} />
          </SkeletonTheme>
        ) : (
          <>
            <div className="bg-[#FFFFFF2D] px-5 py-1.5 text-center rounded-[20px] text-white">
              {currentTerminaldata?.currentTerminal?.name}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SelectLayout;
