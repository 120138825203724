import React, { useState } from 'react'
import ConfirmAlert from '../reusable/ConfirmAlert'
import { toast } from 'react-toastify'
import useHandleToggleSelectedTable from '../../customhooks/useHandleToggleSelectedTable'
import usePosScreenHandleClear from '../../customhooks/usePosScreenHandleClear'
import { POS_SCREEN_HOLD_KITCHEN_ORDER_LIST } from '../../pages/PosScreen/mutation'
import { useMutation, useReactiveVar } from '@apollo/client'
import { cartItemsVar, kitchenOrderItemId, tableItemsVar } from '../localstate/Cache'
import { removeLocalKey } from '../../helpers/sessionKey'

type Props = {
  openVoidOrder: boolean
  setOpenVoidOrder: any
  setOpenHoldPopup: any
}

const PosScreenVoidOrderFunction = (props: Props) => {
  const { openVoidOrder, setOpenVoidOrder, setOpenHoldPopup } = props
  const [note, setNote] = useState(false);
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const { handleClearPosScreen } = usePosScreenHandleClear();
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const [addToHoldCardItem] = useMutation(POS_SCREEN_HOLD_KITCHEN_ORDER_LIST, {
    fetchPolicy: "no-cache",
  });
  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  let totalAmount = cartItems?.map((item: any) => parseFloat(item?.total));
  let modifiedTotalAmount = totalAmount?.reduce((a: any, b: any) => a + b, 0);
  const handleOrderCancel = () => {
    if (
      !!(
        kitchenOrdreItemId?.kitchenOrderPk?.id ||
        kitchenOrdreItemId?.kitchenCardItemId?.id
      )
    ) {
      handleCancelCard(
        kitchenOrdreItemId?.kitchenOrderPk?.pk ??
        kitchenOrdreItemId?.kitchenCardItemId?.pk,
      );
      setOpenHoldPopup(false);
    } else {
      setOpenHoldPopup(false);
    }
  };
  const handleCancelCard = async (pk: string) => {
    if (pk) {
      const response = await addToHoldCardItem({
        variables: {
          id: pk,
          orderStatus: "cancel",
          reason: note,
          amount: modifiedTotalAmount,
        },
      });
      if (response?.data?.modifyKitchenOrderStatus?.success) {
        handleClearPosScreen();
        if (tableItems?.pk) {
          handleSelectTableToggle(false, tableItems?.mergedId ? tableItems?.mergedId : tableItems?.pk);
          removeLocalKey("tableItemSelected");
        }
        toast.success("Successfully Cancel");
      } else {
        toast.success("Some thing went wrongs");
      }
    } else {
      toast.error("Invalid Order");
    }
  };
  return (
    <ConfirmAlert
      title={"Alert!!"}
      subTitle={"Are you sure to cancel this order??"}
      isOpen={openVoidOrder}
      onConfirm={() => handleOrderCancel()}
      setIsOpen={setOpenVoidOrder}
      setNote={setNote}
      notes={true}
    />
  )
}

export default PosScreenVoidOrderFunction