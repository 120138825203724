import React from "react";
import randomString from "../../../../utils/randomString";
import { IHTMLFieldProps } from "../interface";
import Cleave from "cleave.js/react";

const PriceField: React.FC<IHTMLFieldProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange = null,
    helperText,
    disabled = false,
    placeHolder,
    onBlur,
  } = props;

  const id = randomString(10, "-text-field");
  const handleOnChange = (value: string) => {
    setFieldValue(name, value);
    handleChange && handleChange(value, setFieldValue)
  };
  return (
    <div className="w-full relative mb-6 rounded">
      <Cleave
        id={id}
        type="text"
        name={name}
        className="relative peer bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
        placeholder={placeHolder ?? " "}
        value={values[name] ?? ""}
        disabled={disabled}
        onChange={(e: any) => handleOnChange(e.target.value)}
        onBlur={onBlur}
        autoComplete="off"
        options={{
          numeral: true,
          numeralDecimalMark: ".",
          numeralDecimalScale: 2,
          numeralPositiveOnly: true,
          delimiter: ""
        }}
      />
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]}</p>
      )}

      <p className="text-xs text-blue-500">{helperText}</p>

      <label
        htmlFor={id}
        className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export default PriceField;
