import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { POS_SCREEN_KITCHEN_ORDER_ITEMS } from "../queries";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";
import { POS_SCREEN_HOLD_KITCHEN_ORDER_LIST } from "../mutation";
import { toast } from "react-toastify";
import {
  kitchenOrderItemId,
  kitchenOrdreCache,
  modifiedKitchenOrderCache,
  modifiedKitchenOrderItemVar,
  tableCache,
  tableItemsVar,
} from "../../../components/localstate/Cache";
import {
  GET_KITCHEN_CARD_ID,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_TABLE_ITEMS,
} from "../../../components/localstate/Cart";

const HoldCardDetailComponent: any = Loadable({
  loader: () => import("../../../components/PosScreenHoldCard/DetailHoldCart"),
  loading: DataLoadSpinner,
});
const PosScreenHoldCardDetail = () => {
  const id = useParams();
  const { loading: isLoading, data: holdCartItemData } = useQuery(
    POS_SCREEN_KITCHEN_ORDER_ITEMS,
    {
      variables: id,
    },
  );
  const navigate = useNavigate();
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const tableItemSelected = getLocalKey("tableItemSelected");
  const [addToHoldCardItem] = useMutation(POS_SCREEN_HOLD_KITCHEN_ORDER_LIST, {
    fetchPolicy: "no-cache",
  });

  const handleViewOrderInPos = async () => {
    setLocalKey("disableRemotePrint", "false");
    if (holdCartItemData?.kitchenOrder?.table?.pk) {
      handleSelectTableToggle(true, holdCartItemData?.kitchenOrder?.table?.mergeInstance ? holdCartItemData?.kitchenOrder?.table?.mergeInstance : holdCartItemData?.kitchenOrder?.table?.pk);
    }
    //Change HoldCard list To Pending
    await addToHoldCardItem({
      variables: {
        id: holdCartItemData?.kitchenOrder?.pk,
        orderStatus: "pending",
      },
    });
    const kitchenOrderPk: any = {
      id: holdCartItemData?.kitchenOrder?.id,
      pk: holdCartItemData?.kitchenOrder?.pk,
      orderStatus: holdCartItemData?.kitchenOrder?.orderStatus,
    };
    kitchenOrdreCache.writeQuery({
      query: GET_KITCHEN_CARD_ID,
      data: {
        kitchenOrderItemId: kitchenOrderItemId({ kitchenOrderPk }),
      },
    });
    let kitchenOrderItems = {
      kitchenOrderPk: holdCartItemData,
    };
    tableCache.writeQuery({
      query: GET_TABLE_ITEMS,
      data: {
        tableItems: tableItemsVar(holdCartItemData?.kitchenOrder?.table),
      },
    });
    modifiedKitchenOrderCache.writeQuery({
      query: GET_MODIFIED_KITCHEN_CARD_ID,
      data: {
        modifiedKitchenOrderId: modifiedKitchenOrderItemVar(kitchenOrderItems),
      },
    });
    navigate(`/pos-screen`);
    // setLocalKey("disableRemotePrint", "true");
  };

  return (
    <div className="w-full">
      <HoldCardDetailComponent
        isLoading={isLoading}
        holdCartItemData={holdCartItemData?.kitchenOrder}
        handleViewOrderInPos={handleViewOrderInPos}
      />
    </div>
  );
};

export default PosScreenHoldCardDetail;
