import React, { useEffect, useRef, useState } from "react";
import TableIcon from "../../../assets/sidebarIcons/white/table.svg";
import OrderType from "../../../assets/sidebarIcons/white/ordertype.svg";
import DualScreenIcon from "../../../assets/sidebarIcons/white/pos.svg";
import HoldCartIcon from "../../../assets/sidebarIcons/white/holdcart.svg";
import RemoteOrderIcon from "../../../assets/sidebarIcons/white/remoteorder.svg";
import OrderIcon from "../../../assets/sidebarIcons/white/order.svg";
import TransactionIcon from "../../../assets/sidebarIcons/white/transactions.svg";
import BookingScreen from "../../../assets/sidebarIcons/white/bookingscreen.svg";
import CustomerIcon from "../../../assets/sidebarIcons/white/customer.svg";
import ProductIcon from "../../../assets/sidebarIcons/white/product.svg";
import StockIcon from "../../../assets/sidebarIcons/white/stock.svg";
import ReportIcon from "../../../assets/sidebarIcons/white/report.svg";
import { toast } from "react-toastify";
import { ASSIGN_MENUS, REMOVE_ACTION_MENU } from "../../../pages/mutations";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { ACTION_MENUS, DEFAULT_POS_SCREEN } from "../../../pages/queries";
import Modal from "../Modal";
import { useNavigate } from "react-router";
import {
  CURRENT_TERMINAL,
  POS_SCREEN_KITCHEN_ORDER_ITEMS,
  POS_SCREEN_LAST_DOCKET,
  POS_SCREEN_PRINTERS_LIST,
} from "../../../pages/PosScreen/queries";
import PosScreenTableFunction from "../../PosScreenComponent/PosScreenTableFunction";
import ConfirmAlert from "../ConfirmAlert";
import PosScreenHoldOrderFunction from "../../PosScreenComponent/PosScreenHoldOrderFunction";
import PosScreenVoidOrderFunction from "../../PosScreenComponent/PosScreenVoidOrderFunction";
import PosScreenDiscountFunction from "../../PosScreenComponent/PosScreenDiscountFunction";
import PosScreenSurchargeFunction from "../../PosScreenComponent/PosScreenSurchargeFunction";
import PosScreenNoteFunction from "../../PosScreenComponent/PosScreenNoteFunction";
import PosScreenPayFunction from "../../PosScreenComponent/PosScreenPayFunction";
import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
  kitchenOrderItemId,
  kitchenOrdreCache,
  modifiedKitchenOrderItemVar,
  noteCache,
  noteVar,
  openTableCache,
  openTableVar,
  orderTypeVar,
  permissionTypeVar,
  tableItemsVar,
  totalCalculationVar,
  kitchenCardItemValueCache,
  kitchenCardItemValueVar,
} from "../../localstate/Cache";
import usePosScreenHandleClear from "../../../customhooks/usePosScreenHandleClear";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_KITCHEN_CARD_ID,
  GET_NOTE_ITEMS,
  GET_ORDER_TYPE,
} from "../../localstate/Cart";
import { QUERY_SETTINGS } from "../../../pages/Settings/graphql";
import {
  POS_SCREEN_CREATE_KITCHEN_ORDER,
  POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER,
} from "../../../pages/PosScreen/mutation";
import GET_RECEIPT from "../../../pages/ReceiptSetting/queries";
import IndividualButtons from "./IndividualButtons";
import Login from "../../PosScreenType/AustraliaPosScreen/PosScreenCartSection/PermissionVerify/Login";
import moment from "moment";
// import PosScreenAddBooking from "../../PosScreenBookingScreen/PosScreenAddBooking";
import Divider from "../Divider";
import Spinner from "../Spinner";

type Props = {
  startWith: String;
};
const actionValue = [
  { id: 1, name: "Table", slug: "table", icon: TableIcon, disable: false },
  {
    id: 2,
    name: "Order Type",
    slug: "order_type",
    icon: OrderType,
    disable: false,
  },
  { id: 3, name: "POS", slug: "pos", icon: DualScreenIcon, disable: false },
  {
    id: 4,
    name: "Hold Cart",
    slug: "hold_cart",
    icon: HoldCartIcon,
    disable: false,
  },
  {
    id: 5,
    name: "Remote Order",
    slug: "remote_order",
    icon: RemoteOrderIcon,
    disable: false,
  },
  { id: 6, name: "Orders", slug: "orders", icon: OrderIcon, disable: false },
  {
    id: 7,
    name: "Transaction",
    slug: "transaction",
    icon: TransactionIcon,
    disable: false,
  },
  {
    id: 8,
    name: "Customer",
    slug: "customer",
    icon: CustomerIcon,
    disable: false,
  },
  { id: 9, name: "Hold Order", slug: "hold_order", disable: true },
  { id: 10, name: "Void Order", slug: "void_order", disable: true },
  { id: 11, name: "Pay", slug: "pay", disable: true },
  { id: 12, name: "Kitchen Print", slug: "kitchen_print", disable: true },
  { id: 13, name: "Clear", slug: "clear", disable: true },
  { id: 14, name: "Discount", slug: "discount", disable: true },
  {
    id: 15,
    name: "Print Last Docket",
    slug: "print_discount_docket",
    disable: true,
  },
  { id: 16, name: "Note", slug: "note", disable: true },
  { id: 17, name: "Receipt Print", slug: "receipt_print", disable: true },
  { id: 18, name: "Surcharge", slug: "surcharge", disable: true },
  {
    id: 19,
    name: "Products",
    slug: "product",
    icon: ProductIcon,
    disable: false,
  },
  {
    id: 20,
    name: "Reports",
    slug: "sales_report",
    icon: ReportIcon,
    disable: false,
  },
  { id: 21, name: "Stock", slug: "stock", icon: StockIcon, disable: false },
  {
    id: 22,
    name: "Booking Screen",
    slug: "booking_screen",
    icon: BookingScreen,
    disable: false,
  },
];
const PosScreenButtons = (props: Props) => {
  const { startWith } = props;
  const navigate = useNavigate();
  const [screenPopup, setScreenPopup] = useState(false);
  const [screenId, setScreenId] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openOrderType, setOpenOrderType] = useState("");
  const { data: current } = useQuery(CURRENT_TERMINAL);
  const [openHoldPopup, setOpenHoldPopup] = useState(false);
  const [openBookingScreenPopup, setOpenBookingScreenPopup] = useState(false);
  const [openVoidPopup, setOpenVoidPopup] = useState(false);
  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);
  const [selectDiscountType, setSelectDiscountType] = useState("discountPer");
  const [permissionType, setPermissionType] = useState<any>("");

  const [selectSurchargeType, setSelectSuchargeType] = useState("surchargePer");

  const [openSuchargePopup, setOpenSuchargePopup] = useState(false);
  const [openNotePopup, setOpenNotePopup] = useState(false);
  const [openPayPopup, setOpenPayPopup] = useState(false);
  const [permPopup, setPermPopup] = useState<any>(false);

  const [bookingTableTitle, setBookingTableTitle] = useState("Reserve a Table");

  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const permissionTypes: any[] = useReactiveVar(permissionTypeVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const totalAmtCalculations: any = useReactiveVar(totalCalculationVar);
  const noteItems: any = useReactiveVar(noteVar);
  const openTable: any = useReactiveVar(openTableVar);

  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  const [kitchenPrintAlertPopup, setKitchenPrintAlertPopup] = useState(false);
  const [openPayKitchenPrintAlert, setOpenPayKitchenPrintAlert] =
    useState(false);
  const { handleClearPosScreen } = usePosScreenHandleClear();
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  let modItems: any = cartItems?.filter(
    (item: any) => item?.isModified === true
  );
  const [createKitchenOrder, { loading: loadingKitchenOrder }] = useMutation(
    POS_SCREEN_CREATE_KITCHEN_ORDER,
    {
      refetchQueries: ["kitchenOrder"],
    }
  );
  const { data: printerListData } = useQuery(POS_SCREEN_PRINTERS_LIST, {
    fetchPolicy: "network-only",
  });
  const { data: basicSetting } = useQuery(QUERY_SETTINGS, {
    fetchPolicy: "network-only",
  });
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL, {
    fetchPolicy: "network-only",
  });
  const openPopupKitchenPrintDisable =
    currentTerminalData?.currentTerminal?.terminalsetting
      ?.compulsoryKitchenPrint;
  const idToModifyKitchenOrder: any = useReactiveVar(
    modifiedKitchenOrderItemVar
  );
  const [modifiedCreateKitchenOrder, { loading: loadingModifiedKitchenOrder }] =
    useMutation(POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER, {
      refetchQueries: ["kitchenOrder"],
    });
  const [handleData, { data: terminalData }] = useLazyQuery(CURRENT_TERMINAL, {
    fetchPolicy: "network-only",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [KitchenCardItemData, setKitchenCardItemData] = useState<any>({});

  const isEnableA4 = JSON.parse(getLocalKey("A4Enable") || "false");

  const handleClear = (e: any) => {
    if (
      modItems?.length ||
      kitchenOrdreItemId?.kitchenOrderPk?.orderStatus === "HOLD"
    ) {
      setKitchenPrintAlertPopup(true);
    } else {
      handleClearPosScreen();
      // setSelectSuchargeType();
      // setSelectDiscountType();
      // e.stopPropagation();
      // setChecked(false);
    }
    if (tableItems?.pk) {
      handleSelectTableToggle(
        false,
        tableItems?.mergedId ? tableItems?.mergedId : tableItems?.pk
      );
      setLocalKey("tableItemSelected", "");
    }
  };
  const [isLoadingLastDocket, setIsLoadingLastDocket] = useState(false);
  const [getPrintLastDocket] = useLazyQuery(POS_SCREEN_LAST_DOCKET);
  const { data: receiptData } = useQuery(GET_RECEIPT);
  let itemList = cartItems?.filter((item, index) => item.quantity > 0);
  let test = receiptData?.receiptSetting?.header;
  let test1 = receiptData?.receiptSetting?.footer;
  const terminalReceiptSetting =
    currentTerminalData?.currentTerminal?.terminal?.terminalsetting;
  const receiptPrintDefault = {
    netAmountForReceiptPrint: terminalReceiptSetting?.netAmount,
    amountAfterDiscountForReceiptPrint:
      terminalReceiptSetting?.amountAfterDiscount,
    taxAmountForReceiptPrint: terminalReceiptSetting?.gst,
    serviceChargeForReceiptPrint: terminalReceiptSetting?.serviceCharge,
    grossAmountForReceiptPrint: terminalReceiptSetting?.grossAmount,
    surChargeForReceiptPrint: terminalReceiptSetting?.surCharge,
    eftPosChargeForReceiptPrint: terminalReceiptSetting?.eftposCharge,
  };
  const handleElectronPrintLastDocketReceipt = async (e: any) => {
    setIsLoadingLastDocket(true);
    e.stopPropagation();
    const { data: orderDetailsData } = await getPrintLastDocket({
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });
    const jsonData = orderDetailsData?.printLastDocket?.eftposReceipt
      ? JSON.parse(orderDetailsData?.printLastDocket?.eftposReceipt)
      : "";
    setIsLoadingLastDocket(false);
  };

  const handleElectronPrintReceipts = (e: any) => {
    e.stopPropagation();

    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(""),
      },
    });
  };
  const getFinalItems = (orderPrintItems: any) => {
    let finalItems: any = [];
    printerListData?.printers.edges?.map((value: any, index: number) => {
      let productData = orderPrintItems?.filter((item: any, index: number) =>
        item
          ? item?.product?.printer?.edges?.some((element: any) => {
              if (element?.node?.department === value?.node?.department) {
                return true;
              }
            })
          : item?.product?.printer?.edges?.some((element: any) => {
              if (element?.node?.department === value?.node?.department) {
                return true;
              }
            })
      );
      if (productData?.length)
        finalItems.push({
          location: value?.node?.location,
          department: value?.node?.department,
          type: value?.node?.type,
          products: productData,
        });
    });
    return finalItems;
  };
  useEffect(() => {
    if (openTable) {
      setOpenOrderType("table");
    }
  }, [openTable]);

  useEffect(() => {
    if (KitchenCardItemData) {
      kitchenCardItemValueCache.writeQuery({
        query: GET_NOTE_ITEMS,
        data: {
          noteItems: kitchenCardItemValueVar(KitchenCardItemData),
        },
      });
    }
  }, [KitchenCardItemData]);

  const [getKitchenCardItem] = useLazyQuery(POS_SCREEN_KITCHEN_ORDER_ITEMS, {
    fetchPolicy: "network-only",
  });
  const handleElectronKitchenPrint = async (
    orderPrintItems: any,
    kitchenOrderId: any,
    isCreated: boolean = false,
    receiptPrint: boolean
  ) => {
    const kitchenOrderDataResponse = await getKitchenCardItem({
      variables: {
        id: kitchenOrderId,
      },
    });
    if (isCreated) {
      setKitchenCardItemData(kitchenOrderDataResponse?.data);
    }

    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(""),
      },
    });
    const openPopupAfterRemotePrint: any =
      currentTerminalData?.currentTerminal?.terminalsetting
        ?.openPopupAfterRemotePrint;
    if (openPopupAfterRemotePrint === true) {
      // handleClearPosScreen();
      if (tableItems?.pk) {
        handleSelectTableToggle(
          false,
          tableItems?.mergedId ? tableItems?.mergedId : tableItems?.pk
        );
      }
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar({
            id: "",
            name: "",
            pk: "",
          }),
        },
      });
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
  };
  const handleOrderProduct = async (receiptPrint = false) => {
    if (typeof receiptPrint !== "boolean") {
      receiptPrint = false;
    }
    if (isLoading) {
      return;
    }
    const response = await handleData({
      fetchPolicy: "network-only",
    });
    if (response?.data?.currentTerminal === null) {
      toast.warn("Please Select Default Terminal");
      return;
    }
    setLocalKey("disableRemotePrint", "true");
    setIsLoading(true);
    if (idToModifyKitchenOrder?.kitchenOrderPk?.pk) {
      let orderItems: any = [];
      let orderInfo = {
        customer: customerItems?.pk ? customerItems.pk : "",
        table: tableItems?.mergedId
          ? tableItems.mergedId
          : tableItems?.pk ?? "",
        note: noteItems ? noteItems : "",
        id: idToModifyKitchenOrder?.kitchenOrderPk?.pk,
        orderMethod: orderType?.pk ?? "",
      };
      let deletedItems: any = cartItems?.filter(
        (item: any) => item?.isDeleted === true && !item?.idToModifyKitchenOrder
      );
      let otherDeletedItems = cartItems?.filter(
        (item: any) => !deletedItems.includes(item)
      );
      otherDeletedItems?.map((item: any) => {
        let modifierItems: any = [];
        item?.modifier?.map((item: any) => {
          if (item?.quantity > 0) {
            modifierItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
              enteredAmount: item?.price ? item?.price : 0,
              taxAmount: item?.tax ? item?.price : 0,
            });
          }
        });
        item.isModified &&
          orderItems.push({
            id: item?.idToModifyKitchenOrder ?? "",
            product: item?.pk,
            quantity: item?.quantity,
            note: item?.note,
            modifier: modifierItems,
            taxAmount: parseFloat(item?.tax).toFixed(2),
            enteredAmount: parseFloat(item?.price).toFixed(2),
            promotionProducts:
              item?.compound?.length > 0
                ? item?.compound?.map((items: any) => items?.pk).join(",")
                : "",
          });
      });
      const modifiedResponse = await modifiedCreateKitchenOrder({
        variables: {
          orderInfo: orderInfo,
          orderItems: orderItems,
        },
      });
      let editedModifiedList = otherDeletedItems.map(
        (item: any, itemIndex: number) => ({
          ...item,
          isModified: false,
        })
      );
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar(editedModifiedList),
        },
      });

      if (orderItems?.length) {
        setLocalKey(
          `${KitchenCardItemData?.kitchenOrder?.id}`,
          JSON.stringify(false)
        );
      }
      if (modifiedResponse) {
        let sortedList;
        if (
          basicSetting?.basicWebsiteConfigurations?.printProductByCategoryOrder
        ) {
          sortedList =
            modifiedResponse?.data?.topUpKitchenOrder?.modifiedKitchenOrderItems.sort(
              (a: any, b: any) => {
                const aOrder = a?.product?.printOrder?.productOrder;
                const bOrder = b?.product?.printOrder?.productOrder;
                if (aOrder == null && bOrder == null) {
                  return 0;
                } else if (aOrder == null) {
                  return 1;
                } else if (bOrder == null) {
                  return -1;
                }
                return parseFloat(aOrder) - parseFloat(bOrder);
              }
            );
        } else {
          sortedList =
            modifiedResponse?.data?.topUpKitchenOrder
              ?.modifiedKitchenOrderItems;
        }

        let kitchenOrderID =
          modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.id;
        const kitchenCardItemId: any = {
          id: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.id,
          pk: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.pk,
          customerPk:
            modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder?.customer
              ?.pk,
          orderTypePk: modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder
            ?.orderMethod
            ? modifiedResponse?.data?.topUpKitchenOrder?.kitchenOrder
                ?.orderMethod?.pk
            : " ",
        };
        kitchenOrdreCache.writeQuery({
          query: GET_KITCHEN_CARD_ID,
          data: {
            kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
          },
        });
        setIsLoading(false);
        await handleElectronKitchenPrint(
          sortedList,
          kitchenOrderID,
          true,
          receiptPrint
        );
        toast.success("Top up order sent to kitchen");
      }
    } else {
      const orderList = cartItems;
      let orderItems: any = [];
      if (cartItems) {
        let orderInfo = {
          customer: customerItems?.pk ? customerItems.pk : "",
          table: tableItems?.mergedId
            ? tableItems.mergedId
            : tableItems?.pk ?? "",
          note: noteItems ? noteItems : "",
          orderMethod: orderType?.pk ?? "",
        };
        orderList.map((item: any) => {
          let modifierItems: any = [];
          item?.modifier?.map((item: any) => {
            if (item?.quantity > 0) {
              modifierItems.push({
                product: item?.pk,
                quantity: item?.quantity,
                note: item?.note,
                enteredAmount: item?.price ? item?.price : 0,
                taxAmount: item?.tax ? item?.price : 0,
              });
            }
          });
          item.isDeleted === false &&
            orderItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
              modifier: modifierItems,
              taxAmount: parseFloat(item?.tax).toFixed(2),
              enteredAmount: parseFloat(item?.price).toFixed(2),
              promotionProducts:
                item?.compound?.length > 0
                  ? item?.compound?.map((items: any) => items?.pk).join(",")
                  : "",
            });
        });
        const response = await createKitchenOrder({
          variables: {
            orderInfo: orderInfo,
            orderItems: orderItems,
          },
        });
        if (response) {
          let sortedList;
          if (
            basicSetting?.basicWebsiteConfigurations
              ?.printProductByCategoryOrder
          ) {
            sortedList =
              response?.data?.createKitchenOrder?.kitchenOrder?.kitchenOrderItems.sort(
                (a: any, b: any) => {
                  const aOrder = a?.product?.printOrder?.productOrder;
                  const bOrder = b?.product?.printOrder?.productOrder;
                  if (aOrder == null && bOrder == null) {
                    return 0;
                  } else if (aOrder == null) {
                    return 1;
                  } else if (bOrder == null) {
                    return -1;
                  }
                  return parseFloat(aOrder) - parseFloat(bOrder);
                }
              );
          } else {
            sortedList =
              response?.data?.createKitchenOrder?.kitchenOrder
                ?.kitchenOrderItems;
          }

          if (response?.data?.createKitchenOrder?.success) {
            let kitchenOrderId =
              response?.data?.createKitchenOrder?.kitchenOrder?.id;
            const kitchenCardItemId: any = {
              id: response?.data?.createKitchenOrder?.kitchenOrder?.id,
              pk: response?.data?.createKitchenOrder?.kitchenOrder?.pk,
            };
            kitchenOrdreCache.writeQuery({
              query: GET_KITCHEN_CARD_ID,
              data: {
                kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
              },
            });
            await handleElectronKitchenPrint(
              sortedList,
              kitchenOrderId,
              true,
              receiptPrint
            );
            toast.success("Order sent to kitchen");
          } else {
            toast.error("Fail to create remote order");
          }
        }
      }
    }
    setIsLoading(false);
  };
  const handleKitchenClear = (e: any) => {
    handleClearPosScreen();
    e.stopPropagation();
    setKitchenPrintAlertPopup(false);
  };

  const [refetchPos] = useLazyQuery(DEFAULT_POS_SCREEN, {
    fetchPolicy: "network-only",
  });
  const { data: actionMenus, loading: loadActionMenu } = useQuery(
    ACTION_MENUS,
    {
      variables: {
        name_Startswith: startWith,
      },
      fetchPolicy: "network-only",
    }
  );
  const dropdownRef = useRef<any>(null);
  const handleRightClick = (e: any, action: any, index: number) => {
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      if (screenPopup) {
        setScreenPopup(false);
      } else {
        setScreenPopup(true);
        setScreenId(action?.node?.pk);
      }
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setScreenPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const [assignScreen, { loading: assignScreenLoading }] = useMutation(
    ASSIGN_MENUS,
    {
      refetchQueries: [
        {
          query: ACTION_MENUS,
          variables: {
            name_Startswith: startWith,
          },
        },
        {
          query: DEFAULT_POS_SCREEN,
        },
      ],
      fetchPolicy: "network-only",
    }
  );

  const handleClickOptions = async (value: any, id: any) => {
    const responsePos = await refetchPos();
    const response = await assignScreen({
      variables: {
        actionMenu: id,
        type: value?.slug,
        displayText: value?.slug,
        actionName: value?.name,
        screenCategory: responsePos?.data?.defaultPosScreen?.screen?.pk,
        name: value?.name,
      },
    });
    if (response?.data?.assignActionMenu?.success) {
      toast.success("Menu Updated Successfully");
    } else {
      toast.error("Menu Updated Error");
    }
  };
  const [removeActionScreenMenu, { loading: removeScreenMenuLoading }] =
    useMutation(REMOVE_ACTION_MENU, {
      refetchQueries: [
        {
          query: ACTION_MENUS,
          variables: {
            name_Startswith: startWith,
          },
        },
      ],
      fetchPolicy: "network-only",
    });
  const handleRemovePlaceholder = async (id: any) => {
    const response = await removeActionScreenMenu({
      variables: {
        screenActionMenuId: id,
      },
    });
    if (response?.data?.removeScreenActionMenu?.success) {
      toast.success("Removed Successfully");
    } else {
      toast.error("Removed unSucessful");
    }
  };
  const [flag, setFlag] = useState(false);
  const variableData: any = [];

  useEffect(() => {
    if (
      flag &&
      KitchenCardItemData &&
      Object.keys(KitchenCardItemData).length > 0
    ) {
      setFlag(false);
    }
  }, [flag, KitchenCardItemData]);

  const handlePrintReciept = () => {
    handleOrderProduct(true);
    setFlag(true);
  };
  const handleClickButtons = (e: any, value: any, type: any) => {
    switch (type) {
      case "table":
        setOpenPopup(true);
        setOpenOrderType("table");
        break;
      case "order_type":
        setOpenPopup(true);
        setOpenOrderType("order_type");
        break;
      case "pos":
        navigate("/");
        break;
      case "hold_cart":
        navigate("/pos-screen-hold-card");
        break;
      case "remote_order":
        navigate("/pos-screen-kitchen-card");
        break;
      case "orders":
        navigate("/pos-screen-order");
        break;
      case "transaction":
        navigate("/pos-screen-transaction");
        break;
      case "customer":
        navigate("/pos-screen-customers");
        break;
      case "stock":
        if (tableItems?.title || orderType?.title || cartItems?.length > 0) {
          setKitchenPrintAlertPopup(true);
        } else {
          navigate("/stock");
        }
        break;
      case "product":
        if (tableItems?.title || orderType?.title || cartItems?.length > 0) {
          setKitchenPrintAlertPopup(true);
        } else {
          navigate("/product");
        }
        break;
      case "sales_report":
        if (tableItems?.title || orderType?.title || cartItems?.length > 0) {
          setKitchenPrintAlertPopup(true);
        } else {
          navigate("/sales-report");
        }
        break;
      case "hold_order":
        setOpenHoldPopup(true);
        break;
      case "booking_screen":
        setOpenBookingScreenPopup(true);
        break;
      case "void_order":
        setOpenVoidPopup(true);
        break;
      case "pay":
        if (openPopupKitchenPrintDisable === true) {
          if (modItems?.length) {
            setOpenPayKitchenPrintAlert(true);
          } else {
            setOpenPayPopup(true);
          }
        } else {
          setOpenPayPopup(true);
        }
        break;
      case "kitchen_print":
        handleOrderProduct();
        break;
      case "clear":
        handleClear(e);
        break;
      case "discount":
        setOpenDiscountPopup(true);
        break;
      case "print_discount_docket":
        handleElectronPrintLastDocketReceipt(e);
        break;
      case "note":
        setOpenNotePopup(true);
        break;
      case "receipt_print":
        if (isEnableA4) {
          handlePrintReciept();
        } else {
          handleElectronPrintReceipts(e);
        }
        break;
      case "surcharge":
        setOpenSuchargePopup(true);
        break;
    }
  };
  return (
    <>
      {startWith === "L" &&
        actionMenus?.actionMenus?.edges?.map((action: any, index: number) => {
          const value = actionValue?.find(
            (actionId) => actionId.slug === action.node?.action?.type
          );
          return (
            <React.Fragment key={index}>
              <div
                className="m-auto flex justify-center items-center h-full w-full relative cursor-pointer"
                onClick={() => setScreenPopup(false)}
                onContextMenu={(e: any) => handleRightClick(e, action, index)}
              >
                <IndividualButtons
                  handleClickButtons={handleClickButtons}
                  action={action}
                  startWith={startWith}
                  tableItems={tableItems}
                  permissionTypes={permissionTypes}
                  permissionType={permissionType}
                  setOpenCancelPopup={setOpenVoidPopup}
                  setPermPopup={setPermPopup}
                  setPermissionType={setPermissionType}
                  kitchenOrdreItemId={kitchenOrdreItemId}
                  profile={""}
                  value={value}
                  cartItems={cartItems}
                  orderType={orderType}
                />

                {screenPopup &&
                  action?.node?.pk === screenId &&
                  !action?.node?.action?.actionName && (
                    <div
                      className={`${
                        index > 3
                          ? "absolute text-black w-[200px] h-[500px] p-2  right-[-200px] bg-white z-10 overflow-y-auto rounded-md shadow-md bottom-0 "
                          : "absolute overflow-y-auto text-black w-[200px] h-[500px] p-2  right-[-200px] bg-white z-10 rounded-md shadow-md top-0"
                      }`}
                      ref={dropdownRef}
                    >
                      {actionValue?.map((value: any) => (
                        <p
                          onClick={() =>
                            handleClickOptions(value, action?.node?.pk)
                          }
                          className="p-2 cursor-pointer hover:bg-primary hover:text-white "
                        >
                          {value?.name}
                        </p>
                      ))}
                    </div>
                  )}
                {screenPopup &&
                  action?.node?.pk === screenId &&
                  action?.node?.action?.actionName && (
                    <div
                      className={`${
                        index > 3
                          ? "absolute text-black  w-[200px]  p-2  right-[-200px] bg-white z-10 rounded-md shadow-md bottom-0 "
                          : "absolute  text-black w-[200px] p-2  right-[-200px] bg-white z-10 rounded-md shadow-md top-0"
                      }`}
                      ref={dropdownRef}
                    >
                      <p
                        onClick={() =>
                          handleRemovePlaceholder(action?.node?.action?.pk)
                        }
                        className="p-2 cursor-pointer hover:bg-primary hover:text-white"
                      >
                        Remove
                      </p>
                    </div>
                  )}
              </div>
            </React.Fragment>
          );
        })}

      {startWith === "R" &&
        actionMenus?.actionMenus?.edges?.map((action: any, index: number) => {
          const value = actionValue?.find(
            (actionId) => actionId.slug === action.node?.action?.type
          );
          return (
            <div
              className="flex justify-center items-center h-full w-full relative cursor-pointer"
              key={index}
              onClick={() => setScreenPopup(false)}
              onContextMenu={(e: any) => handleRightClick(e, action, index)}
            >
              <IndividualButtons
                handleClickButtons={handleClickButtons}
                action={action}
                tableItems={tableItems}
                permissionTypes={permissionTypes}
                permissionType={permissionType}
                startWith={startWith}
                setOpenCancelPopup={setOpenVoidPopup}
                setPermPopup={setPermPopup}
                setPermissionType={setPermissionType}
                value={value}
                profile={""}
                cartItems={cartItems}
                kitchenOrdreItemId={kitchenOrdreItemId}
                orderType={orderType}
              />
              {screenPopup &&
                action?.node?.pk === screenId &&
                !action?.node?.action?.actionName && (
                  <div
                    className={
                      "absolute text-black w-[200px] p-2 h-[500px] z-50 overflow-y-auto left-[-200px] bg-white rounded-md shadow-md bottom-0 "
                    }
                    ref={dropdownRef}
                  >
                    {actionValue?.map((value: any) => (
                      <p
                        onClick={() =>
                          handleClickOptions(value, action?.node?.pk)
                        }
                        className="p-2 cursor-pointer hover:bg-primary hover:text-white "
                      >
                        {value?.name}
                      </p>
                    ))}
                  </div>
                )}
              {screenPopup &&
                action?.node?.pk === screenId &&
                action?.node?.action?.actionName && (
                  <div
                    className={
                      "absolute text-black w-[200px] p-2  left-[-200px] bg-white z-10 rounded-md shadow-md bottom-0 "
                    }
                    ref={dropdownRef}
                  >
                    <p
                      onClick={() =>
                        handleRemovePlaceholder(action?.node?.action?.pk)
                      }
                      className="p-2 cursor-pointer hover:bg-primary hover:text-white"
                    >
                      Remove
                    </p>
                  </div>
                )}
            </div>
          );
        })}
      <Modal
        open={openTable ? openTable : openPopup}
        setOpen={setOpenPopup}
        size={openOrderType === "table" ? "max-w-full h-screen" : "max-w-7xl"}
        children={
          <PosScreenTableFunction
            openOrderType={openOrderType}
            selectedTerminal={current}
            setOpenPopup={setOpenPopup}
          />
        }
        title={openOrderType === "table" ? "Table" : "OrderType"}
      />
      <PosScreenHoldOrderFunction
        openHoldPopup={openHoldPopup}
        setOpenHoldPopup={setOpenHoldPopup}
      />
      <PosScreenVoidOrderFunction
        setOpenVoidOrder={setOpenVoidPopup}
        openVoidOrder={openVoidPopup}
        setOpenHoldPopup={setOpenHoldPopup}
      />
      <PosScreenDiscountFunction
        openDiscountPopup={openDiscountPopup}
        setSelectDiscountType={setSelectDiscountType}
        selectDiscountType={selectDiscountType}
        setOpenDiscountPopup={setOpenDiscountPopup}
      />
      <PosScreenSurchargeFunction
        openSuchargePopup={openSuchargePopup}
        setOpenSuchargePopup={setOpenSuchargePopup}
      />
      <PosScreenPayFunction
        openPayPopup={openPayPopup}
        setOpenPayPopup={setOpenPayPopup}
        setSelectSuchargeType={setSelectSuchargeType}
        setSelectDiscountType={setSelectDiscountType}
        selectedTerminal={currentTerminalData?.currentTerminal}
      />
      <PosScreenNoteFunction
        openNotePopup={openNotePopup}
        setOpenNotePopup={setOpenNotePopup}
      />
      {/* Permission Grant Modal*/}
      <Modal title={"Alert"} open={permPopup} setOpen={setPermPopup}>
        <p className="py-4"> You don't have permission for the change </p>
        <Login permission={permissionType} setPermPopup={setPermPopup} />
      </Modal>
      <Modal
        title={"Clear Alert !!"}
        open={kitchenPrintAlertPopup}
        setOpen={setKitchenPrintAlertPopup}
        children={
          <>
            <p className="p-3">Are You Sure You Want to Clear??</p>
            <button
              className="bg-primary text-white w-20 h-10 rounded mt-2"
              onClick={(e: any) => handleKitchenClear(e)}
            >
              Clear
            </button>
          </>
        }
      />
      <Modal
        title={"Kitchen Print Alert !!"}
        open={openPayKitchenPrintAlert}
        setOpen={setOpenPayKitchenPrintAlert}
        children={
          <>
            <p className="p-3">Please Make the Kitchen Print first!! </p>
            <p className="p-3">Compulsory Kitchen Print</p>
            <button
              className="bg-primary text-white w-32 h-10 rounded mt-2"
              onClick={() => {
                setOpenPayPopup(true);
                setOpenPayKitchenPrintAlert(false);
              }}
            >
              OpenAnyway
            </button>
          </>
        }
      />
      {/* <Modal
        title={bookingTableTitle}
        open={openBookingScreenPopup}
        setOpen={setOpenBookingScreenPopup}
        size="max-w-3xl"
        children={
          <PosScreenAddBooking setBookingTableTitle={setBookingTableTitle} />
        }
      /> */}
    </>
  );
};

export default PosScreenButtons;
