//@ts-nocheck
import * as Yup from "yup";
import "yup-phone";

const categoryFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Category name is Required"),
  status: Yup.string().required("Select a status"),
});

const dataSyncFormValidationSchema = Yup.object().shape({
  domainName: Yup.string().required("DomainName is Required"),
});
const loginFormValidationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});
const loginOfflineFormValidationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  login_pin: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
});
const asttendanceOfflineFormValidationSchema = Yup.object({
  user: Yup.string().required("User is required"),
  pin: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
});

const ForgotPasswordFormValidationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
});

const resetNewPasswordFormValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required("Please enter your password.")
    .min(8, "Your password is too short."),
  confirmPassword: Yup.string()
    .required("Please retype your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
});

const verifyOTPFormValidationSchema = Yup.object({
  otp: Yup.string().required("OTP is required!"),
});

const materialFormValidationSchema = Yup.object({
  title: Yup.string().required("material is required"),
  unitName: Yup.string().required("unit is required"),
});

const smsProviderFormValidationSchema = Yup.object({
  provider: Yup.string().required("required"),
  senderMobilenumber: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
  publicKey: Yup.string().required("required"),
  privateKey: Yup.string().required("required"),
});

const unitFormValidationSchema = Yup.object({
  title: Yup.string().required("unitl is required"),
  shortCode: Yup.string().required("shortcode is required"),
});
const PaymentFormValidationSchema = Yup.object({
  bankName: Yup.string().required("Bank is required"),
  accountName: Yup.string().required("Account Holder'S Name is required"),
  amountPay: Yup.string().required("Amount is required"),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
  accountNumber: Yup.string().required("Account Number is required"),
});
const CustomerFormValidationSchema = Yup.object({
  fullName: Yup.string().required("FullName is required"),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
});
const importCustomerValidationSchema = Yup.object({
  fullName: Yup.string().required("FullName is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("PhoneNumber is required"),
});

const userFormValidationSchema = Yup.object({
  fullName: Yup.string().trim().required("FullName is required"),
  username: Yup.string().required("Pin is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
      "Must Contain 8 Characters and One Uppercase or Numeric Characters",
    ),
  confirmPassword: Yup.string()
    .required("Re-password is required")
    .oneOf([Yup.ref("password"), null], "Password don`t matched"),
});
const editUserProfileValidation = Yup.object({
  contactNumber: Yup.string().phone(undefined, false, "Invalid Mobile Number"),
  password: Yup.string().matches(
    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
    "Must Contain 8 Characters and One Uppercase or Numeric Characters",
  ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password don`t matched",
  ),
});
const productFormValidationSchema = Yup.object({
  title: Yup.string().required("This field is required"),
  unit: Yup.string().required("This field is required"),
});

const attributeFormValidationSchema = Yup.object({
  label: Yup.string().required("Attribute name is required"),
});

const attributeValueFormValidationSchema = Yup.object({
  label: Yup.string().required("Attribute label is required"),
});

const settingFormValidationSchema = Yup.object({
  company: Yup.string().required("Company is required"),
  graceBeforeCheckIn: Yup.string().required(
    "Grace before check in is required",
  ),
  graceAfterCheckIn: Yup.string().required("Grace after check in is required"),
  graceBeforeCheckOut: Yup.string().required(
    "Grace before checkout is required",
  ),
  graceAfterCheckOut: Yup.string().required("Grace after checkout is required"),
});

const posScreenProductFormValidationSchema = Yup.object({
  title: Yup.string().required("Product name is required"),
  sellingPrice: Yup.string().required("Selling Price is required"),
  costPrice: Yup.string().required("Cost Price is required"),
  category: Yup.array().required("Category is required"),
});
const importProductValidationSchema = Yup.object({
  title: Yup.string().required("Product name is required"),
  sellingPrice: Yup.string().required("Selling price is required"),
});
const addProductOpenValidationSchema = Yup.object({
  title: Yup.string().required("Product name is required"),
  price: Yup.string().required("Price is required"),
  printer: Yup.array().required("Printer is required"),
});
const posScreenQuickProductFormValidationSchema = Yup.object({
  title: Yup.string().required("Product name is required"),
  category: Yup.array().required("Category is required"),
  costPrice: Yup.string().required("Cost Price is required"),
  sellingPrice: Yup.string().required("Selling Price is required"),
});

const posScreenModifierFormValidationSchema = Yup.object({
  title: Yup.string().required("Modifier name is required"),
  category: Yup.array().required("Category is required"),
});
const posScreenQuickModifierFormValidationSchema = Yup.object({
  title: Yup.string().required("Modifier name is required"),
  price: Yup.string().required("Price is required"),
  category: Yup.array().required("Category is required"),
});

const wareHouseFormValidationSchema = Yup.object({
  name: Yup.string().required("Is required"),
});

const purchaseStockValidationSchema = Yup.object({
  supplier: Yup.string().required("Supplier field is required"),
  costPrice: Yup.string().required("CostPrice field is required"),
  quantity: Yup.string().required("Quantity field is required"),
});

const supplierPaymentValidationSchema = Yup.object({
  transactionAccounts: Yup.string().required("Select an account type"),
  user: Yup.string().required("Supplier field is required"),
  amount: Yup.number().required("Amount is required"),
  note: Yup.string().required("Note is required"),
});

const supplierValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is requried"),
  primaryEmail: Yup.string()
    .required("Email is requried")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
  primaryContact: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
  fullAddress: Yup.string().required("Address is requried"),
});

const addExpenseValidationSchema = Yup.object({
  amount: Yup.number().required("Amount is required"),
  note: Yup.string().required("Note is required"),
});

const acceptPaymentValidationSchema = Yup.object({
  amount: Yup.number().required("Amount is required"),
  account: Yup.string().required("Select account type"),
  note: Yup.string().required("Note is required"),
});
const AccountFormValidationSchema = Yup.object({
  name: Yup.string().required("Account name is required"),
});

const carouselFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Carousel name is required"),
});

const salesDiscountValidationSchema = Yup.object().shape({
  title: Yup.string().required("Discount title required"),
  discountType: Yup.string().required("Select a type of discount"),
  rateAmount: Yup.string().required("Enter discount rate"),
});

const giftCardDiscountValidtionSchema = Yup.object().shape({
  couponCode: Yup.string().required("Coupon code is required"),
  title: Yup.string().required("Gift card name is required"),
  discountType: Yup.string().required("Select a type of discount"),
  rateAmount: Yup.string().required("Enter discount rate"),
});

const bulkPurchaseStockValidationSchema = Yup.object({
  supplier: Yup.object().required("Select a Supplier"),
  product: Yup.object().required("Select a Product"),
  costPrice: Yup.number().required("Enter Cost Price"),
  quantity: Yup.number().required("Enter Quantity"),
});

const manageStockValidationSchema = Yup.object({
  product: Yup.object({
    id: Yup.string().nullable().required("Product is required"),
    title: Yup.string().nullable().required("Product is required"),
  })
    .nullable()
    .required("Product is required"),
  quantity: Yup.number().required("Enter Quantity"),
});

const emailValidationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
});

const collectionValidationSchema = Yup.object({
  name: Yup.string().required("Collection Name is required"),
});

const periodicTasksValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  task: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  interval: Yup.object().required("Required"),
});

const taxValidationSchema = Yup.object({
  title: Yup.string().required("GST title is required"),
  rate: Yup.string().required("Enter discount rate"),
});

const deliveryMethodValidationSchema = Yup.object({
  title: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
});
const paymentServicesValidationSchema = Yup.object({
  account: Yup.string().required("Required"),
  secretKey: Yup.string().required("Required"),
  publicKey: Yup.string().required("Required"),
});

const cashPaymentServicesValidationSchema = Yup.object({
  account: Yup.string().required("Required"),
});

const esewaPaymentServicesValidationSchema = Yup.object({
  account: Yup.string().required("This field is required"),
  endPoint: Yup.string().required("This field is required"),
  successfulPaymentRedirectURL: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct url!",
    )
    .required("This field is required"),
  paymentFailureRedirectURL: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct url!",
    )
    .required("Enter valid url"),
  paymentVerificationURL: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct url!",
    )
    .required("This field is required"),
  merchentCode: Yup.string().required("This field is required"),
});

const formsValidationSchema = Yup.object({
  name: Yup.string().required("Username is required"),
});

const formsEditValidationSchema = Yup.object({
  fields: Yup.string().required("Username is required"),
});

const transactionValidationSchema = Yup.object({
  amount: Yup.string().required("Amount is required"),
  note: Yup.string().required("Note is required"),
});

const userProfileValidationSchema = Yup.object({
  fullName: Yup.string().trim().required("Full Name is required"),
  email: Yup.string()
    .nullable(true)
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
  contactNumber: Yup.string()
    .nullable()
    .phone(undefined, false, "Invalid Mobile Number"),
});

const testimonialValidationSchema = Yup.object({
  fullName: Yup.string().required("Fullname is required"),
  profession: Yup.string().required("Profession is required"),
  content: Yup.string().required("Content is required"),
});

const navigationValidationSchema = Yup.object({
  name: Yup.string().required("Navigation title is requried"),
});

const subnavigationValidationSchema = Yup.object({
  name: Yup.string().required("Sub navigation title is requried"),
  link: Yup.object().required("Select a link"),
});

const printerValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  department: Yup.string().required("Department is required"),
  type: Yup.string().required("Select a printer type"),
  location: Yup.string().required("Location is required"),
});

const createUpdateRewardPointSettingValidationSchema = Yup.object({
  amount: Yup.string().required("Amount is required"),
  point: Yup.string().required("Point is required"),
  minRequiredPoint: Yup.string().required("MinRequiredPoint is required"),
});

const selectedTransactionAccountValidationSchema = Yup.object({
  name: Yup.string().required("Account Name is required"),
});

const addressValidationSchema = Yup.object({
  country: Yup.string().required("Country is required"),
  province: Yup.string().required("Province is required"),
  district: Yup.string().required("District is required"),
  city: Yup.string().required("City is required"),
});

const socialMediaValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  url: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct url!",
    )
    .required("This field is required"),
});

const restaurantTableValidationSchema = Yup.object({
  title: Yup.string().required("Table name is required"),
  capacity: Yup.string().test(
    "len",
    "must be greater than one number and not more than 5",
    (val) => {
      if (val == undefined) {
        return true;
      }
      return val.length == 0 || (val.length >= 1 && val.length <= 5);
    },
  ),
  floor: Yup.string().required("Floor name is required"),
});
const restaurantFloorValidationSchema = Yup.object({
  title: Yup.string().required("Table name is required"),
});
const terminalValidationSchema = Yup.object({
  name: Yup.string().required("Terminal name is required"),
  printer: Yup.string().required("Printer is required"),
  ip: Yup.string()
    .required("Ip address is required")
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Invalid IP address",
      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "IP address value should be less or equal to 255",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      },
    ),
});
const screenValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  noOfCats: Yup.number().required("Category Number is required"),
  noOfProducts: Yup.number().required("Product Number is required"),
});
const scaleSettingsSchema = Yup.object({
  scalePort: Yup.string().required("Port is required"),
  scaleBaudRate: Yup.number().required("BaudRate is required"),
  scaleDateBits: Yup.number().required("DataBits is required"),
  scaleParity: Yup.string().required("Parity is required"),
  scaleStopBits: Yup.number().required("StopBits is required"),
});
const voucherDiscountValidationSchema = Yup.object({
  couponCode: Yup.string().required("Coupon code is required"),
  title: Yup.string().required("Voucher Name is required"),
  discountType: Yup.string().required("Select type of discount"),
  rateAmount: Yup.string().required("Discount rate is required"),
});

const serviceChargeValidationSchema = Yup.object({
  title: Yup.string().required("Service charge title is required"),
  rate: Yup.string().required("Discount rate is required"),
});

const activateValidationSchema = Yup.object({
  liscenseKey: Yup.string().required("LiscenseKey is required"),
});

const appPageValidationSchema = Yup.object({
  name: Yup.string().required("Page name is required"),
});

const initialSetupBasicSettingsValidationSchema = Yup.object({
  name: Yup.string().required("Domain title is required"),
  country: Yup.string().required("Select a country"),
  timezone: Yup.string().required("Select a timezone"),
  currency: Yup.string().required("Select a currency"),
  location: Yup.string().required("Location is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
  phone: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Number"),
});

const initialSetupUserValidationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
      "Must Contain 8 Characters and One Uppercase or Numeric Characters",
    ),
  repeatPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password don`t matched"),
});

const initialSetupOtpValidationSchema = Yup.object({
  otp: Yup.string().required("Please Enter OTP code"),
});

const wastageAndLossReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const stockReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const quickSalesReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const detailSalesReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});
const hourlySalesReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const employeeHourReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const membersReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const noSaleReportValidationSchema = Yup.object({
  fromDate: Yup.date().typeError("Start date is required").required(),
  toDate: Yup.date().typeError("End date is required").required(),
});

const openingAndClosingReportValidationSchema = Yup.object({
  floatAmount: Yup.string().required("Float Amount is required"),
});
const roleValidationSchema = Yup.object({
  name: Yup.string().required("Role Name is required"),
});
const promotionValidationSchema = Yup.object({
  title: Yup.string().required("Tilte is required"),
  sellingPrice: Yup.string().required("Selling Price is required"),
});
export const SMSValidationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .phone(undefined, false, "Invalid Mobile Number"),
});
export const EmailValidationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid",
    ),
});

export const bookingTableValidationSchema = Yup.object().shape(
  {
    bookingDate: Yup.string().required("Booking Date is required"),
    bookingTime: Yup.string().required("Booking Time is required"),
    noOfPeople: Yup.number().when("selectManuallyPerson", {
      is: (selectManuallyPerson: any) => !selectManuallyPerson,
      then: Yup.number().required("Number of people is required"),
      otherwise: Yup.number().nullable(),
    }),
    selectManuallyPerson: Yup.number().when("noOfPeople", {
      is: (noOfPeople: any) => !noOfPeople,
      then: Yup.number().required("Select person manually is required"),
      otherwise: Yup.number().nullable(),
    }),
    table: Yup.array()
      .of(Yup.string().required("Table is required"))
      .min(1, "At least one table must be selected"),
  },
  [["noOfPeople", "selectManuallyPerson"]],
);

export const customerBookingValidationSchema = Yup.object({
  customer: Yup.string(),
  firstName: Yup.string().when("customer", {
    is: (value: any) => !value, // Check if 'customer' is empty
    then: Yup.string().required("First Name is required"), // Apply validation when 'customer' is empty
    otherwise: Yup.string(), // No validation when 'customer' has a value
  }),
  lastName: Yup.string().when("customer", {
    is: (value: any) => !value,
    then: Yup.string().required("Last Name is required"),
    otherwise: Yup.string(),
  }),
  email: Yup.string().when("customer", {
    is: (value: any) => !value,
    then: Yup.string()
      .required("Email is required")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Email is not valid",
      ),
    otherwise: Yup.string(),
  }),
  phone: Yup.string().when("customer", {
    is: (value: any) => !value,
    then: Yup.string()
      .required("Mobile Number is required")
      .phone(undefined, false, "Invalid Mobile Number"),
    otherwise: Yup.string(),
  }),
  acceptTermsAndConditions: Yup.boolean()
    .oneOf([true], "Please agree to terms and conditions")
    .required("Please agree to terms and conditions"),
});

export const holidayBookingValidationSchema = Yup.object({
  holidayStartDate: Yup.string().required("Holiday Start Date is required"),
  holidayEndDate: Yup.string().required("Holiday End Date required"),
});

export {
  wastageAndLossReportValidationSchema,
  stockReportValidationSchema,
  quickSalesReportValidationSchema,
  detailSalesReportValidationSchema,
  employeeHourReportValidationSchema,
  membersReportValidationSchema,
  noSaleReportValidationSchema,
  openingAndClosingReportValidationSchema,
  initialSetupBasicSettingsValidationSchema,
  initialSetupOtpValidationSchema,
  initialSetupUserValidationSchema,
  voucherDiscountValidationSchema,
  collectionValidationSchema,
  restaurantTableValidationSchema,
  restaurantFloorValidationSchema,
  terminalValidationSchema,
  materialFormValidationSchema,
  unitFormValidationSchema,
  categoryFormValidationSchema,
  loginFormValidationSchema,
  loginOfflineFormValidationSchema,
  asttendanceOfflineFormValidationSchema,
  ForgotPasswordFormValidationSchema,
  verifyOTPFormValidationSchema,
  resetNewPasswordFormValidationSchema,
  settingFormValidationSchema,
  PaymentFormValidationSchema,
  attributeValueFormValidationSchema,
  productFormValidationSchema,
  CustomerFormValidationSchema,
  importCustomerValidationSchema,
  userFormValidationSchema,
  editUserProfileValidation,
  posScreenProductFormValidationSchema,
  posScreenQuickProductFormValidationSchema,
  posScreenModifierFormValidationSchema,
  posScreenQuickModifierFormValidationSchema,
  smsProviderFormValidationSchema,
  wareHouseFormValidationSchema,
  purchaseStockValidationSchema,
  supplierPaymentValidationSchema,
  supplierValidationSchema,
  addExpenseValidationSchema,
  acceptPaymentValidationSchema,
  AccountFormValidationSchema,
  carouselFormValidationSchema,
  salesDiscountValidationSchema,
  bulkPurchaseStockValidationSchema,
  manageStockValidationSchema,
  emailValidationSchema,
  periodicTasksValidationSchema,
  deliveryMethodValidationSchema,
  paymentServicesValidationSchema,
  cashPaymentServicesValidationSchema,
  formsValidationSchema,
  formsEditValidationSchema,
  transactionValidationSchema,
  testimonialValidationSchema,
  esewaPaymentServicesValidationSchema,
  printerValidationSchema,
  createUpdateRewardPointSettingValidationSchema,
  addressValidationSchema,
  userProfileValidationSchema,
  socialMediaValidationSchema,
  selectedTransactionAccountValidationSchema,
  attributeFormValidationSchema,
  giftCardDiscountValidtionSchema,
  taxValidationSchema,
  serviceChargeValidationSchema,
  navigationValidationSchema,
  subnavigationValidationSchema,
  appPageValidationSchema,
  roleValidationSchema,
  promotionValidationSchema,
  dataSyncFormValidationSchema,
  activateValidationSchema,
  addProductOpenValidationSchema,
  scaleSettingsSchema,
  hourlySalesReportValidationSchema,
  importProductValidationSchema,
  screenValidationSchema,
};
