import { useEffect, useState } from "react";

import {
  cache,
  cartItemsVar,
  searchCache,
  searchItemsVar,
} from "../localstate/Cache";
import { GET_CART_ITEMS, GET_SEARCH_ITEMS } from "../localstate/Cart";
import PrimaryButton from "../reusable/Buttons/PrimaryButton";
import Modal from "../reusable/Modal";
import { useQuery } from "@apollo/client";
import ColorPicker from "../reusable/ColorPicker";
import { toast } from "react-toastify";
import ReusableModifierPlaceholders from "./ReusableModifierPlaceholders";
import { setLocalKey } from "../../helpers/sessionKey";
import classNames from "../../utils/classNames";
import { PRODUCTS_BY_MODIFIERCATEGORY } from "../../pages/PosScreen/queries";
import ModifierPlaceHolderLoader from "../reusable/Loader/ModifierPlaceHolderLoader";

type Props = {
  item?: any;
  open?: boolean;
  setOpen?: any;
  productSingle?: any;
  productSingleItems?: any;
  productIndex?: any;
};
export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const UpdatePosScreenProWithModCat = (props: Props) => {
  const { item, setOpen, productSingle, productIndex, productSingleItems } =
    props;
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openAddQuantityPopup, setOpenAddQuantityPopup] =
    useState<boolean>(false);
  const [activeModifier, setActiveModifier] = useState<any>([]);
  const [enteredNumber, setEnteredNumber] = useState<any>("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCalculate = (data: string) => {
    switch (data) {
      case "backspace":
        if (enteredNumber?.length - 1) {
          setEnteredNumber(
            enteredNumber
              ?.toString()
              .substring(0, enteredNumber?.toString().length - 1),
          );
        } else {
          setEnteredNumber("");
        }
        break;
      case "clear":
        setEnteredNumber("");
        break;
      default:
        setEnteredNumber(enteredNumber + data);
    }
  };

  let cartItems = [...cartItemsVar()];
  const [productInCartList, setProductInCartList] = useState<any>({
    id: "",
    pk: "",
    title: "",
    quantity: 1,
    price: "",
    total: "",
    tax: "",
    taxRate: "",
    isSelected: true,
    isDeleted: false,
    modifier: [],
    isModified: true,
    isUpdated: false,
    productId: "",
    productIndex: "",
  });
  useEffect(() => {
    if (item) {
      setProductInCartList({
        id: item?.id,
        pk: item?.pk,
        title: item?.title,
        quantity: item?.isWeightable ? parseFloat(enteredNumber) : 1,
        price: item?.finalPrice ?? 0,
        total: parseFloat(item?.finalPrice) ?? 0,
        tax: item?.taxAmount,
        taxRate: {
          rate: item?.tax?.rate ?? 0,
          isIncluded: item?.tax?.isIncluded,
        },
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        isUpdated: false,
        productId: productSingle,
        productIndex: productIndex,
      });
    }
  }, [item]);
  useEffect(() => {
    if (productInCartList.id) {
      let modItem: any = item?.modifierCategories?.edges?.map(
        (modCatItem: any, modCatIndex: number) => ({
          title: modCatItem?.node?.category?.name,
          pk: modCatItem?.node?.category?.pk,
          subModifier:
            modCatItem?.node?.category?.productsByCategory?.edges?.map(
              (subModCatItem: any, subModCatIndex: number) => ({
                id: subModCatItem?.node?.id,
                pk: subModCatItem?.node?.pk,
                modifierCategoryId: modCatItem?.node?.category?.pk,
                contentTypeId: subModCatItem?.node?.contentTypeId,
                title: subModCatItem?.node?.title,
                quantity: 1,
                price: subModCatItem?.node?.sellingPrice ?? 0,
                total: parseFloat(subModCatItem?.node?.sellingPrice) ?? 0,
                tax: subModCatItem?.node?.taxAmount,
                taxRate: {
                  rate: subModCatItem?.node?.tax?.rate ?? 0,
                  isIncluded: subModCatItem?.node?.tax?.isIncluded ?? true,
                },
                isSelected: true,
                isDeleted: false,
                modifier: [],
                isModified: true,
                isUpdated: false,
                media: subModCatItem?.node?.media ?? [],
              }),
            ),
        }),
      );

      setProductInCartList((pre: any) => ({ ...pre, modifier: modItem ?? [] }));
    }
  }, [productInCartList?.id]);

  const handleAddProductWithModCategoryClick = () => {
    let modifierItem: any = [];

    productInCartList?.modifier?.forEach((modItem: any) => {
      modItem?.subModifier?.forEach((subModItem: any) => {
        if (subModItem?.isUpdated) {
          modifierItem.push({
            id: subModItem?.id,
            pk: subModItem?.pk,
            title: subModItem?.title,
            quantity: subModItem?.quantity,
            modifierCategoryId: subModItem?.modifierCategoryId,
            price: subModItem?.price ?? 0,
            total:
              (parseFloat(subModItem?.price) ?? 0) *
              (subModItem?.quantity ?? 0),
            tax:
              (parseFloat(subModItem?.tax) ?? 0) *
              (parseFloat(subModItem?.quantity) ?? 0),
            taxRate: subModItem?.taxRate,
            isSelected: true,
            isDeleted: false,
            modifier: [],
            isModified: true,
            isUpdated: false,
          });
        }
      });
    });

    let cartList = {
      id: productInCartList?.id,
      pk: productInCartList?.pk,
      title: productInCartList?.title,
      quantity: item?.isWeightable
        ? parseFloat(enteredNumber)
        : productInCartList?.quantity,
      price: productInCartList?.price ?? 0,
      total: item?.isWeightable
        ? (parseFloat(enteredNumber) ?? 0) *
          parseFloat(productInCartList?.price ?? 0)
        : parseFloat(productInCartList?.price) ?? 0,
      tax: productInCartList?.tax,
      taxRate: productInCartList?.taxRate,
      isSelected: true,
      isDeleted: false,
      modifier: modifierItem ?? [],
      isModified: true,
      isUpdated: false,
      productId: productSingle,
    };

    let updatedCartItems = [...cartItems];

    // Check if the item already exists in the cart
    let previousObject = cartItems.find(
      (x, index) =>
        x.pk === productInCartList?.pk &&
        index === productInCartList?.productIndex,
    );
    if (previousObject) {
      // If the item exists, update its quantity and modifiers
      let foundIndex = cartItems.findIndex(
        (x) => x.id === productInCartList?.id,
      );

      // Update modifiers
      previousObject.modifier = modifierItem;

      // Update the item in the cartItems array
      updatedCartItems[foundIndex] = previousObject;
    } else {
      // If the item is new, add it to the cart with modifiers
      updatedCartItems.push(cartList);
    }

    setProductInCartList(updatedCartItems);

    // Update cache for cart items
    cache.writeQuery({
      query: GET_CART_ITEMS,
      data: {
        cartItems: cartItemsVar([...updatedCartItems]),
      },
    });

    // Update cache for search items
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });

    setOpen(false); // Close the modal
  };

  const handleOnModIncreasingCounter = (
    item: any,
    index: number,
    modifierPk: number,
  ) => {
    const itemCheck = item?.subModifier?.findIndex(
      (item: any) => item.pk === modifierPk,
    );

    setActiveModifier(item.subModifier);
    setProductInCartList((prev: any) => {
      const updatedProductInCartList = { ...prev };
      const subModifierToUpdate =
        updatedProductInCartList.modifier[index].subModifier[itemCheck];

      if (subModifierToUpdate) {
        subModifierToUpdate.isUpdated = !subModifierToUpdate.isUpdated;
      } else {
        toast.info("Empty Modifier");
      }
      return updatedProductInCartList;
    });
  };

  const modifiersFromFirstJSON = productInCartList?.modifier?.flatMap(
    (modifier: any) => modifier.subModifier,
  );
  const modifiersFromSecondJSON = productSingleItems?.modifier;
  const commonModifiersId = modifiersFromFirstJSON?.filter((modifier1: any) =>
    modifiersFromSecondJSON?.some(
      (modifier2: any) =>
        modifier2.id === modifier1.id &&
        modifier1.modifierCategoryId === modifier2.modifierCategoryId,
    ),
  );
  if (commonModifiersId && commonModifiersId.length > 0) {
    productInCartList.modifier.forEach((modifier: any) => {
      modifier.subModifier.forEach((subModifier: any) => {
        const isCommonModifier = commonModifiersId.find(
          (commonModifier: any) =>
            commonModifier.id === subModifier.id &&
            subModifier.modifierCategoryId ===
              commonModifier.modifierCategoryId,
        );

        if (isCommonModifier) {
          const isSelectedModifier = activeModifier?.some(
            (item: any) => item.id === subModifier.id,
          );
          subModifier.isUpdated = isSelectedModifier
            ? !subModifier.isUpdated
            : true;
        }
      });
    });
  }

  const handleAddProductQuantityWithModCategoryClick = () => {
    if (enteredNumber === "" || enteredNumber === null) {
      setOpenAddQuantityPopup(true);
    } else {
      setOpenAddQuantityPopup(false);
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (item?.isWeightable) {
      setOpenPopup(true);
    }
    return () => {
      setOpenPopup(false);
    };
  }, [item]);

  const [productId, setProductId] = useState<string>("");
  const [openContextPopup, setOpenContextPopup] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const handleClickContext = (e: any, item: any) => {
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      setProductId(item?.node?.pk);
      setOpenContextPopup(false);
    }
  };

  const handleQuickAddColor = (e: any) => {
    e.stopPropagation();
    setDisplayColorPicker(true);
    setOpenContextPopup(false);
  };
  const [plachodersValue, setPlachodersValue] = useState(
    productInCartList?.modifier?.[0],
  );

  const { data, loading: modifierCategoryLoading } = useQuery(
    PRODUCTS_BY_MODIFIERCATEGORY,
    {
      variables: {
        modifierCategoryId:
          plachodersValue?.pk ?? productInCartList?.modifier?.[0]?.pk,
        categoryId: plachodersValue?.pk ?? productInCartList?.modifier?.[0]?.pk,
        productId:  productInCartList?.pk,
      },
    },
  );

  const [plachodersValueIndex, setPlachodersValueIndex] = useState<any>(0);
  const handleModifierCategoryWithPlaceholders = (item: any, index: number) => {
    setPlachodersValue(item);
    setPlachodersValueIndex(index);
  };
  let modifierCount = productInCartList?.modifier?.length - 1;
  const value = productInCartList?.modifier?.[plachodersValueIndex + 1];
  const handleOutsideButtonClick = () => {
    setPlachodersValue(value);
    setPlachodersValueIndex(plachodersValueIndex + 1);
  };

  return (
    <div className="">
      <div className="w-full flex flex-wrap gap-2">
        {productInCartList?.modifier?.map((item: any, index: number) => (
          <p
            key={index}
            onClick={() => handleModifierCategoryWithPlaceholders(item, index)}
            className={classNames(
              plachodersValueIndex === index
                ? "bg-primary border-2 border-red-600"
                : "bg-primary",
              "flex p-4 mx-4 rounded cursor-pointer text-white h-[30px] items-center justify-center text-sm font-semibold  shadow-lg hover:shadow-xl transition duration-200",
            )}
          >
            {item?.title}
          </p>
        ))}
      </div>
      {modifierCategoryLoading ? (
        <ModifierPlaceHolderLoader
          count={data?.modifierPlaceHolders?.edges?.length}
        />
      ) : (
        <ReusableModifierPlaceholders
          data={data}
          item={plachodersValue ?? productInCartList?.modifier?.[0]}
          plachodersValueIndex={plachodersValueIndex ?? 0}
          handleOnModIncreasingCounter={handleOnModIncreasingCounter}
          handleClickContext={handleClickContext}
          setOpenContextPopup={setOpenContextPopup}
          openContextPopup={openContextPopup}
          productId={productId}
          handleQuickAddColor={handleQuickAddColor}
          productPk={productInCartList?.pk}
        />
      )}
      <div className={"flex justify-center flex-wrap items-center gap-4 mt-2"}>
        <PrimaryButton text={"Cancel"} onClick={() => setOpen(false)} />
        {!openPopup ? (
          <>
            {modifierCount > plachodersValueIndex ? (
              <>
                <PrimaryButton
                  text={"Next"}
                  onClick={handleOutsideButtonClick}
                />
                <PrimaryButton
                  text={"Done"}
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    handleAddProductWithModCategoryClick();
                    setIsLoading(true);
                  }}
                />
              </>
            ) : (
              <PrimaryButton
                text={"Done"}
                loading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  handleAddProductWithModCategoryClick();
                  setIsLoading(true);
                }}
              />
            )}
          </>
        ) : (
          <PrimaryButton
            text={"Add Quantity"}
            loading={false}
            onClick={() => setOpenAddQuantityPopup(true)}
          />
        )}
      </div>
      <Modal
        title={"Add Quantity"}
        open={openAddQuantityPopup}
        setOpen={setOpenAddQuantityPopup}
        size="max-w-sm"
      >
        <>
          <input
            type="text"
            name={"quantity"}
            className="relative peer bg-[#FFFFFF] p-3  w-full text-gray-700 mt-2 mb-3 rounded"
            style={{
              boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
            }}
            placeholder={"Enter Quantity "}
            value={enteredNumber}
            onChange={(e: any) => setEnteredNumber(e.target.value)}
            autoComplete="off"
          />
          <br />
          <div className="grid grid-cols-3 gap-4">
            {numberPad.map((item: any) => (
              <div
                key={item.id}
                className="col-span-1 w-full  p-2  cursor-pointer bg-[#ffffff] text-[18px] text-center rounded items-center justify-center"
                style={{
                  boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
                }}
                onClick={() => handleCalculate(item.value)}
              >
                <span>{item.label}</span>
              </div>
            ))}
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate(".")}
            >
              <span>.</span>
            </div>
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("0")}
            >
              <span>0</span>
            </div>
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("backspace")}
            >
              <span>x</span>
            </div>
          </div>
          <div className="text-right mt-4">
            <PrimaryButton
              text={"Add"}
              onClick={() => handleAddProductQuantityWithModCategoryClick()}
              loading={false}
              disable={false}
            />
          </div>
        </>
      </Modal>
      <ColorPicker
        type="modifier"
        displayColorPicker={displayColorPicker}
        id={productId}
        productByCatId={""}
        setDisplayColorPicker={setDisplayColorPicker}
        productPk={productInCartList?.pk}
      />
    </div>
  );
};

export default UpdatePosScreenProWithModCat;
