import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  cache,
  cartItemsVar,
  orderTypeVar,
  tableItemsVar,
} from "../../localstate/Cache";
import { GET_CART_ITEMS } from "../../localstate/Cart";

import Modal from "../../reusable/Modal";
import { toast } from "react-toastify";
import PosScreenProWithModCat from "../PosScreenProWithModCat";
import ChangeModalForm from "./ChangeModalForm";

import { Form, Formik } from "formik";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";

import { POS_SCREEN_PRODUCT } from "../../../pages/Product/queries";
import {
  posScreenQuickModifierFormValidationSchema,
  posScreenQuickProductFormValidationSchema,
} from "../../../utils/formValidation";
import randomString from "../../../utils/randomString";
import { useNavigate } from "react-router";
import ColorPicker from "../../reusable/ColorPicker";
import PosScreenProWithModCatTrue from "../PosScreenProWthModCatTrue";
import { setLocalKey } from "../../../helpers/sessionKey";
import { QUERY_SETTINGS } from "../../../pages/Settings/graphql";
import WeightableProductQuantityForm from "./WeightableProductQuantityForm";
import OpenPriceProductPriceForm from "./OpenPriceProductPriceForm";
import ProductCard from "./ProductCard";
import {
  POS_PRODUCT_SOLD_OUT,
  POS_SCREEN_CREATE_PRODUCT,
  POS_SCREEN_CREATE_MODIFIER,
} from "../../../pages/Product/mutation";
import PosScreenProductForm from "./PosScreenProductForm";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import PosScreenModifierForm from "./PosScreenModifierForm";
import {
  UPDATE_POS_SCREEN_PRODUCT,
  REMOVE_ASSIGNED_PRODUCT,
} from "../../../pages/PosScreen/mutation";
import {
  CURRENT_TERMINAL,
  usePosScreenProductByCategory,
  usePosScreenProductByCategoryWithIds,
} from "../../../pages/PosScreen/queries";
import _ from "lodash";

export default function PosScreenProductTab(props: any) {
  const {
    productsData,
    productByCatId,
    productByCatPk,
    categoryDropdown,
    unitDropdown,
    taxTypeDropdown,
    printerListsDropdown,
    noOfColumns,
    modifierCategoryDropdown,
    setOpenContextPopup,
    openContextPopup,
    handleProductRefetch,
    setPosColorId,
    posColorId,
  } = props;
  const { data: settingsData } = useQuery(QUERY_SETTINGS);
  const [productWithModCategoryList, setProductWithModCategoryList] = useState(
    []
  );
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL);
  const enableWightScale =
    currentTerminalData?.currentTerminal?.terminalsetting?.isActiveScale;
  const cartItems: [] = useReactiveVar(cartItemsVar);
  const navigate = useNavigate();
  const [coords, setCoords] = useState<any>({ x: 0, y: 0 });
  const [openProductWithModCategory, setOpenProductWithModCategory] =
    useState<boolean>(false);
  const [openProductWithModCategoryTrue, setOpenProductWithModCategoryTrue] =
    useState<boolean>(false);
  const [isWeightableProduct, setIsWeightableProduct] =
    useState<boolean>(false);
  const [isScaleNotConnected, setIsScaleNotConnected] =
    useState<boolean>(false);
  const [scaleErrorMessage, setScaleErrorMessage] = useState<string>();
  const [item, setItem] = useState<any>();
  const [scaleValues, setScaleValues] = useState();
  const scaleInitialValues = {
    baudRate:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleBaudRate,
    dataBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleDateBits,
    parity: currentTerminalData?.currentTerminal?.terminalsetting?.scaleParity,
    port: currentTerminalData?.currentTerminal?.terminalsetting?.scalePort,
    stopBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleStopBits,
  };
  const [isOpenPriceProducts, setIsOpenPriceProducts] =
    useState<boolean>(false);
  const handlePromoItem = (updateNewItemList: any, newItem: any) => {
    let finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
    const promoInfo = newItem?.promotionInfo;
    const categoryInfo = newItem?.categoryPromotionInfo;
    let disableCart = false;
    let match: boolean = false;
    let promotionList: any = [];
    let promotion: any = [];
    let applicablePromotion: any = [];
    if (promoInfo.length) {
      for (
        let promoInfoIndex = 0;
        promoInfoIndex < promoInfo.length;
        promoInfoIndex++
      ) {
        const promotionItem = promoInfo[promoInfoIndex];
        if (promotionItem.compound.length) {
          const everyItemExists = promotionItem.compound.every((item: any) =>
            finalCartItems.some((elem: any) => elem.id === item.id)
          );
          if (everyItemExists) {
            promotionList.push(promotionItem);
            applicablePromotion.push(promotionItem?.compound);
          }
        }
      }
    }
    if (applicablePromotion.length > 0) {
      for (let index = 0; index < applicablePromotion.length; index++) {
        const element = applicablePromotion[index];
        for (let itemIndex = 0; itemIndex < element.length; itemIndex++) {
          let item = element[itemIndex];
          let foundItemIndex = finalCartItems.findIndex(
            (cartItem: any) => cartItem.id == item.id && cartItem.quantity > 0
          );
          if (foundItemIndex >= 0) {
            finalCartItems[foundItemIndex]["quantity"] -= 1;
            finalCartItems[foundItemIndex]["total"] =
              finalCartItems[foundItemIndex]["quantity"] *
              parseFloat(finalCartItems[foundItemIndex]["price"]);
            match = true;
          } else {
            match = false;
          }
        }
        if (match) {
          promotion = { ...promotionList[index] };
          break;
        } else {
          finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
        }
      }
    }
    if (match) {
      let productInCartList: any = {
        id: promotion?.id,
        pk: promotion?.pk,
        title: promotion?.title,
        quantity: 1,
        price: promotion?.sellingPrice,
        total: promotion?.sellingPrice,
        tax: promotion?.taxAmount ?? "0.00",
        taxRate: {
          rate: promotion?.tax?.rate ?? 0,
          isIncluded: promotion?.tax?.isIncluded,
        },
        isSelected: false,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierCategory: promotion?.modifierCategory,
        compound: promotion?.compound,
      };
      let finalItems = finalCartItems?.filter((item: any) => item.quantity > 0);
      toast.success("Promotion Found");
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([productInCartList, ...finalItems]),
        },
      });
    } else {
      if (categoryInfo?.length) {
        categoryInfo.forEach((promotion: any) => {
          let promotionFound = false;
          promotion.possibleCombinations.forEach(
            (promotionCombination: any) => {
              let combinationMatch = true;
              promotionCombination.forEach((combinationItem: any) => {
                const isExistIndex = finalCartItems.findIndex(
                  (filterCartItem: any) =>
                    filterCartItem.pk === combinationItem?.pk &&
                    filterCartItem.quantity > 0
                );
                if (isExistIndex > -1) {
                  finalCartItems[isExistIndex]["quantity"] -= 1;
                  finalCartItems[isExistIndex]["total"] =
                    finalCartItems[isExistIndex]["quantity"] *
                    parseFloat(finalCartItems[isExistIndex]["price"]);
                } else {
                  combinationMatch = false;
                }
              });
              if (combinationMatch) {
                if (!promotionFound) {
                  toast.success("Promotion found");
                  promotionFound = true;
                }
                disableCart = true;
                let productInCartList: any = {
                  id: promotion?.id,
                  pk: promotion?.pk,
                  title: promotion?.title,
                  quantity: 1,
                  price: promotion?.sellingPrice,
                  total: promotion?.sellingPrice,
                  tax: promotion?.taxAmount ?? "0.00",
                  taxRate: {
                    rate: promotion?.tax?.rate ?? 0,
                    isIncluded: promotion?.tax?.isIncluded,
                  },
                  isSelected: false,
                  isDeleted: false,
                  modifier: [],
                  isModified: true,
                  modifierCategory: promotion?.modifierCategory,
                  compound: promotionCombination,
                };
                let finalItems = finalCartItems?.filter(
                  (item: any) => item.quantity > 0
                );
                cache.writeQuery({
                  query: GET_CART_ITEMS,
                  data: {
                    cartItems: cartItemsVar([productInCartList, ...finalItems]),
                  },
                });
                return;
              } else {
                // Reset finalCartItems if the combination doesn't match
                finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
              }
            }
          );
          if (promotionFound) {
            return; // Exit the outer loop if a promotion is found
          }
        });
      } else {
      }
      // finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
      if (!disableCart) {
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([...updateNewItemList]),
          },
        });
      }
    }
  };
  const scaleValue = JSON.stringify(scaleInitialValues);
  const handleClick = (item: any) => {
    if (openContextPopup) {
      return;
    }
    setLocalKey("disableRemotePrint", "false");
    if (item?.isWeightable) {
      if (item?.isOpenPriceProduct) {
        setIsOpenPriceProducts(true);
        setItem(item);
      }
      if (!enableWightScale) {
        setItem(item);
        setIsWeightableProduct(true);
      } else {
        setItem(item);

        if (item?.isOpenPriceProduct) {
          setIsOpenPriceProducts(true);
          setItem(item);
        }
      }
      setLocalKey("disableRemotePrint", "false");
    } else if (item?.isOpenPriceProduct) {
      setIsOpenPriceProducts(true);
      setItem(item);
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        title: item?.title,
        quantity: 1,
        price: item?.finalPrice ?? 0,
        total: item?.finalPrice ?? 0,
        tax: item?.taxAmount,
        taxRate: {
          rate: item?.tax?.rate ?? 0,
          isIncluded: item?.tax?.isIncluded,
        },
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        promoInfo: item?.promotionInfo ?? [],
        categoryPromo: item?.categoryPromotionInfo ?? [],
        discount: "",
        modifierCategory: item?.modifierCategories ?? null,
        productId: productSingle,
      };
      let previousObject: any = cartItems.find(
        (x: any) => x.id === item?.id && x.isSelected === true
      );

      if (previousObject && previousObject.isSelected) {
        let previousItem = { ...previousObject };
        let newItemList: any = cartItems;
        let foundIndex: any = cartItems.findIndex(
          (x: any) => x.id === item?.id && x.isSelected === true
        );
        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseInt(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].tax =
          parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);
        newItemList[foundIndex].total =
          parseFloat(previousItem.total) / parseFloat(previousItem.quantity) +
          parseFloat(previousItem.total);
        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                  ...item,
                  isSelected: false,
                }
              : item
        );
        handlePromoItem(updateNewItemList, item);
        setLocalKey("disableRemotePrint", "false");
      } else {
        let nonPreviousItemList = cartItems?.map(
          (item: any, index: number) => ({
            ...item,
            isSelected: false,
          })
        );
        handlePromoItem([productInCartList, ...nonPreviousItemList], item);
        setLocalKey("disableRemotePrint", "false");
      }
    }
  };
  const handleModifierClick = (item: any) => {
    if (!item) {
      toast.info("Please select non-empty modifiers");
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === item?.id
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === item?.id
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) + parseFloat(previousObject.total)
              : 0;
          perviousModifierItemList[itemIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;
          perviousModifierItemList[itemIndex].isUpdated = true;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          setLocalKey("disableRemotePrint", "false");
        } else {
          let productInCartList: any = cartItems;
          prevModifierList.push({
            id: item?.id,
            pk: item?.pk,
            title: item?.title,
            price: item?.finalPrice ?? 0,
            quantity: 1,
            total: item?.finalPrice ?? 0,
            tax: item?.taxAmount,
            taxRate: {
              rate: item?.tax?.rate ?? 0,
              isIncluded: item?.tax?.isIncluded,
            },
            isDeleted: false,
            randId: Math.random().toString(36).substr(2, 5),
          });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          setLocalKey("disableRemotePrint", "false");
        }
      } else {
        if (item) {
          toast.info("Please select product");
        } else {
          toast.info("Please select non-empty modifiers");
        }
      }
    }
  };

  const handleProductWithModCategory = (item: any) => {
    setOpenProductWithModCategory(true);
    setProductWithModCategoryList(item);
  };

  const handleProductWithModCategoryTrue = (item: any) => {
    setOpenProductWithModCategoryTrue(true);
    setProductWithModCategoryList(item);
  };

  //Change Context Category Name
  const [changePopup, setChangePopup] = useState<boolean>(false);
  const [changeModifierPopup, setChangeModifierPopup] =
    useState<boolean>(false);
  const [productSingle, setProductSingle] = useState();
  const [addProductPopup, setAddProductPopup] = useState<boolean>(false);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const [openMessagePopup, setOpenMessagePopup] = useState<boolean>(false);

  const [addModifierPopup, setAddModifierPopup] = useState<boolean>(false);
  const [stockAlertPopup, setStockAlertPopup] = useState<boolean>(false);

  const [input, setInput] = useState<string>("");
  const [productId, setProductId] = useState<string>("");

  //Handle Change Product
  const [updatePosScreenProducts] = useMutation(UPDATE_POS_SCREEN_PRODUCT, {
    refetchQueries: [
      {
        query: usePosScreenProductByCategory,
        variables: {
          offset: 0,
          posScreenCategoryId: productByCatPk,
        },
      },
    ],
  });
  const handleProductChange = async (proPk: string) => {
    const response = await updatePosScreenProducts({
      variables: {
        id: productId,
        productId: proPk,
        categoryId: productByCatPk,
      },
    });
    if (response?.data?.assignProduct?.success) {
      setChangePopup(false);
      setChangeModifierPopup(false);
      await handleProductRefetch();
      toast.success(response?.data?.assignProduct?.message);
      setInput("");
    }
  };

  const productInitialValues = {
    title: "",
    category: [],
    unit: unitDropdown[0]?.id,
    isActive: true,
    sellingPrice: "",
    costPrice: 0,
    taxType: taxTypeDropdown[0]?.id,
    subCategory: "",
    barcode: "",
    plu: "",
    printer: [],
    isWeightable: false,
    stock: 0,
    threshold: 0,
    modifierCategories: [],
    isModifierCategoryGrid: false,
  };
  const modifierInitialValues = {
    title: "",
    category: [],
    unit: unitDropdown[0]?.id,
    price: "",
    taxType: taxTypeDropdown[0]?.id,
    barcode: "",
    plu: "",
    isActive: true,
  };

  //Handle Quick Add Product

  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategoryId: productByCatPk,
          },
        },
      ],
    }
  );
  const [modifierCreate, { loading: loadingCreateModifier }] = useMutation(
    POS_SCREEN_CREATE_MODIFIER,
    {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategoryId: productByCatPk,
          },
        },
      ],
    }
  );
  const handleCreateProduct = async (values: any, props: any) => {
    setOpenContextPopup(false);
    setAddProductPopup(false);
    const response = await productCreate({
      variables: {
        posScreenProductId: productId.toString(),
        posScreenCategroryId: productByCatPk,
        category: values?.category,
        title: values?.title,
        promotionCategory: values?.subCategory,
        unit: values?.unit,
        tax: values?.taxType === "0" ? null : values?.taxType,
        stock: parseFloat(values?.stock) ?? 0,
        threshold: parseFloat(values?.threshold) ?? 0,
        isWeightable: values?.isWeightable,
        printOrder: null,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isScalable: values?.isScalable,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategories ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      setAddProductPopup(false);
    } else {
      const errors = backendErrorDisplay(response?.data?.createProduct?.errors);
      props.setErrors(errors);
    }
  };
  const handleCreateModifier = async (values: any, props: any) => {
    setOpenContextPopup(false);
    setAddModifierPopup(false);
    const response = await modifierCreate({
      variables: {
        posScreenProductId: productId.toString(),
        posScreenCategroryId: productByCatPk,
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        tax: values?.taxType === "0" ? null : values?.taxType,
        image: null,
        sellingPrice: values?.price,
        costPrice: "",
        shortDesc: "",
      },
    });
    if (response?.data?.createModifier?.success) {
      toast.success("Modifier Created successfully");
    } else {
      const errors = backendErrorDisplay(
        response?.data?.createModifier?.errors
      );
      props.setErrors(errors);
    }
  };

  const [toggleSoldOutProduct, { loading: toggleSoldoutProductLoading }] =
    useMutation(POS_PRODUCT_SOLD_OUT, {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategoryId: productByCatPk,
          },
        },
      ],
    });
  const handleQuickAddProduct = (e: any) => {
    e.stopPropagation();
    setAddProductPopup(true);
    setOpenContextPopup(false);
  };
  const handleQuickAddModifier = (e: any) => {
    e.stopPropagation();
    setAddModifierPopup(true);
    setOpenContextPopup(false);
  };

  const handleChangeButton = (e: any) => {
    e.stopPropagation();
    setChangePopup(true);
    setOpenContextPopup(false);
  };
  const handleChangeModifierButton = (e: any) => {
    e.stopPropagation();
    setChangeModifierPopup(true);
    setOpenContextPopup(false);
  };

  //Handle Remove Assign Product
  const [removeAssignedProduct] = useMutation(REMOVE_ASSIGNED_PRODUCT, {
    refetchQueries: [
      {
        query: usePosScreenProductByCategory,
        variables: {
          offset: 0,
          posScreenCategoryId: productByCatPk,
        },
      },
    ],
  });
  const handleRemoveAssignProduct = async (e: any) => {
    e.stopPropagation();
    const response = await removeAssignedProduct({
      variables: {
        id: productId,
        categoryId: productByCatPk,
      },
    });
    if (response?.data?.removeAssignedProduct?.success) {
      toast.success("Product Removed successfully");
      await handleProductRefetch();
    }
    setOpenContextPopup(false);
  };
  let deletedItems: any = cartItems.filter(
    (item: any) => item?.isDeleted === false
  );
  const handleEditProduct = async (e: any, product: any) => {
    e.stopPropagation();
    if (tableItems?.id || orderType?.pk || deletedItems?.length > 0) {
      setOpenMessagePopup(true);
    } else {
      if (!product) {
        toast.info("Product/modifier is not assigned");
        setOpenContextPopup(false);
        return;
      }
      if (product.productType !== "MODIFIER") {
        navigate(`/product/edit/${product.id}`);
      } else {
        navigate(`/modifier/edit/${product.id}`);
      }
    }
    setOpenContextPopup(false);
  };

  const handleClickContext = (e: any, item: any) => {
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      setProductId(item?.node?.pk);
      setOpenContextPopup(true);
    }
  };
  //Product Lists
  const { data: productsLists } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      first: 10,
      search: input,
      isOpenProduct: false,
      productType: "product",
    },
  });
  const handleSoldOut = async (e: any, item: any) => {
    e.stopPropagation();
    const response = await toggleSoldOutProduct({
      variables: {
        objectId: item?.node?.product?.product?.pk,
      },
    });
    if (response?.data?.toggleProduct?.success) {
      toast.success(response?.data?.toggleProduct?.message);
      setOpenContextPopup(false);
    } else {
      toast.error(response?.data?.toggleProduct?.message);
    }
  };

  //Modifier Lists
  const { data: modifIerLists } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      first: 10,
      search: input,
      title_Icontains: input,
      productType: "modifier",
    },
  });
  const handleCloseChange = () => {
    setInput("");
  };

  const [getSingleProduct] = useLazyQuery(usePosScreenProductByCategoryWithIds);
  const handleProductClick = async (item: any) => {
    setLocalKey("productId", item?.node?.id);
    const response = await getSingleProduct({
      variables: {
        id: item?.node?.id,
        posScreenCategoryId: productByCatPk,
      },
    });
    if (!response?.data?.posScreenProduct?.product?.product) {
      return;
    }
    if (!response?.data?.posScreenProduct?.product?.product?.isActive) {
      toast.error("Product is Sold Out");
      return;
    }
    const product = response?.data?.posScreenProduct?.product?.product;
    const stock = product?.stock;
    const isStockMaintain =
      settingsData?.basicWebsiteConfigurations?.isStockMaintain;

    let action;

    if (
      stock?.threshold <= parseFloat(stock?.availableQuantity) &&
      isStockMaintain
    ) {
      action = "belowThreshold";
    } else if (
      stock?.threshold > parseFloat(stock?.availableQuantity) &&
      isStockMaintain
    ) {
      action = "aboveThreshold";
    } else {
      action = "default";
    }
    switch (action) {
      case "belowThreshold":
        if (product?.productType !== "MODIFIER") {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      case "aboveThreshold":
        setStockAlertPopup(true);
        if (product?.productType !== "MODIFIER") {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      case "default":
        if (product?.productType !== "MODIFIER") {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      default:
        break;
    }
  };
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [enteredNumber, setEnteredNumber] = useState<any>(1);

  const handleQuickAddColor = (e: any) => {
    e.stopPropagation();
    setDisplayColorPicker(true);
    setOpenContextPopup(false);
  };
  const handleWindowMouseMove = (event: any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY > 400 ? event.clientY - 280 : event.clientY,
    });
  };
  useEffect(() => {
    window.addEventListener("contextmenu", handleWindowMouseMove);
    return () => {
      window.removeEventListener("contextmenu", handleWindowMouseMove);
    };
  }, []);
  return (
    <>
      {openContextPopup ? (
        <div
          className="w-full h-full absolute z-10"
          onClick={() => setOpenContextPopup(false)}
        ></div>
      ) : null}
      {productsData?.posScreenProducts ? (
        <div
          className={`grid h-fit px-4 grid-cols-${noOfColumns} grid-rows-7 gap-2`}
        >
          {productsData?.posScreenProducts?.edges?.map(
            (item: any, index: number) => (
              <React.Fragment key={randomString(20)}>
                <div
                  className={` relative h-[80px] cursor-pointer rounded-lg border-2 border-solid  border-white `}
                  style={{
                    background: item?.node?.product?.product?.title
                      ? item?.node?.product?.color ??
                        `${
                          item?.node?.product?.product?.productType !==
                          "MODIFIER"
                            ? "#4A90E2"
                            : "#8B6BA6"
                        }`
                      : "#F3F4F6",
                  }}
                  onClick={() => {
                    handleProductClick(item);
                    setProductSingle(item?.node?.product?.id);
                  }}
                  onContextMenu={(event: any) => (
                    handleClickContext(event, item),
                    setPosColorId(item?.node?.product?.pk)
                  )}
                >
                  <ProductCard item={item} />
                  {item?.node?.pk === productId && openContextPopup && (
                    <div
                      className={
                        "w-full fixed p-1 cursor-pointer  bg-[#FFFFFF]  rounded-lg z-10"
                      }
                      style={{ width: "150px", top: `${coords?.y}px` }}
                    >
                      {!item?.node?.product?.product && (
                        <div
                          onClick={(e) => handleChangeButton(e)}
                          className="hover:bg-[#4338CA]/50 pl-1  my-4"
                        >
                          Change Product
                        </div>
                      )}
                      <div
                        onClick={(e) =>
                          handleEditProduct(e, item?.node?.product?.product)
                        }
                        className="hover:bg-[#4338CA]/50 pl-1  my-4"
                      >
                        Edit
                      </div>
                      {!item?.node?.product?.product && (
                        <div>
                          <div
                            onClick={(e) => handleChangeModifierButton(e)}
                            className="hover:bg-[#4338CA]/50 pl-1 my-4"
                          >
                            Change Modifier
                          </div>
                          <div
                            onClick={(e) => handleQuickAddProduct(e)}
                            className="hover:bg-[#4338CA]/50 pl-1 my-4"
                          >
                            Add Product
                          </div>
                          <div
                            onClick={(e) => handleQuickAddModifier(e)}
                            className="hover:bg-[#4338CA]/50 pl-1 my-4"
                          >
                            Add Modifier
                          </div>
                        </div>
                      )}
                      <div
                        onClick={(e) => handleRemoveAssignProduct(e)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4"
                      >
                        Remove
                      </div>
                      <div
                        onClick={(e) => handleQuickAddColor(e)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4 "
                      >
                        Add Color
                      </div>
                      <div
                        onClick={(e) => handleSoldOut(e, item)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4 "
                      >
                        Toggle Sold Out
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
          )}
          <Modal
            title={"Select Modifier"}
            open={openProductWithModCategory}
            setOpen={setOpenProductWithModCategory}
            size="max-w-7xl"
            children={
              <PosScreenProWithModCat
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategory}
                open={openProductWithModCategory}
                productSingle={productSingle}
              />
            }
          />

          <Modal
            title={"Select Modifier"}
            open={openProductWithModCategoryTrue}
            setOpen={setOpenProductWithModCategoryTrue}
            size="max-w-7xl"
            children={
              <PosScreenProWithModCatTrue
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategoryTrue}
                open={openProductWithModCategoryTrue}
              />
            }
          />
        </div>
      ) : (
        <div
          className={`grid h-full px-4 grid-cols-${noOfColumns} grid-rows-7 gap-2 border-2`}
        >
          {productsData?.products?.edges?.map((item: any, index: number) => (
            <React.Fragment key={randomString(20)}>
              <div
                className={
                  "relative cursor-pointer border-2 border-solid border-gray-300 rounded-lg "
                }
                style={{ background: item?.node?.color ?? "#F3F4F6" }}
                onClick={() => handleProductClick(item)}
                onMouseLeave={() => setOpenContextPopup(false)}
                onContextMenu={(event: any) => handleClickContext(event, item)}
              >
                {item?.node?.product?.product?.media[0]?.media ? (
                  <>
                    <img
                      src={item?.node?.product?.product?.media[0]?.media}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      className="object-cover"
                    />
                    <span
                      title={item?.node?.product?.product?.title}
                      className={`${
                        item?.node?.product?.product?.productType !== "MODIFIER"
                          ? ""
                          : "text-red-500"
                      } textWithTwoLine absolute top-[20%] w-full bg-gray-200  text-left font-bold px-1`}
                    >
                      {item?.node?.product?.product?.title}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      title={item?.node?.product?.product?.title}
                      className={`${
                        item?.node?.product?.product?.productType !== "MODIFIER"
                          ? ""
                          : "text-red-500"
                      } textWithTwoLine absolute top-[30%] w-full bg-gray-200  text-left p-2`}
                    >
                      {item?.node?.product?.product?.title}
                    </span>
                  </>
                )}
                {item?.node?.pk === productId && openContextPopup && (
                  <div
                    className={
                      "w-full absolute cursor-pointer right-0 top-[80%] bg-[#FFFFFF] p-1 rounded-md z-10"
                    }
                  >
                    {!item?.node?.product?.product && (
                      <div
                        onClick={(e) => handleChangeButton(e)}
                        className="hover:bg-[#4338CA]/50 pl-1  my-4"
                      >
                        Change Product
                      </div>
                    )}
                    <div
                      onClick={(e) =>
                        handleEditProduct(e, item?.node?.product?.product)
                      }
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Edit
                    </div>
                    {!item?.node?.product?.product && (
                      <div>
                        <div
                          onClick={(e) => handleChangeModifierButton(e)}
                          className="hover:bg-[#4338CA]/50 pl-1 my-4"
                        >
                          Change Modifier
                        </div>
                        <div
                          onClick={(e) => handleQuickAddProduct(e)}
                          className="hover:bg-[#4338CA]/50 pl-1 my-4"
                        >
                          Add Product
                        </div>
                        <div
                          onClick={(e) => handleQuickAddModifier(e)}
                          className="hover:bg-[#4338CA]/50 pl-1 my-4"
                        >
                          Add Modifier
                        </div>
                      </div>
                    )}
                    <div
                      onClick={(e) => handleRemoveAssignProduct(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Remove
                    </div>
                    <div
                      onClick={(e) => handleQuickAddColor(e)}
                      className="hover:bg-[#4338CA]/50 pl-1"
                    >
                      Add Color
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
          <Modal
            title={"Add Product"}
            open={openProductWithModCategory}
            setOpen={setOpenProductWithModCategory}
            children={
              <PosScreenProWithModCat
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategory}
                open={openProductWithModCategory}
                productSingle={productSingle}
              />
            }
          />
        </div>
      )}
      <Modal
        title={"Change Item"}
        open={changePopup}
        setOpen={setChangePopup}
        onCloseModal={() => handleCloseChange()}
      >
        <ChangeModalForm
          input={input}
          setInput={setInput}
          name={"searchProduct"}
          children={
            <ul className="block w-full text-left p-1 ml-1 h-[100px] overflow-y-scroll shadow-lg">
              {productsLists?.products?.edges?.map(
                (item: any, index: number) => (
                  <li
                    key={randomString(20)}
                    className="hover:bg-[#4338CA] hover:text-white cursor-pointer"
                    onClick={() => handleProductChange(item?.node?.pk)}
                  >
                    {item?.node?.title}
                  </li>
                )
              )}
            </ul>
          }
        />
      </Modal>
      <Modal
        title={"Change Item"}
        open={changeModifierPopup}
        setOpen={setChangeModifierPopup}
        onCloseModal={() => handleCloseChange()}
      >
        <ChangeModalForm
          input={input}
          setInput={setInput}
          name={"searchProduct"}
          children={
            <ul className="block w-full text-left p-1 ml-1 h-[100px] overflow-y-scroll shadow-lg">
              {modifIerLists?.products?.edges?.map(
                (item: any, index: number) => (
                  <li
                    key={randomString(20)}
                    className="hover:bg-[#4338CA] hover:text-white cursor-pointer"
                    onClick={() => handleProductChange(item?.node?.pk)}
                  >
                    {item?.node?.title}
                  </li>
                )
              )}
            </ul>
          }
        />
      </Modal>
      <Modal
        title={"Add Product"}
        open={addProductPopup}
        setOpen={setAddProductPopup}
      >
        <Formik
          initialValues={productInitialValues}
          validationSchema={posScreenQuickProductFormValidationSchema}
          onSubmit={handleCreateProduct}
        >
          {({ touched, values, setFieldValue, errors, handleChange }) => (
            <Form>
              <PosScreenProductForm
                values={values}
                touched={touched}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                errors={errors}
                unitData={unitDropdown ?? []}
                taxTypeDropdown={taxTypeDropdown ?? []}
                printerListsDropdown={printerListsDropdown ?? []}
                modifierCategoryDropdown={modifierCategoryDropdown ?? []}
              />

              <div className="text-right">
                <PrimaryButton
                  text={"Submit"}
                  loading={loadingCreateProduct ? true : false}
                  disable={loadingCreateProduct}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        title={"Add Modifier"}
        open={addModifierPopup}
        setOpen={setAddModifierPopup}
      >
        <Formik
          initialValues={modifierInitialValues}
          validationSchema={posScreenQuickModifierFormValidationSchema}
          onSubmit={handleCreateModifier}
        >
          {({ touched, values, setFieldValue, errors, handleChange }) => (
            <Form>
              <PosScreenModifierForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                categoryDropdown={categoryDropdown ?? []}
                unitData={unitDropdown ?? []}
                taxTypeDropdown={taxTypeDropdown ?? []}
              />

              <div className="text-right">
                <PrimaryButton
                  text={"Submit"}
                  loading={loadingCreateProduct}
                  disable={loadingCreateProduct}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        title={"Stock Alert"}
        open={stockAlertPopup}
        setOpen={setStockAlertPopup}
        size="max-w-sm"
        children={
          <>
            <p>Quantity is less than threshold value &#9785;</p>
          </>
        }
      />
      <Modal
        title={"Add Quantity"}
        open={isWeightableProduct}
        setOpen={setIsWeightableProduct}
        size="max-w-sm"
      >
        <WeightableProductQuantityForm
          item={item}
          cartItems={cartItems}
          setIsWeightableProduct={setIsWeightableProduct}
          setScaleValue={setScaleValues}
          isOpenPriceProducts={isOpenPriceProducts}
          setEnteredNumber={setEnteredNumber}
          enteredNumber={enteredNumber}
        />
      </Modal>
      <Modal
        open={isScaleNotConnected}
        setOpen={() => {
          setIsScaleNotConnected(false);
        }}
        children={<>{scaleErrorMessage}</>}
        title={JSON.stringify(scaleErrorMessage)}
      />
      <Modal
        open={openMessagePopup}
        setOpen={setOpenMessagePopup}
        children={<>Please Clear PosScreen to Edit</>}
        title={"Items Present in Screen"}
      />
      <Modal
        title={"Add Price"}
        open={isOpenPriceProducts}
        setOpen={setIsOpenPriceProducts}
        size="max-w-sm"
      >
        <OpenPriceProductPriceForm
          item={item}
          cartItems={cartItems}
          setIsOpenPriceProducts={setIsOpenPriceProducts}
          scaleValues={scaleValues}
          enteredQauntity={enteredNumber}
        />
      </Modal>

      <ColorPicker
        type="product"
        displayColorPicker={displayColorPicker}
        id={posColorId}
        productByCatId={productByCatPk}
        setDisplayColorPicker={setDisplayColorPicker}
      />
    </>
  );
}
