import CartIcon from "../../../../../assets/cart.svg";
import { useEffect, useState } from "react";
import AutoComplete from "../../../../reusable/AutoComplete";
import {
  customerCache,
  CustomerItemsVar,
  permissionTypeCache,
  permissionTypeVar,
} from "../../../../localstate/Cache";
import {
  GET_CUSTOMER_ITEMS,
  GET_PERMISSION_TYPE,
} from "../../../../localstate/Cart";
import AccessControl from "../../../../../helpers/accessControl";
import Modal from "../../../../reusable/Modal";
import Login from "../PermissionVerify/Login";

type Props = {
  cartItems?: any;
  customerItems?: any;
  permissionTypes: any;
};

export default function PosScreenCustomerSection(props: Props) {
  const { cartItems, customerItems, permissionTypes } = props;

  const [selected, setSelected] = useState({
    id: customerItems?.id ?? "",
    pk: customerItems?.pk ?? "",
    name: customerItems?.name ?? "",
  });

  // Used for customer cache
  useEffect(() => {
    if (selected?.name) {
      let customerItem = {
        id: selected?.id,
        name: selected?.name,
        pk: selected?.pk,
      };
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          selected: CustomerItemsVar(customerItem),
        },
      });
    }
    return () => {
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar({ id: "", name: "", pk: "" }),
        },
      });
    };
  }, [selected]);

  const result = cartItems.filter((item: any) => item.isDeleted === true);

  const [permPopup, setPermPopup] = useState(false);
  const [permissionType, setPermissionType] = useState("");

  //Cah Drawer Functionality

  return (
    <div className="flex justify-between place-items-center p-0 text-white my-2">
      <div className="mr-2">
        <AutoComplete
          selected={{
            id: customerItems?.id ?? "",
            pk: customerItems.pk ?? "",
            name: customerItems.name ?? "",
          }}
          setSelected={setSelected}
          placeHolder="Select Customer"
          customerItems={customerItems}
        />
      </div>
      <div className="flex justify-between items-center gap-2">
        <div className="bg-[#4338CA] rounded-full p-2">
          {permissionTypes === "can_use_no_sale" ? (
            <button className="h-4 w-4 cursor-pointer">NS</button>
          ) : (
            <AccessControl
              allowedPermissions={["can_use_no_sale"]}
              renderNoAccess={
                <button
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setPermPopup(true);
                    setPermissionType("can_use_no_sale");
                  }}
                >
                  NS
                </button>
              }
              children={<button className="h-4 w-4 cursor-pointer">NS</button>}
            />
          )}
        </div>
        <div className="flex justify-between items-center bg-[#4338CA] rounded-full p-2">
          <img alt="" src={CartIcon} className="h-4 w-4" aria-hidden="true" />
          <div
            style={{
              border: "1px solid white",
              transform: "rotate(90deg)",
              width: "20px",
            }}
          ></div>
          <span className="">
            {cartItems ? cartItems?.length - result?.length : 0}
          </span>
        </div>
      </div>

      {/* Permission Grant Madal*/}
      <Modal title={"Alert"} open={permPopup} setOpen={setPermPopup}>
        <p className="py-4"> You don't have permission for the change </p>
        <Login permission={permissionType} setPermPopup={setPermPopup} />
      </Modal>
    </div>
  );
}
