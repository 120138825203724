import { useReactiveVar } from "@apollo/client";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";
import { tableItemsVar } from "../../localstate/Cache";
import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";

type Props = {
  tableId: string;
  tableData: any;
  isTableLoading: boolean;
  handleSelectTable: any;
};

const PosScreenSplitSubTableList = (props: Props) => {
  const tableItems: any = useReactiveVar(tableItemsVar);
  const { tableId, tableData, isTableLoading, handleSelectTable } = props;
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();

  const filterSubTables: any = tableData?.floor?.tablesByFloor?.edges?.filter(
    (item: any) => (item?.node?.pk).toString() === tableId
  );

  const handleSubTableSelected = async (item: any) => {
    const valueResponse: any = await handleSelectTableToggle(
      true,
      item?.mergeInstance ? item?.mergeInstance : item?.pk
    );
    if (valueResponse) {
      handleSelectTable(item);
    }
  };
  return (
    <>
      {isTableLoading ? (
        <DataLoadSpinner />
      ) : (
        <div className="grid grid-rows-7 grid-cols-7 gap-2 p-2">
          {filterSubTables?.map((item: any) =>
            item?.node?.subTables?.edges?.map((item: any) => (
              <div
                key={item?.node?.id}
                title={
                  item?.node?.status === "OCCUPIED" ? "Occupied" : "Vacant"
                }
                className={`flex text-center min-h-full max-h-[60px] cursor-pointer rounded-lg items-center 
                            ${
                              item.node.status === "OCCUPIED"
                                ? "bg-[#4338CA] text-white"
                                : item.node.pk === tableItems.pk
                                ? "text-red-500 bg-[#efefef]"
                                : "bg-[#efefef] text-gray-800"
                            }`}
              >
                <div
                  className="flex py-3 w-full justify-center"
                  onClick={() => handleSubTableSelected(item?.node)}
                >
                  <p className="">
                    {item?.node?.title}
                    <br />
                    0/{item?.node?.capacity}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default PosScreenSplitSubTableList;
