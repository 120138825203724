import React from 'react'
import ConfirmAlert from '../reusable/ConfirmAlert'
import { POS_SCREEN_CREATE_KITCHEN_ORDER, POS_SCREEN_HOLD_KITCHEN_ORDER_LIST } from '../../pages/PosScreen/mutation'
import { useMutation, useReactiveVar } from '@apollo/client'
import { cartItemsVar, CustomerItemsVar, noteVar, orderPrintItemsVar, orderPrintItemsVarCache, orderTypeVar, tableItemsVar } from '../localstate/Cache'
import { toast } from 'react-toastify'
import { removeLocalKey } from '../../helpers/sessionKey'
import useHandleToggleSelectedTable from '../../customhooks/useHandleToggleSelectedTable'
import usePosScreenHandleClear from '../../customhooks/usePosScreenHandleClear'
import { GET_ORDER_PRINT_ITEMS } from '../localstate/Cart'

type Props = {
  setOpenHoldPopup: any
  openHoldPopup: any

}

const PosScreenHoldOrderFunction = (props: Props) => {
  const { setOpenHoldPopup, openHoldPopup } = props
  const [addToHoldCardItem] = useMutation(POS_SCREEN_HOLD_KITCHEN_ORDER_LIST, {
    fetchPolicy: "no-cache",
  });
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const noteItems: any = useReactiveVar(noteVar);

  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const { handleClearPosScreen } = usePosScreenHandleClear();
  const [createKitchenOrder, { loading: loadingKitchenOrder }] = useMutation(
    POS_SCREEN_CREATE_KITCHEN_ORDER,
    {
      refetchQueries: ["kitchenOrder"],
    },
  );
  const handleHoldCard = async () => {
    if (tableItems?.currentKitchenOrder?.pk) {
      const response = await addToHoldCardItem({
        variables: {
          id: tableItems?.currentKitchenOrder?.pk,
          orderStatus: "hold",
        },
      });
      if (response) {
        handleClearPosScreen();
        toast.success("Successfully Hold");
      }
      if (tableItems?.pk) {
        handleSelectTableToggle(false, tableItems?.mergedId ? tableItems?.mergedId : tableItems?.pk);
        removeLocalKey("tableItemSelected");
      }
    } else {
      const orderList = cartItems;
      let orderItems: any = [];
      if (cartItems) {
        let orderInfo = {
          customer: customerItems?.pk ? customerItems.pk : "",
          table: tableItems?.pk ? tableItems.pk : "",
          note: noteItems ? noteItems : "",
          orderStatus: "hold",
          orderMethod: orderType?.pk ?? "",
        };
        orderList.map((item: any) => {
          let modifierItems: any = [];
          {
            item?.modifier?.map((item: any) => {
              modifierItems.push({
                product: item?.pk,
                quantity: item?.quantity,
                note: item?.note,
                taxAmount: parseFloat(item?.tax).toFixed(2),
                enteredAmount: parseFloat(item?.price).toFixed(2),
              });
            });
          }
          item.isDeleted === false &&
            orderItems.push({
              product: item?.pk,
              quantity: item?.quantity,
              note: item?.note,
              modifier: modifierItems,
              taxAmount: parseFloat(item?.tax).toFixed(2),
              enteredAmount: parseFloat(item?.price).toFixed(2),
              promotionProducts: item?.compound?.length > 0 ? (item?.compound?.map((items: any) => (items?.pk))).join(',') : ""


            });
        });
        const response = await createKitchenOrder({
          variables: {
            orderInfo: orderInfo,
            orderItems: orderItems,
          },
        });
        if (response) {
          orderPrintItemsVarCache.writeQuery({
            query: GET_ORDER_PRINT_ITEMS,
            data: {
              orderPrintItems: orderPrintItemsVar(
                response?.data?.createKitchenOrder?.kitchenOrder
                  ?.kitchenOrderItems,
              ),
            },
          });
          handleClearPosScreen();
          if (tableItems?.pk) {
            handleSelectTableToggle(false, tableItems?.mergedId ? tableItems?.mergedId : tableItems?.pk);
            removeLocalKey("tableItemSelected");
          }
          toast.success("Successfully Hold");
        }
      }
    }
  };
  return (
    <ConfirmAlert
      title={"Alert!!"}
      subTitle={"Are you sure to hold this order??"}
      isOpen={openHoldPopup}
      onConfirm={handleHoldCard}
      notes={false}
      setIsOpen={setOpenHoldPopup}
    />
  )
}

export default PosScreenHoldOrderFunction

function handleClearPosScreen() {
  throw new Error('Function not implemented.')
}
