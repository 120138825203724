import { gql } from "@apollo/client";

const GET_RECEIPT = gql`
  query {
    receiptSetting {
      id
      pk
      createdBy {
        id
        pk
        username
        fullName
      }
      header
      footer
    }
  }
`;
export default GET_RECEIPT;
