import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useEffect, useState } from "react";
import {
  SEARCH_BARCODE,
  SEARCH_PLU,
  SEARCH_PRODUCT_ITEMS,
} from "../../../pages/PosScreen/queries";
import {
  POS_SCREEN_PRINTER_LIST,
  POS_SCREEN_PRODUCT_CATEGORY,
} from "../../../pages/Product/queries";
import { QUERY_TAX, QUERY_UNIT } from "../../../pages/queries";
import {
  cache,
  cartItemsVar,
  openProductPopupCache,
  openProductPopupVar,
  searchCache,
  searchItemsVar,
  searchSelectedCache,
  searchSelectedItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_OPEN_PRODUCT_POPUP,
  GET_SEARCH_ITEMS,
  GET_SEARCH_SELETCED_ITEMS,
} from "../../localstate/Cart";
import PosScreenCartSection from "./PosScreenCartSection/PosScreenCartSection";
import PosScreenCategoryTab from "./PosScreenCategoryTab";
import PosScreenSearchProductTab from "./PosScreenSearchProductTab";
import { toast } from "react-toastify";
import Modal from "../../reusable/Modal";
import AddProductImage from "../../../assets/addproductwhite.svg";
import TextField from "../../reusable/Forms/TextField";
import { Form, Formik } from "formik";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
import MultipleSelectField from "../../reusable/Forms/MultipleSelectField";
import { setLocalKey } from "../../../helpers/sessionKey";
import PriceField from "../../reusable/Forms/PriceField";
import Spinner from "../../reusable/Spinner";
import { POS_SCREEN_CREATE_PRODUCT } from "../../../pages/Product/mutation";
import { debounce } from "lodash";
import PosProductLoader from "../../reusable/Loader/PosProductLoader";
import PosScreenSearchBarcodeTab from "./PosScreenSearchBarcodeTab";
import PosScreenSearchPluTab from "./PosScreenSearchPluProduct";
import { addProductOpenValidationSchema } from "../../../utils/formValidation";

const PosScreen = (props: any) => {
  const {
    isLoading,
    categoriesData,
    handleNextPage,
    handlePreviousPage,
    handleCategoryClick,
    handleCategoryRefetch,
    handleProductRefetch,
    productsData,
    posScreenProductByCategoryDataLoading,
    productByCatId,
    productByCatPk,
    noOfColumns,
  } = props;
  const searchItems: any = useReactiveVar(searchItemsVar);
  const cartItems: [] = useReactiveVar(cartItemsVar);
  const [isLoadMore, setIsLoadMore] = useState<number>(20);
  const openProductPopup: any = useReactiveVar(openProductPopupVar);

  //Search Query for products
  const [
    getSearchProduct,
    { loading: searchProductLoading, data: searchProductData },
  ] = useLazyQuery(SEARCH_PRODUCT_ITEMS);
  const [
    getSearchProductBarcode,
    { loading: searchProductBarcodeLoading, data: searchProductBarcodeData },
  ] = useLazyQuery(SEARCH_BARCODE);
  const [
    getSearchProductPlu,
    { loading: searchProductPluLoading, data: searchProductPluData },
  ] = useLazyQuery(SEARCH_PLU);
  useEffect(() => {
    // wait 500ms before executing search
    let timeoutID = setTimeout(async () => {
      // do not search if input is empty
      if (searchItems?.name === "searchProduct" && searchItems?.searchInput) {
        getSearchProduct({
          variables: {
            first: isLoadMore ?? 20,
            offset: 0,
            search: searchItems.searchInput,
            productType: "product",
          },
          fetchPolicy: "network-only",
        });
      } else if (
        searchItems?.name === "searchBarcode" &&
        searchItems?.searchInput
      ) {
        getSearchProductBarcode({
          variables: {
            first: isLoadMore ?? 20,
            offset: 0,
            search: searchItems.searchInput,
          },
          fetchPolicy: "network-only",
        });
      } else if (
        searchItems?.name === "searchPlu" &&
        searchItems?.searchInput
      ) {
        getSearchProductPlu({
          variables: {
            first: isLoadMore ?? 20,
            offset: 0,
            search: searchItems.searchInput,
          },
          fetchPolicy: "network-only",
        });
      }
    }, 200);

    // CLEANUP: clear current timer
    return () => {
      clearTimeout(timeoutID);
    };
  }, [searchItems, searchItems.searchInput, isLoadMore]);

  useEffect(() => {
    if (searchProductData?.products) {
      const searchProductDataItems: any = {
        index: 0,
        data: searchProductData ?? {},
      };
      searchSelectedCache.writeQuery({
        query: GET_SEARCH_SELETCED_ITEMS,
        data: {
          searchSelectedItems: searchSelectedItemsVar(searchProductDataItems),
        },
      });
    }
  }, [searchProductData]);
  const [addQuickProductPopup, setAddQuickProductPopup] =
    useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,
    {},
  );
  const handleQuickProduct = async (values: any) => {
    if (isLoadingButton) {
      return;
    }
    setIsLoadingButton(true);
    setAddQuickProductPopup(false);
    setLocalKey("disableRemotePrint", "false");
    const response = await productCreate({
      variables: {
        title: values?.title,
        sellingPrice: values?.price,
        printers: values?.printer,
        isOpenProduct: true,
        promotionCategory: "",
        plu: "",
        modifierCategories: [],
        category: [],
        unit: null,
        tax: "f4e8d5b8-c316-483b-a829-8e3af49b23d5",
        isWeightable: false,
        printOrder: null,
        isModifierCategoryGrid: false,
        isOpenPriceProduct: false,
        image: null,
        costPrice: "",
        barcodeContent: "",
        stock: 0,
        threshold: 0,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      let productList = {
        id: response?.data?.createProduct?.product?.id,
        pk: response?.data?.createProduct?.product?.pk,
        title: response?.data?.createProduct?.product?.title,
        quantity: parseFloat("1").toFixed(2),
        price: response?.data?.createProduct?.product?.sellingPrice ?? 0,
        total: response?.data?.createProduct?.product?.sellingPrice ?? 0,
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        tax: response?.data?.createProduct?.product?.taxAmount,
        taxRate: {
          isIncluded: true,
          rate: response?.data?.createProduct?.product?.tax?.rate ?? "0.00",
        },
      };
      let nonPreviousItemList = cartItems?.map((item: any, index: number) => ({
        ...item,
        isSelected: false,
      }));
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([productList, ...nonPreviousItemList]),
        },
      });
      toast.success("Product Created successfully");
    } else {
      toast.error("Product Failed to Create");
    }
    setIsLoadingButton(false);
  };
  const debouncedHandleClick = debounce(handleQuickProduct, 300);
  const handleOpenProductPopup = () => {
    openProductPopupCache.writeQuery({
      query: GET_OPEN_PRODUCT_POPUP,
      data: {
        openAddProductPopup: openProductPopupVar(false),
      },
    });
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
  };
  //Initial Lists
  const [taxTypeDropdown, setTaxTypeDropdown] = useState([]);
  const [printerListsDropdown, setPrinterListsDropdown] = useState([]);
  const [modifierCategoryDropdown, setModifierCategoryDropdown] = useState([]);

  //Unit Type
  const { data: unitData } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
    fetchPolicy: "network-only",
  });
  const [unitDropdown, setUnitDropdown] = useState([
    {
      id: unitData?.units?.[0]?.node.pk,
      title: unitData?.units?.[0]?.node.title,
    },
  ]);

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      }),
    );
    setUnitDropdown(dropdownFormat);
  }, [unitData]);

  //Tax List
  const { data: taxData } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    if (taxData) {
      taxData?.taxes?.edges?.map((elem: any) =>
        dropdownFormat.push({
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded,
        }),
      );
    }
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);
  //Printer Lists
  const { data: printerListsData } = useQuery(POS_SCREEN_PRINTER_LIST, {
    variables: {
      first: 10,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    printerListsData?.printers?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.department,
      }),
    );
    setPrinterListsDropdown(dropdownFormat);
  }, [printerListsData]);

  //Modifier Category
  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setModifierCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);

  const initialValues = {
    title: "Misc Product",
    price: "",
    printer: [],
  };
  const PosScreenSearchTabs: any = () => {
    if (
      searchItems?.searchInput !== "" &&
      searchItems.name === "searchProduct" &&
      searchProductData !== undefined
    ) {
      return (
        <>
          {searchProductLoading ? (
            <div className="w-full overflow-y-auto h-full flex-[70%]">
              <PosProductLoader count={isLoadMore} />
            </div>
          ) : (
            <div className="w-full overflow-y-auto h-full flex-[70%]">
              <PosScreenSearchProductTab
                searchProductData={searchProductData}
                taxTypeDropdown={taxTypeDropdown}
                unitDropdown={unitDropdown}
                printerListsDropdown={printerListsDropdown}
                modifierCategoryDropdown={modifierCategoryDropdown}
                cartItems={cartItems}
                noOfColumns={noOfColumns}
              />

              {searchProductData?.products?.pageInfo?.hasNextPage === true ? (
                <div
                  className="text-primary underline p-3 text-center cursor-pointer flex justify-end "
                  onClick={() => setIsLoadMore((pre: any) => pre + 10)}
                >
                  Load More {">>>"}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      );
    } else if (
      searchItems?.searchInput !== "" &&
      searchItems.name === "searchPlu" &&
      searchProductPluData !== undefined &&
      !searchProductPluLoading
    ) {
      return (
        <>
          <PosScreenSearchPluTab
            noOfColumns={noOfColumns}
            searchProductPluData={searchProductPluData}
            taxTypeDropdown={taxTypeDropdown}
            unitDropdown={unitDropdown}
            printerListsDropdown={printerListsDropdown}
            modifierCategoryDropdown={modifierCategoryDropdown}
          />
        </>
      );
    } else {
      return (
        <div className="relative w-full h-full flex-[70%]">
          <button
            className="absolute bottom-5 z-10 right-0 font-bold p-3 rounded-full bg-blue-500 shadow-2xl"
            style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)" }}
            onClick={() => {
              setAddQuickProductPopup(true);
            }}
          >
            <img src={AddProductImage} alt="" className="w-[35px] h-[35px]" />
          </button>
          {isLoading ? (
            // <DataLoadSpinner />
            ""
          ) : (
            <PosScreenCategoryTab
              isLoading={isLoading}
              categoriesData={categoriesData}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handleCategoryClick={handleCategoryClick}
              handleCategoryRefetch={handleCategoryRefetch}
              handleProductRefetch={handleProductRefetch}
              posScreenProductByCategoryDataLoading={
                posScreenProductByCategoryDataLoading
              }
              productsData={productsData}
              productByCatId={productByCatId}
              productByCatPk={productByCatPk}
              noOfColumns={noOfColumns}
              taxTypeDropdown={taxTypeDropdown}
              unitDropdown={unitDropdown}
              printerListsDropdown={printerListsDropdown}
              modifierCategoryDropdown={modifierCategoryDropdown}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className="relative w-full h-full flex gap-8 ">
      <PosScreenSearchTabs />
      <div className=" w-full h-full flex-[30%] mx-0">
        <PosScreenCartSection />
      </div>
      <Modal
        title={"Add Open Product"}
        open={addQuickProductPopup}
        setOpen={setAddQuickProductPopup}
        size="max-w-sm"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={addProductOpenValidationSchema}
          onSubmit={debouncedHandleClick}
        >
          {({ touched, values, setFieldValue, errors, handleChange }: any) => (
            <Form>
              <div className="w-full mb-4 p-4 bg-slate-100">
                <div className="grid grid-cols-1 gap-4">
                  <TextField
                    name={"title"}
                    label={"Title"}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <PriceField
                    name={"price"}
                    label={"Price"}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    onChange={handleChange}
                  />
                  <MultipleSelectField
                    name="printer"
                    label={"Select on or more Printer"}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    options={printerListsDropdown}
                  />
                </div>
              </div>
              {isLoadingButton ? (
                <Spinner />
              ) : (
                <PrimaryButton
                  text={"Submit"}
                  loading={loadingCreateProduct}
                  disable={loadingCreateProduct}
                />
              )}
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        open={openProductPopup}
        setOpen={handleOpenProductPopup}
        title={"Add Product"}
      >
        <PosScreenSearchBarcodeTab
          taxTypeDropdown={taxTypeDropdown}
          unitDropdown={unitDropdown}
          printerListsDropdown={printerListsDropdown}
          modifierCategoryDropdown={modifierCategoryDropdown}
        />
      </Modal>
    </div>
  );
};
export default PosScreen;
