import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IHTMLFieldProps } from "../interface";
import { disableFocusCache, disableFocusVar } from "../../../localstate/Cache";
import { GET_DISABLE_FOCUS } from "../../../localstate/Cart";
import { useReactiveVar } from "@apollo/client";


interface IMultiSelectField extends IHTMLFieldProps {
  options?: any
  showChips?: boolean
  handleChange?: (value: any[], name: string) => void
  titleLabel?: string
  selectAllOption?: boolean
}

const MultiSelectField: React.FC<IMultiSelectField> = (props) => {
  const { name, label, values, helperText, touched, errors, setFieldValue, options, showChips = true, handleChange = null, titleLabel = null, selectAllOption = false, disabled = false } = props
  const [selectedOptions, setSelectedOptions] = useState<any>(values[name] || [])
  function isSelected(value: any) {
    return !!selectedOptions?.find((el: any) => el === value)
  }

  function handleSelection(item: any) {
    if (item !== "all-values") {
      const selectedResult = selectedOptions?.filter(
        (selected: any) => selected === item
      )
      if (selectedResult?.length) {
        removeOption(item);
      } else {
        setSelectedOptions((currents: any) => [...currents, item])
      }
    } else {
      // IF ALL OPTIONS ARE SELECTED THEN DESELCT ALL ELSE SELECT ALL
      if (selectedOptions?.length === options?.length) {
        setSelectedOptions([])
      } else {
        let allOption = options?.map((elem: any) => elem?.id)
        setSelectedOptions(allOption)
      }
    }
  };

  let selectedAltIdList: any = []
  useEffect(() => {
    setFieldValue(name, selectedOptions)
    handleChange && selectedOptions?.map((elem: any) => {
      let foundOption = options?.find((optionObject: any) => optionObject?.id === elem)
      return selectedAltIdList.push(foundOption?.altId ? foundOption?.altId : foundOption?.id)
    })
    handleChange && handleChange(selectedAltIdList, name)
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedOptions !== 0) {
      setSelectedOptions(values[name])
    }
  }, [values])

  function removeOption(item: any) {
    const removedSelection = selectedOptions.filter(
      (selected: any) => selected !== item
    );
    setSelectedOptions(removedSelection);
  }

  const renderSelectedLabel = (id: any) => {
    const item = options?.find((indexItem: any) => indexItem.id === id)
    return item?.title
  }
  const disableFocus: any = useReactiveVar(disableFocusVar);
  const handleClick = () => {
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(false),
      },
    });
  }

  return (
    <div className="w-full mb-6 relative">
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedOptions}
        onChange={handleSelection}
        disabled={disabled}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="absolute left-2 -top-2.5 z-10 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm">
              {titleLabel}
            </Listbox.Label>
            <div className="relative">
              <span className="inline-block w-full rounded-sm shadow-sm">
                <Listbox.Button
                  onClick={handleClick}
                  className="relative w-full  truncate rounded-md border text-gray-700 border-gray-300 bg-gray-200 pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 cursor-pointer">
                  {showChips ? <>{!selectedOptions?.length && label}</> : label}
                  {
                    showChips ?
                      selectedOptions?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="inline-flex items-center px-1 mr-1 mt-1 rounded text-white bg-gray-400"
                        >
                          {renderSelectedLabel(item)}
                          <div
                            className="ml-1 bg-gray-100 rounded-full cursor-pointer"
                            onClick={() => removeOption(item)}
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                                fill="#4A5568"
                              />
                            </svg>
                          </div>
                        </div>
                      )) : null}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
              </span>
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="z-50 absolute mt-1 w-full rounded-md bg-white shadow-lg"
              >
                <Listbox.Options
                  // disabled={true}
                  // static
                  className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                >
                  {selectAllOption ?
                    <Listbox.Option value={"all-values"}>
                      {({ active, selected }) => (
                        <div
                          className={`${active
                            ? "text-white bg-blue-600"
                            : "text-gray-900"
                            } z-50 cursor-default select-none relative py-2 pl-8 pr-4`}
                        >
                          <span
                            className={`${selected ? "font-semibold" : "font-normal"
                              } block truncate`}
                          >
                            Select All
                          </span>
                          {selected && (
                            <span
                              className={`${active ? "text-white" : "text-blue-600"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>

                    : null
                  }
                  {options?.map((item: any, index: number) => {
                    let selected = isSelected(item.id);
                    return (
                      <Listbox.Option key={index} value={item.id}>
                        {({ active }) => (
                          <div
                            className={`${active
                              ? "text-white bg-blue-600"
                              : "text-gray-900"
                              } z-50 cursor-default select-none relative py-2 pl-8 pr-4`}
                          >
                            <span
                              className={`${selected ? "font-semibold" : "font-normal"
                                } block truncate`}
                            >
                              {item?.title}
                            </span>
                            {selected && (
                              <span
                                className={`${active ? "text-white" : "text-blue-600"
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {Boolean(touched[name] && errors[name]) && <p className='text-sm text-red-500'>{errors[name] ?? ""}</p>}
      <p className='text-xs text-blue-500 '>{helperText}</p>
    </div>
  );
}
export default MultiSelectField