import { useQuery } from "@apollo/client";
import { BackspaceIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { QUERY_POS_DISCOUNT } from "../../../../../pages/queries";
import { permissionTypeCache, permissionTypeVar } from "../../../../localstate/Cache";
import { GET_PERMISSION_TYPE } from "../../../../localstate/Cart";

export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: "Clear",
    value: "clear",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: <BackspaceIcon className="w-5 h-5 m-auto" />,
    value: "backspace",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const numberWithDot = /^[0-9]*\.?[0-9]*$/; //regx for only number with one dot

type Props = {
  selectDiscountType?: any;
  setSelectDiscountType?: any;
  open?: any;
  setOpen?: any;
  totalAmtCalculations?: any
};
const OverAllDiscountForm = (props: Props) => {
  const { selectDiscountType, setSelectDiscountType, setOpen, totalAmtCalculations } = props;
  const [enteredNumber, setEnteredNumber] = useState<any>("");
  const [discountPercentageList, setDiscountPercentageList] = useState<any>([]);
  //Discount Percentage List
  const { data: discountListData } = useQuery(QUERY_POS_DISCOUNT, {
    variables: {
      first: 4,
    },
  });
  // for setting discount percentage at discount button
  useEffect(() => {
    if (discountListData?.discounts?.edges.length) {
      const discountPercentageItem = discountListData?.discounts?.edges?.map(
        (item: any) => item?.node,
      );
      setDiscountPercentageList(discountPercentageItem);
    }
  }, [discountListData]);

  const handleDiscountType = (selectDiscountType: string) => {
    if (selectDiscountType === "discountPer") {
      if (enteredNumber > 100) {
        toast.info("Discount Percentage cannot be more than 100")
      } else {
        setSelectDiscountType("discountPer");
      }
    }
    if (selectDiscountType === "discountAmt") {
      setSelectDiscountType("discountAmt");
    }
  };
  const handleDiscountPercentageType = (discountPercentageItem: any) => {
    discountPercentageItem.discountType === "PERCENTAGE"
      ? setSelectDiscountType({
        discountPercentage: discountPercentageItem.rateAmount,
        discountAmount: 0,
      })
      : setSelectDiscountType({
        discountPercentage: 0,
        discountAmount: discountPercentageItem.rateAmount,
      });
    setOpen(false);
  };
  const handleEnteredNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    if (numberWithDot.test(value)) {
      if (selectDiscountType === "discountPer" && parseFloat(value) > 100) {
        setEnteredNumber(100)
      } else {
        setEnteredNumber(value)
      }
    }
  };

  const handleCalculate = (data: string) => {
    switch (data) {
      case "backspace":
        setEnteredNumber(
          enteredNumber?.substring(0, enteredNumber?.length - 1),
        );
        break;
      case "clear":
        setEnteredNumber("");
        break;
      case "calculate":
        if (selectDiscountType === "discountPer") {
          setSelectDiscountType({
            discountPercentage: parseFloat(enteredNumber),
            discountAmount: 0,
          });
          setOpen(false);
          permissionTypeCache.writeQuery({
            query: GET_PERMISSION_TYPE,
            data: {
              permissionTypes: permissionTypeVar(''),
            },
          });
        } else if (selectDiscountType === "discountAmt" && enteredNumber < parseFloat(totalAmtCalculations?.netAmount)) {
          setSelectDiscountType({
            discountAmount: parseFloat(enteredNumber),
            discountPercentage: 0,
          });
          setOpen(false);
          permissionTypeCache.writeQuery({
            query: GET_PERMISSION_TYPE,
            data: {
              permissionTypes: permissionTypeVar(''),
            },
          });
        } else {
          if (selectDiscountType === "discountAmt") {
            toast.info("Discount Amount more than paid amount")
            return;
          }
          toast.info("Enter Discount Type")
        }
        break;
      default:
        if (numberWithDot.test(enteredNumber)) {
          if (selectDiscountType === "discountPer" && parseFloat(enteredNumber + data) > 100) {
            setEnteredNumber(100)
          } else {
            setEnteredNumber(enteredNumber + data)
          }
          // selectDiscountType === "discountPer" && parseFloat(enteredNumber + data) < 100 ? setEnteredNumber(enteredNumber + data) : setEnteredNumber(100);
        }
    }
  };

  return (
    <>
      <h3 className="text-left mb-1">Enter amount</h3>
      <div className="grid grid-cols-3 gap-1 mb-2">
        <input
          className="p-2 items-center border-2 border-solid border-black-800 rounded-md"
          autoFocus
          type="text"
          value={enteredNumber}
          onChange={(e) => handleEnteredNumber(e)}
          name={"enteredNumber"}
        />
        <span
          className={`col-span-1 w-full p-2 cursor-pointer text-md items-center justify-center rounded-md ${selectDiscountType === "discountAmt"
            ? "bg-[#4338CA] text-white"
            : "bg-[#E0E0E0]"
            }`}
          onClick={() => handleDiscountType("discountAmt")}
        >Discount (Amt.)</span>
        <span
          className={`col-span-1 w-full p-2 cursor-pointer text-md items-center justify-center rounded-md ${selectDiscountType === "discountPer"
            ? "bg-[#4338CA] text-white"
            : "bg-[#E0E0E0]"
            }`}
          onClick={() => handleDiscountType("discountPer")}
        >Discount (%)</span>
      </div>

      <div className="w-[100%] flex text-center">
        <div className="col-span-4 w-[80%]">
          <div className="grid grid-cols-4 gap-1 mr-2">
            {numberPad.map((item) => (
              <div
                key={item.id}
                className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
                onClick={() => handleCalculate(item.value)}
              >
                <span>{item.label}</span>
              </div>
            ))}
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("0")}
            >
              <span>0</span>
            </div>
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate(".")}
            >
              <span>.</span>
            </div>
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("00")}
            >
              <span>00</span>
            </div>
            <div
              className="col-span-2 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("calculate")}
            >
              <span>Enter</span>
            </div>
          </div>
        </div>
        <div className=" w-[20%]">
          <div className="grid grid-cols-1 grid-rows-4 gap-1">
            {discountPercentageList?.length === 0 ? (
              <>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 1 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 2 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}

                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 3 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : (
              discountPercentageList?.length <= 4 && (
                <>
                  {discountPercentageList?.map((item: any) => (
                    <div
                      key={item.id}
                      className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                      onClick={() => handleDiscountPercentageType(item)}
                    >
                      <span>
                        {item?.discountType === "PERCENTAGE"
                          ? `${item.rateAmount}%`
                          : item.rateAmount}
                      </span>
                    </div>
                  ))}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OverAllDiscountForm;