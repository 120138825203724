import React from "react";
import PosScreenButton from "../Buttons/PosScreenButton";
import { getLocalKey } from "../../../helpers/sessionKey";
import AccessControl from "../../../helpers/accessControl";

const IndividualButtons = ({
  handleClickButtons,
  action,
  tableItems,
  profile,
  value,
  orderType,
  cartItems,
  startWith,
  setPermissionType,
  kitchenOrdreItemId,
  setPermPopup,
  permissionTypes,
}: any) => {
  const disableButtons = (value: any) => {
    const cartCheck = cartItems?.length > 0;
    const kitchen = getLocalKey("disableRemotePrint");
    const disableRemotePrint = JSON.parse(
      getLocalKey("disableRemotePrint") || "false",
    );
    const hasKitchenOrderId =
      kitchenOrdreItemId?.kitchenCardItemId ||
      kitchenOrdreItemId?.kitchenOrderPk;
    const isRemotePrintEnabled = disableRemotePrint;
    const disableConditions: any = {
      booking_screen: true,
      table: true,
      order_type: true,
      pos: true,
      hold_cart: true,
      remote_order: true,
      orders: true,
      transaction: true,
      customer: true,
      product: true,
      stock: true,
      sales_report: true,
      hold_order: cartCheck,
      void_order:
        (cartCheck && hasKitchenOrderId?.id && isRemotePrintEnabled) ||
        (cartCheck &&
          hasKitchenOrderId?.id &&
          kitchenOrdreItemId?.orderStatus !== "HOLD"),
      pay: cartCheck,
      kitchen_print: cartCheck && kitchen === "false",
      clear: tableItems?.title || orderType?.title || cartCheck,
      discount: cartCheck,
      print_discount_docket: true,
      note: cartCheck,
      receipt_print: cartCheck,
      surcharge: cartCheck,
    };

    return disableConditions[value?.slug] || false;
  };
  const permissionGiven = () => {
    const allowedPermissions: any = {
      table: false,
      order_type: false,
      pos: false,
      hold_cart: false,
      remote_order: false,
      orders: false,
      transaction: false,
      product: false,
      stock: false,
      sales_report: false,
      customer: false,
      hold_order: false,
      void_order: "can_void_transaction",
      pay: false,
      kitchen_print: false,
      clear: false,
      discount: "can_apply_discount",
      print_discount_docket: false,
      note: false,
      receipt_print: false,
      surcharge: false,
    };
    return allowedPermissions[value?.slug];
  };
  const isDisabled = disableButtons(value);
  const isPermission = profile?.isSuperuser ? false : permissionGiven();
  const renderButtonContent = () => (
    <>
      {action?.node?.action?.type === "table" && tableItems?.title && (
        <span className="absolute top-[10%]  flex w-[max-content] p-1 bg-green-600 text-center text-white text-[10px]">
          {tableItems.title}
        </span>
      )}
      {action?.node?.action?.type === "order_type" && orderType?.title && (
        <span className="absolute top-[10%]  flex w-[max-content] p-1 bg-green-600 text-center text-white text-[10px]">
          {orderType.title}
        </span>
      )}
      {action?.node?.action?.actionName}
    </>
  );
  const handleButtonClick = (e: any, isClickable: boolean) => {
    if (isClickable && !isPermission) {
      handleClickButtons(
        e,
        action?.node?.action?.actionName,
        action?.node?.action?.type,
      );
    } else if (permissionTypes && isClickable) {
      handleClickButtons(
        e,
        action?.node?.action?.actionName,
        action?.node?.action?.type,
      );
    } else if (isClickable && isPermission) {
      setPermPopup(true);
      setPermissionType(isPermission);
    }
  };
  const renderButton = (
    color: any,
    textColor: any,
    bgColor: any,
    isClickable: boolean,
  ) => {
    return (
      <div className={`h-full w-full text-${textColor} bg-[${bgColor}]`}>
        <div
          className={`h-full w-full text-${textColor}`}
          onClick={(e) => {
            handleButtonClick(e, isClickable);
          }}
        >
          <div className="flex w-full h-full flex-col gap-1 justify-center items-center p-2">
            {value?.icon && (
              <img
                src={value?.icon}
                className="relative w-5 h-5 cursor-pointer"
              />
            )}
            {renderButtonContent()}
          </div>
        </div>
      </div>
    );
  };
  const PosScreenRightSide = ({ onClick }: { onClick: (e: any) => void }) => {
    if (startWith !== "R") {
      return null;
    }
    return (
      <div className="flex w-full h-full flex-col justify-center items-center p-1">
        <PosScreenButton
          disabled={isDisabled}
          onClick={onClick}
          buttonTitle={renderButtonContent()}
        />
      </div>
    );
  };
  const posSCreenLeftSide = () => {
    return (
      startWith === "L" &&
      (isDisabled ? (
        <div className="w-full h-full border-b-2">
          {renderButton("white", "white", "#4338CA", true)}
          <hr className="w-full bg-[#921f1f42]" />
        </div>
      ) : (
        <div
          className={`w-full h-full border-b-2 ${!value ? "bg-primary" : ""}`}
        >
          {renderButton("black", "black", "#ffff", false)}
          <hr className="w-full bg-[#921f1f42]" />
        </div>
      ))
    );
  };
  return (
    <>
      {isPermission !== false ? (
        <>
          {permissionTypes === isPermission ? (
            <>
              <PosScreenRightSide
                onClick={(e: any) =>
                  handleClickButtons(
                    e,
                    action?.node?.action?.actionName,
                    action?.node?.action?.type,
                  )
                }
              />
              {posSCreenLeftSide()}
            </>
          ) : (
            <AccessControl
              allowedPermissions={["can_void_transaction"]}
              renderNoAccess={
                <>
                  <PosScreenRightSide
                    onClick={() => {
                      setPermPopup(true);
                      setPermissionType(isPermission);
                    }}
                  />
                  {posSCreenLeftSide()}
                </>
              }
              children={
                <>
                  <PosScreenRightSide
                    onClick={(e: any) =>
                      handleClickButtons(
                        e,
                        action?.node?.action?.actionName,
                        action?.node?.action?.type,
                      )
                    }
                  />
                  {posSCreenLeftSide()}
                </>
              }
            />
          )}
        </>
      ) : (
        <>
          <PosScreenRightSide
            onClick={(e: any) =>
              handleClickButtons(
                e,
                action?.node?.action?.actionName,
                action?.node?.action?.type,
              )
            }
          />
          {posSCreenLeftSide()}
        </>
      )}
    </>
  );
};

export default IndividualButtons;
