import React, { useRef, useState } from "react";
import Pay from "../PosScreenType/AustraliaPosScreen/PosScreenCartSection/Pay/Pay";
import Modal from "../reusable/Modal";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  cartItemsVar,
  CustomerItemsVar,
  noteVar,
  rewardPointVar,
  splitPayCache,
  splitPayVar,
  tableItemsVar,
  totalCalculationVar,
} from "../localstate/Cache";
import { GET_SPLIT_PAY } from "../localstate/Cart";
import GET_RECEIPT from "../../pages/ReceiptSetting/queries";
import { QUERY_SETTINGS } from "../../pages/Settings/graphql";
import PayBillForm from "../PosScreenType/AustraliaPosScreen/PosScreenCartSection/Pay/PayBillForm";

type Props = {
  openPayPopup: boolean;
  setOpenPayPopup: any;
  setSelectSuchargeType: any;
  setSelectDiscountType: any;
  selectedTerminal: any;
};

const PosScreenPayFunction = (props: Props) => {
  const {
    openPayPopup,
    setOpenPayPopup,
    setSelectSuchargeType,
    setSelectDiscountType,
    selectedTerminal,
  } = props;
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const totalAmtCalculations: any = useReactiveVar(totalCalculationVar);
  const [enteredNumber, setEnteredNumber] = useState<number>(0);
  const noteItems: any = useReactiveVar(noteVar);
  const RewardPointAmount: any = useReactiveVar(rewardPointVar);
  const [paymentOrderId, setPaymentOrderId] = useState("");
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const { data: receiptData } = useQuery(GET_RECEIPT);
  const [chectked, setChecked] = useState<boolean>(false);
  const [openPayPrintBillPopup, setOpenPayPrintBillPopup] = useState(false);
  const terminalReceiptSetting = selectedTerminal?.terminalsetting;
  let selectedCustomer;
  const handlePayClose = () => {
    setEnteredNumber(0);
    splitPayCache.writeQuery({
      query: GET_SPLIT_PAY,
      data: {
        splitPay: splitPayVar([]),
      },
    });
  };
  return (
    <>
      <Modal
        title={"Pay"}
        open={openPayPopup}
        setOpen={setOpenPayPopup}
        size="max-w-xl"
        onCloseModal={() => handlePayClose()}
        children={
          <Pay
            cartItems={cartItems}
            enteredNumber={enteredNumber}
            setEnteredNumber={setEnteredNumber}
            setSelectSuchargeType={setSelectSuchargeType}
            openPayPopup={openPayPopup}
            setOpenPayPopup={setOpenPayPopup}
            tableItems={tableItems}
            customerItems={customerItems}
            totalAmtCalculations={totalAmtCalculations}
            noteItems={noteItems}
            terminalReceiptSetting={terminalReceiptSetting}
            openPayPrintBillPopup={openPayPrintBillPopup}
            setOpenPayPrintBillPopup={setOpenPayPrintBillPopup}
            paymentOrderId={paymentOrderId}
            setPaymentOrderId={setPaymentOrderId}
            setSelectDiscountType={setSelectDiscountType}
            basicSetting={basicSetting}
            receiptData={receiptData}
            selectedCustomer={selectedCustomer}
            RewardPointAmount={RewardPointAmount}
            chectked={chectked}
            setChecked={setChecked}
          />
        }
      />
      <Modal
        title={"Print Bill"}
        open={openPayPrintBillPopup}
        setOpen={setOpenPayPrintBillPopup}
        children={
          <PayBillForm
            setOpen={setOpenPayPrintBillPopup}
            paymentOrderId={paymentOrderId}
            receiptPrintDefault={terminalReceiptSetting}
          />
        }
      />
    </>
  );
};

export default PosScreenPayFunction;
