import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import PosScreenProductTab from "./PosScreenProductTab";
import RightArrowIcon from "../../../assets/rightarrow.svg";
import LeftArrowIcon from "../../../assets/leftarrow.svg";
import Modal from "../../reusable/Modal";
import ChangeModalForm from "./ChangeModalForm";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { categoryInitialValues } from "../../../utils/formInitialValues";
// import { categoryFormValidationSchema } from "../../../utils/formValidation";
// import CategoryForm from "../../Category/CategoryForm";
import {
  ROOT_CATEGORIES_QUERY,
  useRootCatagories,
} from "../../../pages/PosScreen/queries";
import randomString from "../../../utils/randomString";
import ColorPicker from "../../reusable/ColorPicker";
import classNames from "../../../utils/classNames";
import { ADD_CATEGORY } from "../../../pages/mutations";
import {
  updataPosScreenCategory,
  REMOVE_ASSIGNED_CATEGORY,
} from "../../../pages/PosScreen/mutation";
import { debounce } from "lodash";
import { categoryFormValidationSchema } from "../../../utils/formValidation";

export default function PosScreenCategoryTab(props: any) {
  const {
    categoriesData,
    handlePreviousPage,
    handleNextPage,
    handleCategoryClick,
    handleCategoryRefetch,
    handleProductRefetch,
    productsData,
    posScreenProductByCategoryDataLoading,
    productByCatId,
    productByCatPk,
    noOfColumns,
    categoryDropdown,
    taxTypeDropdown,
    unitDropdown,
    setIsModifier,
    printerListsDropdown,
    modifierCategoryDropdown,
  } = props;
  const [openContextPopup, setOpenContextPopup] = useState<boolean>(false);
  const [openContextPopupProduct, setOpenContextPopupProduct] =
    useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>("");
  //Change Context Category Name
  const [changePopup, setChangePopup] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  //Category List
  const { data } = useQuery(useRootCatagories, {
    fetchPolicy: "cache-and-network",
    variables: {
      name_Icontains: input,
      status: "category",
    },
  });
  const [posColorId, setPosColorId] = useState("");

  //Handle Category Change
  const [updatePosScreenCategories] = useMutation(updataPosScreenCategory, {
    refetchQueries: ["PosScreenCategories"],
  });

  const handleCategoryChange = async (catPk: string) => {
    const response = await updatePosScreenCategories({
      variables: {
        id: categoryId,
        categoryId: catPk,
      },
    });
    if (response?.data?.assignCategory?.success) {
      setChangePopup(false);
      await handleCategoryRefetch();
      toast.success(response?.data?.assignCategory?.success);
      setInput("");
    }
  };

  //handle Remove Assigned Category
  const [removeAssignedCategory] = useMutation(REMOVE_ASSIGNED_CATEGORY, {
    refetchQueries: ["PosScreenCategories"],
    fetchPolicy: "network-only",
  });

  const handleRemoveAssignCategory = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const response = await removeAssignedCategory({
      variables: {
        id: categoryId,
      },
    });
    if (response?.data?.removeAssignedCategory?.success) {
      await handleCategoryRefetch();
      toast.success("Category Removed successfully");
    }
    setOpenContextPopup(false);
  };

  //Handle Quick Add Product
  const [addCategoryPopup, setAddCategoryPopup] = useState<boolean>(false);

  const [addQuickCategory, { loading: addQuickCategoryLoading }] = useMutation(
    ADD_CATEGORY,
    {
      refetchQueries: [
        {
          query: ROOT_CATEGORIES_QUERY,
          variables: {
            offset: 0,
          },
        },
      ],
    }
  );

  const handleQuickAddCategory = (e: any) => {
    e.stopPropagation();
    setAddCategoryPopup(true);
    setOpenContextPopup(false);
  };

  const handleCreateCategory = async (values: any, props: any) => {
    setAddCategoryPopup(false);
    const response = await addQuickCategory({
      variables: {
        posScreenCategoryId: categoryId.toString(),
        name: values?.name,
        status: "category",
      },
    });
    if (response?.data?.createCategory?.success) {
      toast.success("Category Created successfully");
      await handleCategoryRefetch();
    }
  };

  const debouncedHandleProductClick = debounce(handleCreateCategory, 300);

  const handleChangeButton = (e: any) => {
    e.stopPropagation();
    setChangePopup(true);
    setOpenContextPopup(false);
  };
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const handleQuickAddColor = async (e: any) => {
    e.stopPropagation();
    setDisplayColorPicker(true);
    setOpenContextPopup(false);
  };

  const handleClick = (e: any, item: any) => {
    if (e.type === "contextmenu") {
      setCategoryId(item?.node?.pk);
      setOpenContextPopup(true);
      setOpenContextPopupProduct(false);
    }
  };
  return (
    <div className="flex flex-col gap-2 w-full h-full mt-1">
      {openContextPopup ? (
        <div
          className="w-full h-full absolute z-10"
          onClick={() => setOpenContextPopup(false)}
        ></div>
      ) : null}

      {categoriesData?.posScreenCategories ? (
        <div className={"relative"}>
          {categoriesData?.posScreenCategories?.pageInfo.hasNextPage && (
            <div
              className={
                "absolute flex justify-center items-center cursor-pointer right-0 top-[50%] bg-[#FFFFFF] rounded-full p-2 z-10"
              }
              onClick={() => handleNextPage()}
            >
              <img className="h-4 w-4" src={RightArrowIcon} alt="" />
            </div>
          )}
          {categoriesData?.posScreenCategories?.pageInfo.hasPreviousPage && (
            <div
              className={
                "absolute flex justify-center items-center cursor-pointer left-0 top-[50%] bg-[#FFFFFF] rounded-full p-2 z-10"
              }
              onClick={() => handlePreviousPage()}
            >
              <img className="h-4 w-4" src={LeftArrowIcon} alt="" />
            </div>
          )}
          <div
            className={classNames(
              `grid px-4 grid-cols-${noOfColumns}  gap-2 [word-spacing:3px]`,
              categoriesData?.posScreenCategories?.edges?.length <= noOfColumns
                ? "grid-rows-1 mb-4"
                : "grid-rows-2"
            )}
          >
            {categoriesData?.posScreenCategories?.edges?.map(
              (item: any, index: number) => {
                return (
                  <React.Fragment key={randomString(10)}>
                    <div
                      className={`relative cursor-pointer rounded-lg border-2 border-solid ${
                        productByCatId === item?.node?.id
                          ? "border-indigo-400"
                          : "border-gray-300"
                      }`}
                      style={{
                        background: item?.node?.category?.category?.name
                          ? item?.node?.category?.color ?? "#FF6542"
                          : "#F3F4F6",
                        height: "100px",
                      }}
                      onClick={() =>
                        handleCategoryClick(item?.node?.id, item?.node?.pk)
                      }
                      onContextMenu={(event: any) => (
                        handleClick(event, item),
                        setPosColorId(item?.node?.category?.pk)
                      )}
                    >
                      {item?.node?.category?.category?.image ? (
                        <>
                          <img
                            src={item?.node?.category?.category?.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="object-cover"
                          />
                          <span
                            title={item?.node?.category?.category?.name}
                            className={`textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs font-bold text-left px-1`}
                          >
                            {item?.node?.category?.category?.name}
                          </span>
                        </>
                      ) : (
                        <span
                          title={item?.node?.category?.category?.name}
                          className={
                            "textWithTwoLine absolute top-[20%] w-full  text-left font-bold px-2"
                          }
                        >
                          {item?.node?.category?.category?.name}
                        </span>
                      )}

                      {item?.node?.pk === categoryId && openContextPopup && (
                        <>
                          <div
                            className={
                              "w-full absolute cursor-pointer top-[50%] bg-[#FFFFFF] rounded-md p-1 z-20"
                            }
                            style={{ width: "120px" }}
                          >
                            {!item?.node?.category?.category?.name && (
                              <div
                                onClick={(e) => handleChangeButton(e)}
                                className="hover:bg-[#4338CA]/50 pl-1 my-4"
                              >
                                Change
                              </div>
                            )}
                            <div
                              onClick={(e) => handleRemoveAssignCategory(e)}
                              className="hover:bg-[#4338CA]/50 pl-1 my-4"
                            >
                              Remove
                            </div>
                            {!item?.node?.category?.category?.name && (
                              <div
                                onClick={(e) => handleQuickAddCategory(e)}
                                className="hover:bg-[#4338CA]/50 pl-1 my-4"
                              >
                                Add Category
                              </div>
                            )}
                            <div
                              onClick={(e) => handleQuickAddColor(e)}
                              className="hover:bg-[#4338CA]/50 pl-1 my-4"
                            >
                              Add Color
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                );
              }
            )}
          </div>
        </div>
      ) : (
        <div className={"flex-[20%] relative"}>
          {categoriesData?.categories?.pageInfo.hasNextPage && (
            <div
              className={
                "absolute flex justify-center items-center cursor-pointer right-0 h-full bg-[#FFFFFF] rounded-lg p-2"
              }
              onClick={() => handleNextPage()}
            >
              <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
          {categoriesData?.categories?.pageInfo.hasPreviousPage && (
            <div
              className={
                "absolute flex justify-center items-center cursor-pointer left-0 h-full bg-[#FFFFFF] rounded-full p-2"
              }
              onClick={() => handlePreviousPage()}
            >
              <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
          <div
            className={`grid grid-cols-${noOfColumns} px-4 grid-rows-2 gap-2`}
          >
            {categoriesData?.categories?.edges?.map(
              (item: any, index: number) => (
                <React.Fragment key={randomString(10)}>
                  <div
                    className={
                      "relative cursor-pointer bg-[#F3F4F6] rounded-lg border-2 border-solid border-white "
                    }
                    onClick={(event: any) =>
                      handleCategoryClick(event, item, item?.node?.pk)
                    }
                    onContextMenu={(event: any) => (
                      handleClick(event, item),
                      setPosColorId(item?.node?.category?.pk)
                    )}
                  >
                    {item?.node?.category?.category?.image ? (
                      <>
                        <img
                          src={item?.node?.category?.category?.image}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <span
                          title={item?.node?.category?.category?.name}
                          className={`textWithTwoLine absolute bottom-0 w-full bg-[#F3F4F6] text-center px-2`}
                        >
                          {item?.node?.category?.category?.name}
                        </span>
                      </>
                    ) : (
                      <span
                        title={item?.node?.category?.category?.name}
                        className={
                          "textWithTwoLine absolute top-[20%] w-full bg-[#F3F4F6] text-center  font-bold px-1"
                        }
                      >
                        {item?.node?.category?.category?.name}
                      </span>
                    )}
                    {item?.node?.category?.category?.pk === categoryId &&
                      openContextPopup && (
                        <div
                          className={
                            "w-full absolute flex justify-center items-center cursor-pointer left-0 top-[50%] bg-[#FFFFFF] rounded-md  p-1 z-20"
                          }
                        >
                          <div
                            onClick={(e) => handleChangeButton(e)}
                            className="hover:bg-[#4338CA]/50 pl-1"
                          >
                            Change
                          </div>
                          <div
                            onClick={(e) => handleRemoveAssignCategory(e)}
                            className="hover:bg-[#4338CA]/50 pl-1"
                          >
                            Remove
                          </div>
                          <div
                            onClick={(e) => handleQuickAddCategory(e)}
                            className="hover:bg-[#4338CA]/50 pl-1"
                          >
                            Add Category
                          </div>
                          <div
                            onClick={(e) => handleQuickAddColor(e)}
                            className="hover:bg-[#4338CA]/50 pl-1"
                          >
                            Add Color
                          </div>
                        </div>
                      )}
                  </div>
                </React.Fragment>
              )
            )}
          </div>
        </div>
      )}
      <div
        className={classNames(
          openContextPopupProduct ? "overflow-hidden" : "overflow-y-auto",
          "flex-[78%] relative "
        )}
      >
        {posScreenProductByCategoryDataLoading ? (
          // <Spinner />
          ""
        ) : (
          <PosScreenProductTab
            productsData={productsData}
            productByCatId={productByCatId}
            productByCatPk={productByCatPk}
            categoryDropdown={categoryDropdown}
            taxTypeDropdown={taxTypeDropdown}
            unitDropdown={unitDropdown}
            openContextPopup={openContextPopupProduct}
            setOpenContextPopup={setOpenContextPopupProduct}
            noOfColumns={noOfColumns}
            setIsModifier={setIsModifier}
            printerListsDropdown={printerListsDropdown}
            modifierCategoryDropdown={modifierCategoryDropdown}
            handleProductRefetch={handleProductRefetch}
            handleCategoryClick={handleCategoryClick}
            posColorId={posColorId}
            setPosColorId={setPosColorId}
          />
        )}
      </div>

      <Modal
        title={"Change Item"}
        open={changePopup}
        setOpen={setChangePopup}
        onCloseModal={() => setInput("")}
      >
        <ChangeModalForm
          input={input}
          setInput={setInput}
          children={
            <ul className="block w-full text-left p-1 ml-1  h-[100px] overflow-y-scroll shadow-lg">
              {data?.categories?.edges?.map((item: any, index: number) => (
                <li
                  key={randomString(20)}
                  className="hover:bg-[#4338CA] hover:text-white cursor-pointer"
                  onClick={() => handleCategoryChange(item?.node?.pk)}
                >
                  {item?.node?.name}
                </li>
              ))}
            </ul>
          }
          name={"searchategory"}
        />
      </Modal>

      <Modal
        title={"Add Category"}
        open={addCategoryPopup}
        setOpen={setAddCategoryPopup}
      >
        <div>
          <Formik
            initialValues={categoryInitialValues}
            validationSchema={categoryFormValidationSchema}
            onSubmit={debouncedHandleProductClick}
          >
            {({ touched, values, setFieldValue, errors }) => {
              return (
                <>
                  {/* // <CategoryForm
                //   touched={touched}
                //   values={values}
                //   setFieldValue={setFieldValue}
                //   errors={errors}
                //   categoryLoading={addQuickCategoryLoading}
                //   text="Add"
                //   imageActive={false}
                // /> */}
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
      <ColorPicker
        type="category"
        id={posColorId}
        displayColorPicker={displayColorPicker}
        setDisplayColorPicker={setDisplayColorPicker}
      />
    </div>
  );
}
