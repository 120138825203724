import PosScreenButtons from "../../../reusable/PosScreenButtons";

type Props = {};
export default function PosScreenQuickAccessTool(props: Props) {
  return (
    <div className="w-full h-full container pl-1 block">
      <div className="w-full h-full bg-[#ffffff] text-center grid grid-rows-2 grid-cols-5">
        <PosScreenButtons startWith={"R"} />
      </div>
    </div>
  );
}
