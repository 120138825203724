import { useLazyQuery, useQuery } from '@apollo/client'
import DataLoadSpinner from '../../reusable/Spinner/DataLoadSpinner'
import { GET_RESTAURANT_FLOOR, QUERY_RESTAURANT_TABLE } from '../../../pages/queries'
import { useEffect } from 'react'
import { isFunction } from 'lodash'

type Props = {
  selectedSwapTable: string
  setSelectedSwapTable: any
  tableId: string
  tableData: any
  isTableLoading: boolean
  handleSwapTable: any
}

const PosScreenSwapTable = (props: Props) => {
  const { data: selectedRestaurantFloor } = useQuery(QUERY_RESTAURANT_TABLE, {
    fetchPolicy: "network-only",
    pollInterval: 2000,
    variables: {
      isVisible: true,
      isFunctional: true
    }
  });
  const {
    selectedSwapTable,
    setSelectedSwapTable,
    tableId,
    tableData,
    isTableLoading,
    handleSwapTable
  } = props
  return (
    <>
      {isTableLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <div className="h-[520px] overflow-y-scroll">
            <div className="grid grid-rows-7 grid-cols-7 gap-2 p-2">
              {selectedRestaurantFloor?.restaurantTables?.edges?.filter((item: any) =>
                (item?.node?.mergeInstance ? item?.node?.mergeInstance : item?.node?.pk).toString() !== tableId).map((elem: any) =>
                (
                  <div
                    key={elem?.node?.pk}
                    className={`flex text-center border-2 bg-[#efefef] ${selectedSwapTable === (elem?.node?.mergeInstance ? elem?.node?.mergeInstance : elem?.node?.pk).toString() ? "border-[#00BFFF]" : "border-[#efefef]"}
                                  p-3 min-h-full max-h-[60px] cursor-pointer rounded-lg items-center shadow-sm text-gray-800`}
                    onClick={() => setSelectedSwapTable((elem?.node?.mergeInstance ? elem?.node?.mergeInstance : elem?.node?.pk).toString())}
                  >
                    <div className="mx-auto ">
                      <p className="flex">
                        {elem?.node?.title}
                        <br />
                        0/{elem?.node?.capacity}
                      </p>
                    </div>

                  </div>
                ))
              }
            </div>
          </div>

          <div className='text-right mt-3'>
            <button
              onClick={() => handleSwapTable()}
              className={`w-[10%] h-[50px] text-white  bg-[#4338CA] rounded mr-5`}
            >
              Swap
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default PosScreenSwapTable