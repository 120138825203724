import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  count: number;
};

const ModifierPlaceHolderLoader = (props: Props) => {
  const { count } = props;
  return (
    <div className="relative z-10">
      <div className={`grid w-full grid-cols-${7} p-4 grid-rows-7 gap-2 `}>
        {Array.from({ length: count }).map((_, index) => (
          <Skeleton
            key={index}
            className="relative h-[80px] cursor-pointer border-2 border-solid rounded-lg"
          />
        ))}
      </div>
    </div>
  );
};

export default ModifierPlaceHolderLoader;
