import React from 'react'
import PosScreenNote from '../PosScreenType/AustraliaPosScreen/PosScreenCartSection/Note/PosScreenNote';
import Modal from '../reusable/Modal';
import { useReactiveVar } from '@apollo/client';
import { noteVar } from '../localstate/Cache';

type Props = {
  openNotePopup: boolean
  setOpenNotePopup: any
}

const PosScreenNoteFunction = (props: Props) => {
  const { openNotePopup, setOpenNotePopup } = props;
  const noteItems: any = useReactiveVar(noteVar);

  return (
    <Modal
      title={"Note"}
      open={openNotePopup}
      setOpen={setOpenNotePopup}
      children={
        <PosScreenNote
          setOpenNotePopup={setOpenNotePopup}
          noteItems={noteItems}
        />
      }
    />
  )
}

export default PosScreenNoteFunction