import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { POS_SCREEN_ORDERED_ITEMS_LIST } from "../queries";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { searchItemsVar } from "../../../components/localstate/Cache";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const PosScreenOrderComponent: any = Loadable({
  loader: () => import("../../../components/Orders/OrderList/OrderList"),
  loading: () => <TableLoader showRightBar={false}/>,
});

const PosScreenOrder = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const searchItems: any = useReactiveVar(searchItemsVar);
  const { loading: ordersDataLoading, data: ordersData } = useQuery(
    POS_SCREEN_ORDERED_ITEMS_LIST,
    {
      fetchPolicy: "no-cache",
      variables: {
        offset: offset,
        first: pageSize,
        search: searchItems?.searchInput,
      },
    },
  );

  return (
    <div className="w-full">
      <PosScreenOrderComponent
        ordersDataLoading={ordersDataLoading}
        data={ordersData?.orders}
        isPosScreenKitchenOrder={true}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenOrder;
