import Cleave from "cleave.js/react";
import { useState } from "react";
import { cache, cartItemsVar } from "../../localstate/Cache";
import { GET_CART_ITEMS } from "../../localstate/Cart";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
const log = "electron-log/node";

type Props = {
  item?: any;
  cartItems?: any;
  setScaleValue?: any;
  setIsWeightableProduct?: any;
  isOpenPriceProducts?: any;
  setEnteredNumber?: any;
  enteredNumber?: any;
};

export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];

const WeightableProductQuantityForm = (props: Props) => {
  const {
    item,
    cartItems,
    setIsWeightableProduct,
    setScaleValue,
    isOpenPriceProducts,
    setEnteredNumber,
    enteredNumber,
  } = props;

  const handleCalculate = (data: string) => {
    switch (data) {
      case "backspace":
        if (enteredNumber?.length - 1) {
          setEnteredNumber(
            enteredNumber
              ?.toString()
              .substring(0, enteredNumber?.toString().length - 1)
          );
        } else {
          setEnteredNumber("");
        }
        break;
      case "clear":
        setEnteredNumber("");
        break;
      default:
        setEnteredNumber(enteredNumber + data);
    }
  };

  const handleClick = () => {
    if (enteredNumber > 0) {
      setScaleValue(enteredNumber);
      if (isOpenPriceProducts) {
        setIsWeightableProduct(false);
      } else {
        let productInCartList = {
          id: item?.id,
          pk: item?.pk,
          contentTypeId: item?.contentTypeId,
          title: item?.title,
          quantity: parseFloat(enteredNumber),
          price: item?.finalPrice ?? 0,
          total: parseFloat(enteredNumber) * parseFloat(item?.finalPrice) ?? 0,
          tax: parseFloat(enteredNumber) * parseFloat(item?.taxAmount),
          taxRate: {
            rate: item?.tax?.rate ?? 0,
            isIncluded: item?.tax?.isIncluded,
          },
          isSelected: true,
          isDeleted: false,
          modifier: [],
          isModified: true,
        };
        let previousObject: any = cartItems.find(
          (x: any) => x.id === item?.id && x.isSelected === true
        );

        if (previousObject && previousObject.isSelected) {
          let newItemList: any = cartItems;
          let foundIndex: any = cartItems.findIndex(
            (x: any) => x.id === item?.id && x.isSelected === true
          );
          newItemList[foundIndex].quantity = item?.isOpenPriceProduct
            ? parseFloat(enteredNumber)
            : previousObject.quantity >= 0
            ? parseFloat(previousObject.quantity) + parseFloat(enteredNumber)
            : 1;
          newItemList[foundIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          newItemList[foundIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) *
                parseFloat(previousObject.quantity)
              : 0;

          newItemList[foundIndex].isDeleted = false;
          newItemList[foundIndex].isSelected = true;
          newItemList[foundIndex].isModified = true;

          let updateNewItemList: any = newItemList.map(
            (item: any, index: number) =>
              !index === foundIndex
                ? {
                    ...item,
                    isSelected: false,
                  }
                : item
          );

          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...updateNewItemList]),
            },
          });
        } else {
          let nonPreviousItemList = cartItems?.map(
            (item: any, index: number) => ({
              ...item,
              isSelected: false,
            })
          );

          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([
                productInCartList,
                ...nonPreviousItemList,
              ]),
            },
          });
        }
        setIsWeightableProduct(false);
      }
    } else {
      setIsWeightableProduct(true);
    }
  };
  return (
    <>
      <Cleave
        type="text"
        name={"quantity"}
        className="relative peer bg-[#FFFFFF] p-3  w-full text-gray-700 mt-2 mb-3 rounded"
        style={{
          boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
        }}
        placeholder={"Enter Quantity "}
        value={enteredNumber}
        onChange={(e: any) => setEnteredNumber(e.target.value)}
        autoComplete="off"
        options={{
          numeral: true,
          numeralDecimalMark: ".",
          numeralDecimalScale: 2,
          numeralPositiveOnly: true,
          delimiter: "",
        }}
      />
      <br />
      <div className="grid grid-cols-3 gap-4">
        {numberPad.map((item: any) => (
          <div
            key={item.id}
            className="col-span-1 w-full  p-2  cursor-pointer bg-[#ffffff] text-[18px] text-center rounded items-center justify-center"
            style={{
              boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => handleCalculate(item.value)}
          >
            <span>{item.label}</span>
          </div>
        ))}
        <div
          className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
          style={{
            boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
          }}
          onClick={() => handleCalculate(".")}
        >
          <span>.</span>
        </div>
        <div
          className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
          style={{
            boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
          }}
          onClick={() => handleCalculate("0")}
        >
          <span>0</span>
        </div>
        <div
          className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
          style={{
            boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
          }}
          onClick={() => handleCalculate("backspace")}
        >
          <span>x</span>
        </div>
      </div>
      <div className="text-right mt-4">
        <PrimaryButton
          onClick={handleClick}
          text={"Add"}
          loading={false}
          disable={false}
        />
      </div>
    </>
  );
};
export default WeightableProductQuantityForm;
