import React, { useEffect, useState } from "react";
import DynamicDropDown from "../../reusable/Forms/DynamicDropDown";
import TextField from "../../reusable/Forms/TextField";
import randomString from "../../../utils/randomString";
import { IHTMLDropDownProps } from "../../reusable/Forms/interface";
import PriceField from "../../reusable/Forms/PriceField";
import MultipleSelectField from "../../reusable/Forms/MultipleSelectField";
import CheckBoxField from "../../reusable/Forms/CheckBoxField";
import { POS_SCREEN_PRODUCT_CATEGORY } from "../../../pages/Product/queries";
import { useQuery } from "@apollo/client";
const TaxTableTypeDropDownField: React.FC<IHTMLDropDownProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    list,
    disabled = false,
    helperText,
  } = props;
  const id = randomString(10, "-drop-down-field");

  const handleOnChange = (value: string) => {
    if (value === "0") {
      setFieldValue(name, "");
    } else {
      setFieldValue(name, value);
    }
  };
  return (
    <div className="rounded relative">
      <select
        id={id}
        name={name}
        className="peer bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
        placeholder=" "
        value={values[name] === "" ? 0 : values[name]}
        onChange={(e: any) => handleOnChange(e.target.value)}
        disabled={disabled}
      >
        <option disabled value="">
          Select
        </option>
        {list.map((elem: any) => (
          <option key={elem.id} value={elem.id}>
            {elem.title}
          </option>
        ))}
      </select>
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]}</p>
      )}
      <p className="text-xs text-blue-500 ">{helperText}</p>
      <label
        htmlFor={id}
        className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
};
type Props = {
  values: any;
  touched: any;
  setFieldValue: any;
  handleChange: any;
  errors: any;
  unitData: any;
  taxTypeDropdown: any;
  printerListsDropdown: any;
  modifierCategoryDropdown: any;
};

const PosScreenProductForm = (props: Props) => {
  const {
    values,
    touched,
    setFieldValue,
    handleChange,
    errors,
    unitData,
    taxTypeDropdown,
    printerListsDropdown,
    modifierCategoryDropdown,
  } = props;
  const [categoryDropdown, setCategoryDropdown] = useState();
  const [modCategoryDropdown, setModCategoryDropdown] = useState();
  const [subCategoryDropdown, setSubCategoryDropdown] = useState();
  const profitMargin = ((values?.sellingPrice - values?.costPrice) / values?.sellingPrice) * 100

  //Product Categories
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "category",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);
  //Product Categories
  const { data: modCategory } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modCategory?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setModCategoryDropdown(dropdownFormat);
  }, [modCategory]);
  const { data: subCategory } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "promotion",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    subCategory?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setSubCategoryDropdown(dropdownFormat);
  }, [subCategory]);
  return (
    <>
      <div className="w-full grid grid-cols-2 gap-4">
        <TextField
          name={"title"}
          label={"Title"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
        />
        <MultipleSelectField
          name={"category"}
          label={"Select on or more category"}
          titleLabel={"Category"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          options={categoryDropdown}
        />
        <DynamicDropDown
          name={"unit"}
          label={"Unit"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          list={unitData}
        />
        <TaxTableTypeDropDownField
          name={"taxType"}
          label={"Taxable Type"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          list={taxTypeDropdown}
        />
      </div>
      <div className="w-full grid grid-cols-3 gap-x-4 mb-4 mt-4">
        <CheckBoxField
          handleChange={(e: any) =>
            setFieldValue("isWeightable", e.target.checked)
          }
          name={"isWeightable"}
          values={values}
          label={"Weightable"}
        />
        <CheckBoxField
          handleChange={(e: any) =>
            setFieldValue("isOpenPriceProduct", e.target.checked)
          }
          name={"isOpenPriceProduct"}
          values={values}
          label={"OpenPriceProduct"}
        />
        <CheckBoxField
          handleChange={(e: any) =>
            setFieldValue("isModifierCategoryGrid", e.target.checked)
          }
          name={"isModifierCategoryGrid"}
          values={values}
          label={"ModifierCategory"}
        />
        <CheckBoxField
          handleChange={(e: any) =>
            setFieldValue("isScalable", e.target.checked)
          }
          name={"isScalable"}
          values={values}
          label={"Scalable"}
        />
        <CheckBoxField
          handleChange={(e: any) =>
            setFieldValue("displayInWebsite", e.target.checked)
          }
          name={"displayInWebsite"}
          values={values}
          label={"DisplayInWebsite"}
        />
      </div>
      <div className="w-full flex justify-between gap-4">
        <PriceField
          name={"costPrice"}
          label={"Cost Price"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
          helperText={""}
        />
        <PriceField
          name={"sellingPrice"}
          label={"Selling Price"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
          helperText={""}
        />
        <div className="relative w-full mb-6 rounded">
          <input id={"profits"} type="text" value={`${isNaN(profitMargin) ? 0 : `${(profitMargin).toFixed(2)}`}%`} disabled className="relative peer bg-gray-200 p-2 rounded w-full h-fit text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500" />
          <label
            htmlFor={"profits"}
            className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
          >
            Profit Margin
          </label>
        </div>
      </div>
      <MultipleSelectField
        name="printer"
        label={"Select on or more Printer"}
        titleLabel={"Printer"}
        values={values}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        options={printerListsDropdown}
      />
      <MultipleSelectField
        name={"modifierCategories"}
        titleLabel={"Modifier Category"}
        label={"Select on or more category"}
        values={values}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        options={modCategoryDropdown}
      />
      <div className="mb-4">
        <DynamicDropDown
          name={"subCategory"}
          label={"SubCategory"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          list={subCategoryDropdown}
        />
      </div>
      <div className="grid grid-cols-2 gap-10 ">
        <TextField
          name={"barcodeContent"}
          label={"Bar Code"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
        />
        <TextField
          name={"plu"}
          label={"PLU"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
        />
      </div>
      <div className="grid grid-cols-2 gap-10 ">
        <TextField
          name={"stock"}
          label={"Stock"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
        />
        <TextField
          name={"threshold"}
          label={"Threshold"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default PosScreenProductForm;
