import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { USER_LOGIN } from "../../../../../pages/Auth/graphql";

import Cleave from "cleave.js/react";
import {
  permissionTypeCache,
  permissionTypeVar,
} from "../../../../localstate/Cache";
import { GET_PERMISSION_TYPE } from "../../../../localstate/Cart";
import { setLocalKey } from "../../../../../helpers/sessionKey";

type Props = {
  permission: string;
  setPermPopup: any;
  handleCloseChange?: any;
};
export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const Login = (props: Props) => {
  const [enteredNumber, setEnteredNumber] = useState<any>("");
  const { permission, setPermPopup, handleCloseChange } = props;

  const [authToken, { reset }] = useMutation(USER_LOGIN);
  const handleLogin = async (loginCredentials: any) => {
    const response = await authToken({
      variables: loginCredentials,
    });
    if (response.data?.tokenAuth.success) {
      const permRes =
        response.data?.tokenAuth?.user?.profile?.user?.assignPermissions?.find(
          (user: { codename: string }) => user.codename === permission,
        );
      setLocalKey("authorizedBy", response.data?.tokenAuth?.user?.pk);
      if (permRes?.codename) {
        permissionTypeCache.writeQuery({
          query: GET_PERMISSION_TYPE,
          data: {
            permissionTypes: permissionTypeVar(permission),
          },
        });
        setPermPopup(false);
        handleCloseChange(permRes?.codename);
        // toast.info("Permission granted")
      } else {
        toast.error("Not Alloted Permissions");
      }
    } else if (response.data.tokenAuth.errors) {
      reset();
      toast.error(response.data.tokenAuth.errors.nonFieldErrors[0]?.message);
    }
  };

  useEffect(() => {
    if (enteredNumber?.length === 4) {
      handleLogin({ username: enteredNumber });
      setEnteredNumber("");
    }
  }, [enteredNumber?.length === 4]);

  const handleCalculate = (data: string) => {
    switch (data) {
      case "backspace":
        if (enteredNumber?.length - 1) {
          setEnteredNumber(
            enteredNumber
              ?.toString()
              .substring(0, enteredNumber?.toString().length - 1),
          );
        } else {
          setEnteredNumber("");
        }
        break;
      case "clear":
        setEnteredNumber("");
        break;
      default:
        setEnteredNumber(enteredNumber + data);
    }
  };

  return (
    <div className="relative w-full bg-[#e0e0e0]">
      <div className="w-[70%]  m-auto flex flex-wrap justify-between items-center p-20 rounded-md">
        <div>
          <Cleave
            type="password"
            name={"loginPin"}
            className="relative peer bg-[#FFFFFF] p-3  w-full text-gray-700 mt-2 mb-3 rounded"
            style={{
              boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
            }}
            placeholder={"Enter PIN "}
            value={enteredNumber}
            onChange={(e: any) => setEnteredNumber(e.target.value)}
            autoComplete="off"
            options={{
              numeral: true,
              numeralIntegerScale: 4,
              numeralPositiveOnly: true,
              delimiter: "",
              stripLeadingZeroes: false,
            }}
          />
          {/* <input type="text"  name="pincode" maxlength="4"  id="pin" pattern="^0[1-9]|[1-9]\d$" required/> */}
          <div className="grid grid-cols-3 gap-4">
            {numberPad.map((item: any) => (
              <div
                key={item.id}
                className="col-span-1 w-full  p-2  cursor-pointer bg-[#ffffff] text-[18px] text-center rounded items-center justify-center"
                style={{
                  boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
                }}
                onClick={() => handleCalculate(item.value)}
              >
                <span>{item.label}</span>
              </div>
            ))}
            <div
              className="col-span-2  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("0")}
            >
              <span>0</span>
            </div>
            <div
              className="col-span-1  p-2  cursor-pointer bg-[#ffffff] text-[18px]  font-normal text-center items-center justify-center rounded"
              style={{
                boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleCalculate("backspace")}
            >
              <span>x</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
