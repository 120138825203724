import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";
import RGL, { WidthProvider } from "react-grid-layout";
import _ from "lodash";
import classNames from "../../../utils/classNames";
// import FloorSwitchCase from "../../reusable/FloorSwitchCase";
const GridLayout = WidthProvider(RGL);

type Props = {
  setSelectedTableArray: any;
  selectedTableArray: any;
  tableId: string;
  tableData: any;
  isTableLoading: boolean;
  handleMergeTable: any;
};

const PosScreenMergeTable = (props: Props) => {
  const {
    selectedTableArray,
    setSelectedTableArray,
    tableId,
    tableData,
    isTableLoading,
    handleMergeTable,
  } = props;
  const tableLayout = JSON.parse(tableData?.floor?.layout || "[]");
  const tableFloor = tableData?.floor?.tablesByFloor?.edges || [];
  function getNamesOfCommonObjects(array: any, commonId: any) {
    return array
      .filter((item: any) => item?.mergeInstance === commonId)
      .map((item: any) => item?.pk);
  }
  const map = new Map(
    tableFloor?.map((item: any) => [item.node.pk, item.node])
  );
  const mergedArray = tableLayout.map((item: any) => {
    const node: any = map.get(item.tableId);
    return node ? { ...node, ...item } : item;
  });
  const handleTableClick = (pk: any, mergeId: any) => {
    const common = getNamesOfCommonObjects(
      mergedArray,
      mergeId === null ? undefined : mergeId
    ).filter((item: any) => item !== null && item !== undefined);

    if (selectedTableArray.includes(pk)) {
      setSelectedTableArray((prev: any) =>
        prev.filter((elem: string) => elem !== pk && !common.includes(elem))
      );
    } else {
      setSelectedTableArray((prev: any) => {
        const newArray = [
          ...prev,
          pk,
          ...common.filter((item: any) => !prev.includes(item)),
        ].filter((item: any) => item !== null && item !== undefined); // Filter out null or undefined
        return Array.from(new Set(newArray)); // Ensure there are no duplicates
      });
    }
  };
  const generateDOM = () => {
    return _.map(mergedArray, function (l, i: any) {
      const tableCount: any = mergedArray[i];
      const tableNode: any = mergedArray[i];
      let tableShapeImage: any;
      const color = tableNode?.mergedColorCode;
      const myComponentClassName = classNames(
        "relative flex justify-between cursor-pointer",
        tableNode && !tableNode?.isFunctional ? "pushBack" : ""
      );
      const pClassName = classNames(
        "absolute flex justify-center items-center w-full h-full text-center p-2 right-1 text-sm z-50",
        tableNode && tableNode?.isSelected ? "text-red-600" : "",
        tableNode?.status === "OCCUPIED" ? "text-white" : ""
      );
      return (
        <div
          key={i}
          data-grid={l}
          className={myComponentClassName}
          style={{
            border: `${tableNode?.isMerged ? `4px solid ${color}` : ""}`,
            borderColor: `${tableNode?.isMerged ? color : ""}`,
          }}
        >
          <div
            style={{
              border: `${
                selectedTableArray.includes((tableNode?.tableId).toString())
                  ? "4px solid #00BFFF"
                  : ""
              }`,
            }}
            className={"relative flex justify-between w-full"}
            onClick={() => {
              handleTableClick(tableNode?.tableId, tableNode?.mergeInstance);
            }}
          >
            {/* <FloorSwitchCase
              parameter={tableCount?.shape}
              tableShapeImage={tableShapeImage}
              one={false}
              color={tableNode?.status === "OCCUPIED" ? "#4338CA" : ""}
            /> */}
            {!tableCount?.isFunctional ? (
              tableCount?.shape === "textField" ? (
                <p>{tableNode?.title}</p>
              ) : (
                ""
              )
            ) : (
              <p className={pClassName}>{tableNode?.title}</p>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {isTableLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <GridLayout
            className="layout"
            style={{ height: "540px" }}
            cols={16}
            rowHeight={10}
            width={1200}
            measureBeforeMount={false}
            compactType={null}
            allowOverlap={true}
            isDroppable={false}
            containerPadding={[0, 0]}
            margin={[0, 0]}
            isBounded={true}
            isDraggable={false}
            isResizable={false}
          >
            {generateDOM()}
          </GridLayout>
          <div className="text-right mt-3">
            <button
              onClick={() => handleMergeTable()}
              className={`w-[10%] h-[50px] text-white  bg-[#4338CA] rounded mr-5`}
            >
              Merge
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default PosScreenMergeTable;
