import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
  mutation tokenAuth($username: String!) {
    tokenAuth(input: { username: $username }) {
      token
      success
      errors
      user {
        id
        pk
        email
        username
        fullName
        dateJoined
        profile {
          user {
            assignPermissions {
              name
              codename
            }
          }
        }
      }
    }
  }
`;
export const ATTENDANCE_LIST = gql`
  mutation ($startDate: String!, $endDate: String!) {
    filterAttendance(input: { startDate: $startDate, endDate: $endDate }) {
      errors
      success
      body
      headers
    }
  }
`;

export const SETUP_ATTENDANCE = gql`
  mutation ($user: String!, $pin: String!) {
    setupAttendancePin(input: { user: $user, pin: $pin }) {
      success
      errors
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    sendOtpResetPassword(input: { email: $email }) {
      success
      errors
      message
      otp
      clientMutationId
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation ($email: String!, $otp: String!) {
    verifyOtpResetPassword(input: { email: $email, otp: $otp }) {
      success
      errors
      message
      clientMutationId
    }
  }
`;
export const RESET_NEW_PASSWORD = gql`
  mutation (
    $email: String!
    $otp: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    forgetPassword(
      input: {
        usernameOrEmail: $email
        otp: $otp
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      success
      errors
      message
      clientMutationId
    }
  }
`;
