import { InMemoryCache, makeVar } from "@apollo/client";
import { getLocalKey } from "../../helpers/sessionKey";

//Search Initial State
export const searchSelectedItemsVar: any = makeVar({});
export const searchSelectedCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchSelectedItems: {
          read() {
            return searchSelectedItemsVar();
          },
        },
      },
    },
  },
});

//Search Initial State
export const searchItemsVar: any = makeVar({});
export const searchCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchItems: {
          read() {
            return searchItemsVar();
          },
        },
      },
    },
  },
});

//Select Category State
export const selectCategoryVar: any = makeVar({});
export const selectCategoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectCategory: {
          read() {
            return selectCategoryVar();
          },
        },
      },
    },
  },
});

export const qrCodeSearchVar: any = makeVar("");

//CartItems Initial State
export const cartItemsVar: any = makeVar([]);
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cartItems: {
          read() {
            return cartItemsVar();
          },
        },
      },
    },
  },
});

//Note Initial State
export const permissionTypeVar: any = makeVar("");
export const permissionTypeCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        noteItems: {
          read() {
            return permissionTypeVar();
          },
        },
      },
    },
  },
});
//Kitchen Print Initial State
export const orderPrintItemsVar: any = makeVar([]);
export const orderPrintItemsVarCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orderPrintItems: {
          read() {
            return orderPrintItemsVar();
          },
        },
      },
    },
  },
});

//TableItmes Initial State
export const tableItemsVar: any = makeVar({});
export const tableCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        tableItems: {
          read() {
            return tableItemsVar();
          },
        },
      },
    },
  },
});

//Order type Initial State
export const orderTypeVar: any = makeVar({});
export const orderTypeCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orderType: {
          read() {
            return orderTypeVar();
          },
        },
      },
    },
  },
});

//Split Pay Itmes Initial State
export const splitPayVar: any = makeVar([]);
export const splitPayCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        splitPay: {
          read() {
            return splitPayVar();
          },
        },
      },
    },
  },
});

//CustomerItmes Initial State
export const CustomerItemsVar: any = makeVar({});
export const customerCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        customerItems: {
          read() {
            return CustomerItemsVar();
          },
        },
      },
    },
  },
});
//Kitchen Order Initial State
export const kitchenOrderItemId: any = makeVar({});
export const kitchenOrdreCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        kitchenOrderId: {
          read() {
            return kitchenOrderItemId();
          },
        },
      },
    },
  },
});
//Modified Kitchen Order Initial State
export const modifiedKitchenOrderItemVar: any = makeVar({});
export const modifiedKitchenOrderCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        modifiedKitchenOrderId: {
          read() {
            return modifiedKitchenOrderItemVar();
          },
        },
      },
    },
  },
});

//Total Calculation Initial State
export const totalCalculationVar: any = makeVar({});
export const totalCalculationCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        totalCalculationItems: {
          read() {
            return totalCalculationVar();
          },
        },
      },
    },
  },
});

//Total Discount State
export const totalDiscountValueVar: any = makeVar({});
export const totalDiscountValueCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        totalCalculationItems: {
          read() {
            return totalDiscountValueVar();
          },
        },
      },
    },
  },
});

//Total Surcharge State
export const totalSurchargeValueVar: any = makeVar({});
export const totalSurchargeValueCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        totalCalculationItems: {
          read() {
            return totalSurchargeValueVar();
          },
        },
      },
    },
  },
});

//Total Surcharge State
export const kitchenCardItemValueVar: any = makeVar({});
export const kitchenCardItemValueCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        kitchenCardItemValue: {
          read() {
            return kitchenCardItemValueVar();
          },
        },
      },
    },
  },
});

//Note Initial State
export const noteVar: any = makeVar("");
export const noteCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        noteItems: {
          read() {
            return noteVar();
          },
        },
      },
    },
  },
});

//Note Initial State
export const rewardPointVar: any = makeVar("");
export const rewardPointCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        rewardPointItems: {
          read() {
            return rewardPointVar();
          },
        },
      },
    },
  },
});

// MyOrders State //////  QRCode My Orders
export const myOrdersVar: any = makeVar([]);
export const myOrdersCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        myOrderItems: {
          read() {
            return myOrdersVar();
          },
        },
      },
    },
  },
});

// QR cart code State //////  QRCode cart
export const qrCartVar: any = makeVar(
  JSON.parse(getLocalKey("qrCodeItems") || "[]"),
);
export const qrCartcache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        qrCart: {
          read() {
            return qrCartVar();
          },
        },
      },
    },
  },
});

// Price Manipulation  //////  QRCode cart
export const selectedTaxRateVar: any = makeVar(null);
export const calculatedSellingPriceVar: any = makeVar(null);
export const EditProductCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        taxRate: {
          read() {
            return selectedTaxRateVar();
          },
        },
        calculatedSellingPrice: {
          read() {
            return calculatedSellingPriceVar();
          },
        },
      },
    },
  },
});

export const selectedModifierTaxRateVar: any = makeVar(null);
export const calculatedModifierSellingPriceVar: any = makeVar(null);
export const EditModifierCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        taxRate: {
          read() {
            return selectedModifierTaxRateVar();
          },
        },
        calculatedSellingPrice: {
          read() {
            return calculatedModifierSellingPriceVar();
          },
        },
      },
    },
  },
});

export const searchCustomer = makeVar("");

//TableItmes Initial State
export const openTableVar: any = makeVar(false);
export const openTableCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        openTable: {
          read() {
            return openTableVar();
          },
        },
      },
    },
  },
});
//TableItmes Initial State
export const openKitchenAlertVar: any = makeVar(false);
export const openKitchenAlertCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        openTable: {
          read() {
            return openKitchenAlertVar();
          },
        },
      },
    },
  },
});
//TableItmes Initial State
export const openReportPopupVar: any = makeVar(false);
export const openReportPopupCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        openTable: {
          read() {
            return openReportPopupVar();
          },
        },
      },
    },
  },
});
export const disableFocusVar: any = makeVar(true);
export const disableFocusCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cartItems: {
          read() {
            return cartItemsVar();
          },
        },
      },
    },
  },
});

//Open product
export const openProductPopupVar: any = makeVar(false);
export const openProductPopupCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        openTable: {
          read() {
            return openProductPopupVar();
          },
        },
      },
    },
  },
});
