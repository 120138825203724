import TextField from "../../reusable/Forms/TextField";
import DynamicDropDown from "../../reusable/Forms/DynamicDropDown";
import MultipleSelectField from "../../reusable/Forms/MultipleSelectField";
import { IHTMLDropDownProps } from "../../reusable/Forms/interface";
import randomString from "../../../utils/randomString";
import { useEffect, useState } from "react";
import {
  EditModifierCache,
  selectedModifierTaxRateVar,
} from "../../localstate/Cache";
import { GET_EDIT_MODIFIER_TAX_RATE } from "../../localstate/EditProductState";
// import SingleImageUpload from "../../reusable/SingleImageUpload";
import TextAreaField from "../../reusable/Forms/TextAreaField";
import PriceUpdateList from "../../reusable/Forms/PriceListField";
import PriceField from "../../reusable/Forms/PriceField";
import { POS_SCREEN_PRODUCT_CATEGORY } from "../../../pages/Product/queries";
import { useQuery } from "@apollo/client";

const TaxTableTypeDropDownField: React.FC<IHTMLDropDownProps> = (props) => {
  const {
    name,
    label,
    values,
    touched,
    errors,
    setFieldValue,
    list,
    disabled = false,
    helperText,
  } = props;
  const id = randomString(10, "-drop-down-field");

  const handleOnChange = (value: string) => {
    if (value === "0") {
      setFieldValue(name, "");
    } else {
      setFieldValue(name, value);
    }
  };

  useEffect(() => {
    const item = list.find((item: any) => item.id == values[name]);
    EditModifierCache.writeQuery({
      query: GET_EDIT_MODIFIER_TAX_RATE,
      data: {
        taxRate: selectedModifierTaxRateVar(item),
      },
    });
  }, [values, list]);
  return (
    <div className="rounded relative">
      <select
        id={id}
        name={name}
        className="peer bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
        placeholder=" "
        value={values[name] === "" ? 0 : values[name]}
        onChange={(e: any) => handleOnChange(e.target.value)}
        disabled={disabled}
      >
        <option disabled value="">
          Select
        </option>
        {list.map((elem: any) => (
          <option key={elem.id} value={elem.id}>
            {elem.title}
          </option>
        ))}
      </select>
      {Boolean(touched[name] && errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]}</p>
      )}
      <p className="text-xs text-blue-500 ">{helperText}</p>
      <label
        htmlFor={id}
        className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
};
const PosScreenModifierForm = (props: any) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    categoryDropdown,
    unitData,
    handleBlur,
    taxTypeDropdown,
  } = props;
  const [modCategoryDropdown, setModCategoryDropdown] = useState();
  const { data: modCategory } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier",
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modCategory?.categories?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      }),
    );
    setModCategoryDropdown(dropdownFormat);
  }, [modCategory]);
  return (
    <div className="mb-4 p-4 bg-slate-100">
      <div className="grid grid-cols-2 gap-4">
        <TextField
          name="title"
          label={"Modifier Name"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <MultipleSelectField
          name={"category"}
          label={"Select one or more category"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          options={modCategoryDropdown}
          onBlur={handleBlur}
        />
        <DynamicDropDown
          name="unit"
          label={"Unit"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          list={unitData}
          
        />

        <TaxTableTypeDropDownField
          name={"taxType"}
          label={"Taxable Type"}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          list={taxTypeDropdown}
        />
      </div>
      <div className="mt-5">
        <PriceField
          name={"price"}
          label={"Price"}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          onChange={handleChange}
          helperText={""}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};
export default PosScreenModifierForm;
