import { useQuery, useReactiveVar } from "@apollo/client";
import Loadable from "react-loadable";
import { useNavigate } from "react-router";
import {
  customerCache,
  CustomerItemsVar,
  searchItemsVar,
} from "../../../components/localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../../components/localstate/Cart";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import usePosScreenHandleClear from "../../../customhooks/usePosScreenHandleClear";
import { POS_SCREEN_CUSTOMER_LIST } from "../queries";
import AccessControl from "../../../helpers/accessControl";
import UserPermissionRequired from "../../Error/UserPermisssionRequired";
import TableLoader from "../../../components/reusable/Loader/TableLoader";

const PosScreenCustomerListComponent: any = Loadable({
  loader: () =>
    import(
      "../../../components/PosScreenType/AustraliaPosScreen/PosScreenCartSection/PosScreenCustomerSection/PosScreenCustomerList"
    ),
  loading: () => <TableLoader />,
});
const PosScreenCustomer = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const navigate = useNavigate();

  const { handleClearPosScreen } = usePosScreenHandleClear();

  const { data, loading: customerLoading } = useQuery(
    POS_SCREEN_CUSTOMER_LIST,
    {
      variables: {
        first: pageSize,
        offset: offset,
        search: searchItems?.searchInput,
      },
      fetchPolicy: "network-only",
    },
  );

  const handleNavigateToPos = (value: any, state: String) => {
    if (state !== "fromOrderTypeMustSelectCustomerCondition") {
      handleClearPosScreen();
    }

    let customerItem = {
      id: value?.id,
      name: value?.user?.fullName,
      pk: value?.pk,
    };
    customerCache.writeQuery({
      query: GET_CUSTOMER_ITEMS,
      data: {
        customerItems: CustomerItemsVar(customerItem),
      },
    });
    navigate("/posscreen");
  };

  return (
    <AccessControl
      allowedPermissions={["can_view_customer"]}
      renderNoAccess={<UserPermissionRequired />}
      children={
        <div className="w-full">
          <PosScreenCustomerListComponent
            data={data?.customers ?? []}
            setPageSize={setPageSize}
            pageSize={pageSize}
            customerLoading={customerLoading}
            offset={offset}
            setOffset={setOffset}
            handleNavigateToPos={handleNavigateToPos}
          />
        </div>
      }
    />
  );
};

export default PosScreenCustomer;
