import { useEffect, useState } from "react";
import PosScreenProductForm from "./PosScreenTableSection/PosScreenProductForm";
import Modal from "../../../reusable/Modal";
import EditIcon from "../../../../assets/components/Edit";
import AccessControl from "../../../../helpers/accessControl";
import Login from "./PermissionVerify/Login";
import DeleteIcon from "../../../../assets/components/Delete";

type Props = {
  cartItem?: any;
  handleRemoveProductFromCart?: any;
  index?: any;
  ref?: any;
  onRightClick?: any;
  permissionTypes: any;
};
export default function PosScreenPurchaseList(props: Props) {
  const {
    cartItem,
    handleRemoveProductFromCart,
    index,
    onRightClick,
    permissionTypes,
  } = props;
  const [open, setOpen] = useState(false);
  const [permissionType, setPermissionType] = useState("");
  const [permPopup, setPermPopup] = useState(false);
  const promoCompoundList =
    cartItem?.compound?.map((item: any) => parseFloat(item?.sellingPrice)) ||
    [];
  let totalPromoCompoundPrice = promoCompoundList?.reduce(
    (a: any, b: any) => a + b,
    0,
  );
  const totalPromoValue = (
    totalPromoCompoundPrice.toFixed(2) - parseFloat(cartItem?.total)
  ).toFixed(2);

  return (
    <>
      {cartItem?.compound ? (
        <>
          <td onContextMenu={() => onRightClick(cartItem, index)}>
            <span title={cartItem.title} className="ml-2">
              --{cartItem.title}--
            </span>
          </td>
          <td>
            <span className="ml-2">-{totalPromoValue}</span>
          </td>
          <td>
            <span
              className="flex items-center  justify-center cursor-pointer"
              onClick={(e) => handleRemoveProductFromCart(e, index, cartItem)}
            >
              <DeleteIcon
                fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"}
              />
            </span>
          </td>
        </>
      ) : (
        <>
          <td
            // ref={ref}
            style={{
              borderRight: "solid white thin",
              padding: "2px",
              width: "70%",
              position: "relative",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            onContextMenu={() => onRightClick(cartItem, index)}
          >
            <span title={cartItem.title}>
              <span className="font-semibold text-xs italic">
                <span
                  className={`${cartItem.isSelected ? "text-white" : ""
                    } text-lg`}
                >
                  {parseFloat(cartItem.quantity).toFixed(3)}
                </span>
                <span className="p-1">X</span>
                <span className="p-1 font-bold">
                  {parseFloat(cartItem.price).toFixed(2)}
                </span>
              </span>
              {cartItem.title}
            </span>
          </td>
          <td
            style={{
              borderRight: "solid white thin",
              padding: "2px",
              width: "15%",
              fontWeight: "bold",
            }}
          >
            {parseFloat(cartItem.total).toFixed(2)}
          </td>
          <td
            style={{
              borderRight: "solid white thin",
              padding: "2px",
              width: "10%",
            }}
          >
            {permissionTypes === "can_edit_cart_item" ? (
              <span className="cursor-pointer" onClick={() => setOpen(true)}>
                <EditIcon
                  fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"}
                />
              </span>
            ) : (
              <AccessControl
                allowedPermissions={["can_edit_cart_item"]}
                renderNoAccess={
                  <div
                    onClick={() => {
                      setPermissionType("can_edit_cart_item");
                      setPermPopup(true);
                    }}
                  >
                    {permPopup ? (
                      <></>
                    ) : (
                      <span className="cursor-pointer">
                        <EditIcon
                          fillColor={
                            cartItem.isSelected ? "#FFFFFF" : "#323232"
                          }
                        />
                      </span>
                    )}
                  </div>
                }
                children={
                  <span
                    className="cursor-pointer"
                    onClick={() => setOpen(true)}
                  >
                    <EditIcon
                      fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"}
                    />
                  </span>
                }
              />
            )}
          </td>
        </>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        title={"Update Product"}
        children={
          <PosScreenProductForm
            cartItem={cartItem}
            setOpen={setOpen}
            itemToDeleteIndex={index}
            handleRemoveProductFromCart={handleRemoveProductFromCart}
          />
        }
      />
      {/* Permission Grant Madal*/}
      <Modal title={"Alert"} open={permPopup} setOpen={setPermPopup}>
        <p className="py-4"> You don't have permission for the change </p>
        <Login permission={permissionType} setPermPopup={setPermPopup} />
      </Modal>
    </>
  );
}
