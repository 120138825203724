import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { useState } from "react";
import {
  cache,
  cartItemsVar,
  disableFocusCache,
  disableFocusVar,
  searchCache,
  searchItemsVar,
  searchSelectedItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_DISABLE_FOCUS,
  GET_SEARCH_ITEMS,
} from "../../localstate/Cart";
import { toast } from "react-toastify";
import Modal from "../../reusable/Modal";
import PosScreenProWithModCat from "../PosScreenProWithModCat";
import { Form, Formik } from "formik";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
import WeightableProductQuantityForm from "./WeightableProductQuantityForm";
import { setLocalKey } from "../../../helpers/sessionKey";
import OpenPriceProductPriceForm from "./OpenPriceProductPriceForm";
import { POS_SCREEN_CREATE_PRODUCT } from "../../../pages/Product/mutation";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import PosScreenProductForm from "./PosScreenProductForm";
import { CURRENT_TERMINAL } from "../../../pages/PosScreen/queries";
import { posScreenQuickProductFormValidationSchema } from "../../../utils/formValidation";

const PosScreenSearchProductTab = (props: any) => {
  const {
    searchProductData,
    taxTypeDropdown,
    unitDropdown,
    printerListsDropdown,
    modifierCategoryDropdown,
    noOfColumns,
  } = props;

  const cartItems: [] = useReactiveVar(cartItemsVar);
  const [productWithModCategoryList, setProductWithModCategoryList] = useState(
    []
  );
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL);

  const [scaleValues, setScaleValues] = useState();
  const [enteredNumber, setEnteredNumber] = useState("");
  const [openProductWithModCategory, setOpenProductWithModCategory] =
    useState<boolean>(false);
  const enableWightScale: any =
    currentTerminalData?.currentTerminal?.terminalsetting?.isActiveScale;
  const [isWeightableProduct, setIsWeightableProduct] =
    useState<boolean>(false);
  const [isScaleNotConnected, setIsScaleNotConnected] =
    useState<boolean>(false);
  const [scaleErrorMessage, setScaleErrorMessage] = useState<string>();
  const [productSingle, setProductSingle] = useState("");
  const scaleInitialValues = {
    baudRate:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleBaudRate,
    dataBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleDateBits,
    parity: currentTerminalData?.currentTerminal?.terminalsetting?.scaleParity,
    port: currentTerminalData?.currentTerminal?.terminalsetting?.scalePort,
    stopBits:
      currentTerminalData?.currentTerminal?.terminalsetting?.scaleStopBits,
  };
  const [isOpenPriceProducts, setIsOpenPriceProducts] =
    useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const scaleValue = JSON.stringify(scaleInitialValues);

  const handlePromoItem = (updateNewItemList: any, newItem: any) => {
    let finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
    const promoInfo = newItem?.promotionInfo;
    const categoryInfo = newItem?.categoryPromotionInfo;
    let disableCart = false;
    let match: boolean = false;
    let promotionList: any = [];
    let promotion: any = [];
    let applicablePromotion: any = [];
    if (promoInfo.length) {
      for (
        let promoInfoIndex = 0;
        promoInfoIndex < promoInfo.length;
        promoInfoIndex++
      ) {
        const promotionItem = promoInfo[promoInfoIndex];
        if (promotionItem.compound.length) {
          const everyItemExists = promotionItem.compound.every((item: any) =>
            finalCartItems.some((elem: any) => elem.id === item.id)
          );
          if (everyItemExists) {
            promotionList.push(promotionItem);
            applicablePromotion.push(promotionItem?.compound);
          }
        }
      }
    }
    if (applicablePromotion.length > 0) {
      for (let index = 0; index < applicablePromotion.length; index++) {
        const element = applicablePromotion[index];
        for (let itemIndex = 0; itemIndex < element.length; itemIndex++) {
          let item = element[itemIndex];
          let foundItemIndex = finalCartItems.findIndex(
            (cartItem: any) => cartItem.id == item.id && cartItem.quantity > 0
          );
          if (foundItemIndex >= 0) {
            finalCartItems[foundItemIndex]["quantity"] -= 1;
            finalCartItems[foundItemIndex]["total"] =
              finalCartItems[foundItemIndex]["quantity"] *
              parseFloat(finalCartItems[foundItemIndex]["price"]);
            match = true;
          } else {
            match = false;
          }
        }
        if (match) {
          promotion = { ...promotionList[index] };
          break;
        } else {
          finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
        }
      }
    }
    if (match) {
      let productInCartList: any = {
        id: promotion?.id,
        pk: promotion?.pk,
        title: promotion?.title,
        quantity: 1,
        price: promotion?.sellingPrice,
        total: promotion?.sellingPrice,
        tax: promotion?.taxAmount ?? "0.00",
        taxRate: {
          rate: promotion?.tax?.rate ?? 0,
          isIncluded: promotion?.tax?.isIncluded,
        },
        isSelected: false,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierCategory: promotion?.modifierCategory,
        compound: promotion?.compound,
      };
      let finalItems = finalCartItems?.filter((item: any) => item.quantity > 0);
      toast.success("Promotion Found");
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([productInCartList, ...finalItems]),
        },
      });
    } else {
      if (categoryInfo?.length) {
        categoryInfo.forEach((promotion: any) => {
          let promotionFound = false;
          promotion.possibleCombinations.forEach(
            (promotionCombination: any) => {
              let combinationMatch = true;
              promotionCombination.forEach((combinationItem: any) => {
                const isExistIndex = finalCartItems.findIndex(
                  (filterCartItem: any) =>
                    filterCartItem.pk === combinationItem?.pk &&
                    filterCartItem.quantity > 0
                );
                if (isExistIndex > -1) {
                  finalCartItems[isExistIndex]["quantity"] -= 1;
                  finalCartItems[isExistIndex]["total"] =
                    finalCartItems[isExistIndex]["quantity"] *
                    parseFloat(finalCartItems[isExistIndex]["price"]);
                } else {
                  combinationMatch = false;
                }
              });
              if (combinationMatch) {
                if (!promotionFound) {
                  toast.success("Promotion found");
                  promotionFound = true;
                }
                disableCart = true;
                let productInCartList: any = {
                  id: promotion?.id,
                  pk: promotion?.pk,
                  title: promotion?.title,
                  quantity: 1,
                  price: promotion?.sellingPrice,
                  total: promotion?.sellingPrice,
                  tax: promotion?.taxAmount ?? "0.00",
                  taxRate: {
                    rate: promotion?.tax?.rate ?? 0,
                    isIncluded: promotion?.tax?.isIncluded,
                  },
                  isSelected: false,
                  isDeleted: false,
                  modifier: [],
                  isModified: true,
                  modifierCategory: promotion?.modifierCategory,
                  compound: promotionCombination,
                };
                let finalItems = finalCartItems?.filter(
                  (item: any) => item.quantity > 0
                );
                cache.writeQuery({
                  query: GET_CART_ITEMS,
                  data: {
                    cartItems: cartItemsVar([productInCartList, ...finalItems]),
                  },
                });
                return;
              } else {
                // Reset finalCartItems if the combination doesn't match
                finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
              }
            }
          );
          if (promotionFound) {
            return; // Exit the outer loop if a promotion is found
          }
        });
      } else {
      }
      // finalCartItems = JSON.parse(JSON.stringify(updateNewItemList));
      if (!disableCart) {
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([...updateNewItemList]),
          },
        });
      }
    }
  };
  const handleClick = (item: any) => {
    if (item?.isWeightable) {
      if (item?.isOpenPriceProduct) {
        setIsOpenPriceProducts(true);
        setItem(item);
      }
      if (!enableWightScale) {
        setItem(item);
        setIsWeightableProduct(true);
      } else {
        setItem(item);
      }
      setLocalKey("disableRemotePrint", "false");
    } else if (item?.isOpenPriceProduct) {
      setIsOpenPriceProducts(true);
      setItem(item);
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        title: item?.title,
        quantity: 1,
        price: item?.finalPrice ?? 0,
        total: item?.finalPrice ?? 0,
        tax: item?.taxAmount,
        taxRate: {
          rate: item?.tax?.rate ?? 0,
          isIncluded: item?.tax?.isIncluded,
        },
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        promoInfo: item?.promotionInfo ?? [],
        discount: "",
        modifierCategory: item?.modifierCategories,
        productId: item?.id,
      };
      let previousObject: any = cartItems.find(
        (x: any) => x.id === item?.id && x.isSelected === true
      );

      if (previousObject && previousObject.isSelected) {
        let previousItem = { ...previousObject };
        let newItemList: any = cartItems;
        let foundIndex: any = cartItems.findIndex(
          (x: any) => x.id === item?.id && x.isSelected === true
        );
        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseInt(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].tax =
          parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);
        newItemList[foundIndex].total =
          parseFloat(previousItem.total) / parseFloat(previousItem.quantity) +
          parseFloat(previousItem.total);
        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                  ...item,
                  isSelected: false,
                }
              : item
        );
        handlePromoItem(updateNewItemList, item);
        const searchList = {
          searchInput: "",
          name: "",
        };
        searchCache.writeQuery({
          query: GET_SEARCH_ITEMS,
          data: {
            searchItems: searchItemsVar(searchList),
          },
        });
        setLocalKey("disableRemotePrint", "false");
      } else {
        let nonPreviousItemList = cartItems?.map(
          (item: any, index: number) => ({
            ...item,
            isSelected: false,
          })
        );
        handlePromoItem([productInCartList, ...nonPreviousItemList], item);
        const searchList = {
          searchInput: "",
          name: "",
        };
        searchCache.writeQuery({
          query: GET_SEARCH_ITEMS,
          data: {
            searchItems: searchItemsVar(searchList),
          },
        });
        setLocalKey("disableRemotePrint", "false");
      }
    }
  };

  const handleModifierClick = (item: any) => {
    if (!item) {
      toast.info("Please select non-empty modifiers");
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === item?.id
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === item?.id
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) + parseFloat(previousObject.total)
              : 0;
          perviousModifierItemList[itemIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        } else {
          let productInCartList: any = cartItems;
          prevModifierList.push({
            id: item?.id,
            pk: item?.pk,
            contentTypeId: item?.contentTypeId,
            title: item?.title,
            price: item?.finalPrice ?? 0,
            quantity: 1,
            total: item?.finalPrice ?? 0,
            tax: item?.taxAmount,
            taxRate: {
              rate: item?.tax?.rate ?? 0,
              isIncluded: item?.tax?.isIncluded,
            },
            isDeleted: false,
            randId: Math.random().toString(36).substr(2, 5),
          });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        }
      } else {
        if (item) {
          toast.info("Please select product");
        } else {
          toast.info("Please select non-empty modifiers");
        }
      }
    }
  };

  const handleProductWithModCategory = (item: any) => {
    setOpenProductWithModCategory(true);
    setProductSingle(item?.id);
    setProductWithModCategoryList(item);
  };
  const searchSelectedItems: any = useReactiveVar(searchSelectedItemsVar);

  const handleProductClick = async (item: any) => {
    if (item)
      if (item?.productType !== "modifier") {
        if (item?.modifierCategories?.edges?.length) {
          handleProductWithModCategory(item);
        } else {
          handleClick(item);
        }
      } else {
        handleModifierClick(item);
      }
  };
  //Add quick Product
  const [addProductPopup, setAddProductPopup] = useState<boolean>(false);
  const productInitialValues = {
    title: "",
    category: [],
    unit: unitDropdown[0]?.id,
    costPrice: 0,
    subCategory: "",
    sellingPrice: "",
    taxType: taxTypeDropdown[0]?.id,
    barcode: "",
    plu: "",
    printer: [],
    isWeightable: false,
    modifierCategories: [],
    isOpenPriceProduct: false,
    isModifierCategoryGrid: false,
  };

  //Handle Quick Add Product
  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT
  );
  const handleCreateProduct = async (values: any, props: any) => {
    const response = await productCreate({
      variables: {
        // posScreenProductId: productId.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        tax: values?.taxType === "0" ? null : values?.taxType,
        isWeightable: values?.isWeightable,
        stock: values?.stock ?? 0,
        threshold: values?.threshold ?? 0,
        promotionCategory: values?.subCategory,
        printOrder: null,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategories ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      setAddProductPopup(false);
      let productInCartList = {
        id: response?.data?.createProduct?.product?.id,
        pk: response?.data?.createProduct?.product?.pk,
        contentTypeId: response?.data?.createProduct?.product?.contentTypeId,
        title: response?.data?.createProduct?.product?.title,
        quantity: 1,
        price: response?.data?.createProduct?.product?.finalPrice ?? 0,
        total: response?.data?.createProduct?.product?.finalPrice ?? 0,
        tax: response?.data?.createProduct?.product?.taxAmount,
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
      };

      let nonPreviousItemList = cartItems?.map((item: any, index: number) => ({
        ...item,
        isSelected: false,
      }));
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([productInCartList, ...nonPreviousItemList]),
        },
      });
      const searchList = {
        searchInput: "",
        name: "",
      };
      searchCache.writeQuery({
        query: GET_SEARCH_ITEMS,
        data: {
          searchItems: searchItemsVar(searchList),
        },
      });
      disableFocusCache.writeQuery({
        query: GET_DISABLE_FOCUS,
        data: {
          disableFocus: disableFocusVar(true),
        },
      });
    } else {
      const errors = backendErrorDisplay(response?.data?.createProduct?.errors);
      props.setErrors(errors);
    }
  };

  const handleNavigate = () => {
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };

  return (
    <>
      {searchProductData?.products?.edges?.length ? (
        <div
          className={`grid w-full grid-cols-${noOfColumns} p-4 grid-rows-7 gap-2 border-2`}
        >
          {searchProductData?.products?.edges?.map(
            (item: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  id={`selectedItem_${index}`}
                  className={`relative h-[80px] cursor-pointer border-2 border-solid border-gray-300 rounded-lg
                  ${
                    searchSelectedItems?.index === index
                      ? "border-red-600"
                      : "border-black-500"
                  }
                  `}
                  // style={{ background: item?.node?.title ? item?.node?.color ?? "#E9B634"  : "#F3F4F6"}}
                  onClick={() => handleProductClick(item?.node)}
                >
                  {item?.node?.image ? (
                    <>
                      <img
                        src={item?.node?.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <span
                        title={item?.node?.title}
                        className={`${
                          item?.node?.productType !== "modifier"
                            ? ""
                            : "text-red-500"
                        } textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs  text-left px-1`}
                      >
                        {item?.node?.title}
                      </span>
                    </>
                  ) : (
                    <div>
                      <img
                        src={item?.node?.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <span
                        title={item?.node?.title}
                        className={`${
                          item?.node?.productType !== "modifier"
                            ? ""
                            : "text-red-500"
                        } textWithTwoLine absolute top-[20%] w-full bg-gray-200  text-left font-bold px-1`}
                      >
                        {item?.node?.title}
                      </span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
          )}
        </div>
      ) : (
        <div className="px-6 shadow bg-[#ffffff]">
          <div className="text-lg font-semibold text-black text-center p-4">
            Add Product
          </div>
          <Formik
            initialValues={productInitialValues}
            validationSchema={posScreenQuickProductFormValidationSchema}
            onSubmit={handleCreateProduct}
          >
            {({ touched, values, setFieldValue, errors, handleChange }) => (
              <Form>
                <PosScreenProductForm
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  unitData={unitDropdown ?? []}
                  taxTypeDropdown={taxTypeDropdown ?? []}
                  printerListsDropdown={printerListsDropdown ?? []}
                  modifierCategoryDropdown={modifierCategoryDropdown ?? []}
                />
                <div className="flex flex-wrap justify-between mb-4 p-4">
                  <PrimaryButton
                    type={"button"}
                    onClick={handleNavigate}
                    text={"Back"}
                    loading={false}
                    disable={false}
                  />
                  <PrimaryButton
                    text={"Submit"}
                    loading={loadingCreateProduct ? true : false}
                    disable={loadingCreateProduct}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <Modal
        title={"Select Modifier"}
        open={openProductWithModCategory}
        setOpen={setOpenProductWithModCategory}
        size="max-w-7xl"
        children={
          <PosScreenProWithModCat
            item={productWithModCategoryList}
            setOpen={setOpenProductWithModCategory}
            open={openProductWithModCategory}
            productSingle={productSingle}
          />
        }
      />

      <Modal
        title={"Add Quantity"}
        open={isWeightableProduct}
        setOpen={setIsWeightableProduct}
        size="max-w-sm"
      >
        <WeightableProductQuantityForm
          item={item}
          cartItems={cartItems}
          setIsWeightableProduct={setIsWeightableProduct}
          setScaleValue={setScaleValues}
          setEnteredNumber={setEnteredNumber}
          enteredNumber={enteredNumber}
          isOpenPriceProducts={isOpenPriceProducts}
        />
      </Modal>

      <Modal
        open={isScaleNotConnected}
        setOpen={() => {
          setIsScaleNotConnected(false);
        }}
        children={<>{scaleErrorMessage}</>}
        title={JSON.stringify(scaleErrorMessage)}
      />
      {!isWeightableProduct && (
        <Modal
          title={"Add Price"}
          open={isOpenPriceProducts}
          setOpen={setIsOpenPriceProducts}
          size="max-w-sm"
        >
          <OpenPriceProductPriceForm
            item={item}
            cartItems={cartItems}
            setIsOpenPriceProducts={setIsOpenPriceProducts}
            scaleValues={scaleValues}
            enteredQauntity={enteredNumber}
          />
        </Modal>
      )}
    </>
  );
};

export default PosScreenSearchProductTab;
