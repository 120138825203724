import {
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import {
  CURRENT_TERMINAL,
  POS_SCREEN_CUSTOMER_LIST_BY_ID,
  POS_SCREEN_KITCHEN_ORDER_ITEMS,
} from "../../../../pages/PosScreen/queries";

import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
  disableFocusCache,
  disableFocusVar,
  kitchenOrderItemId,
  noteVar,
  orderTypeCache,
  orderTypeVar,
  permissionTypeVar,
} from "../../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_DISABLE_FOCUS,
  GET_ORDER_TYPE,
} from "../../../localstate/Cart";
import PosScreenCustomerSection from "./PosScreenCustomerSection/PosScreenCustomerSection";
import PosScreenQuickAccessTool from "./PosScreenQuickAccessTool";
import PosScreenTable from "./PosScreenTableSection/PosScreenTable";
import PosScreenTotalSection from "./PosScreenTotalSection";
import { REWARD_SETTING_POINT } from "../../../../pages/queries";

type Props = {};

export default function PosScreenCartSection(props: Props) {
  //local reactive state variables
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const permissionTypes: any[] = useReactiveVar(permissionTypeVar);
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const noteItems: any = useReactiveVar(noteVar);
  const [checked, setChecked] = useState<boolean>(false);

  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  const [holdCardItemData, setHoldCardItemData] = useState({});
  //Get HoldCard Lists
  const [getHoldCardItem] = useLazyQuery(POS_SCREEN_KITCHEN_ORDER_ITEMS, {
    fetchPolicy: "cache-and-network",
  });

  const getHoldOrderItems = async () => {
    const response = await getHoldCardItem({
      variables: {
        id: kitchenOrdreItemId?.kitchenOrderPk?.id,
      },
    });
    let customerItems = {
      id: response?.data?.kitchenOrder?.customer?.id ?? "",
      name: `${response?.data?.kitchenOrder?.customer?.user?.fullName ?? ""}`,
      pk: response?.data?.kitchenOrder?.customer?.pk ?? "",
    };
    if (response?.data?.kitchenOrder?.customer) {
      customerCache.writeQuery({
        query: GET_CUSTOMER_ITEMS,
        data: {
          customerItems: CustomerItemsVar(customerItems),
        },
      });
    }
    if (response?.data?.kitchenOrder?.orderType) {
      const ordeTypeList = {
        pk: response?.data?.kitchenOrder?.orderTypePk,
        title: response?.data?.kitchenOrder?.orderType?.deliveryMethod,
      };
      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar(ordeTypeList),
        },
      });
    }
    setHoldCardItemData(response?.data);
  };
  useEffect(() => {
    if (kitchenOrdreItemId?.kitchenOrderPk?.id) {
      getHoldOrderItems();
    }
  }, [kitchenOrdreItemId?.kitchenOrderPk?.id]);

  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL, {
    fetchPolicy: "network-only",
  });
 
  const handleTableBodyClicked = (e: any) => {
    let editedProductList = cartItems.map((item: any, itemIndex: number) => ({
      ...item,
      isSelected: false,
    }));
    cache.writeQuery({
      query: GET_CART_ITEMS,
      data: {
        cartItems: cartItemsVar(editedProductList),
      },
    });
  };

  const availablePointsCheckboxRef: any = useRef();
  const { data: selectedCustomer } = useQuery(POS_SCREEN_CUSTOMER_LIST_BY_ID, {
    variables: {
      id: customerItems?.id ?? "",
    },
  });
  const { data: rewardPointSettingData } = useQuery(REWARD_SETTING_POINT);
  const handleDisable = () => {
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };
  const rewardPointSettingPoint: any =
    parseFloat(rewardPointSettingData?.rewardPointSetting?.amount) /
    parseFloat(rewardPointSettingData?.rewardPointSetting?.point);
  return (
    <>
      <div
        className="flex flex-col h-full mx-0 justify-between w-full"
        onClick={handleDisable}
      >
        <div className="h-[10%]">
          <PosScreenCustomerSection
            customerItems={customerItems}
            cartItems={cartItems}
            permissionTypes={permissionTypes}
          />
        </div>
        <div
          className="rounded-lg h-[40%]  overflow-y-auto"
          onClick={(e) => handleTableBodyClicked(e)}
        >
          <div onClick={(e) => e.stopPropagation}>
            <PosScreenTable
              cartItems={cartItems}
              holdCardItemData={holdCardItemData}
              permissionTypes={permissionTypes}
            />
          </div>
        </div>
        <div className=" h-[25%]">
          <hr className="my-2"></hr>
          <p className="text-[12px] text-left py-0">Note*: {noteItems} </p>
          <hr className="my-1"></hr>
          <PosScreenTotalSection
            cartItems={cartItems}
            selectedTerminal={currentTerminalData?.currentTerminal}
            customerItems={customerItems}
            availablePointsCheckboxRef={availablePointsCheckboxRef}
            availableRewardPoints={selectedCustomer?.customer?.rewardPoints ?? 0.00}
            rewardPointSettingPoint={parseFloat(
              rewardPointSettingPoint,
            ).toFixed(2)}
            isRewardPointEnabled={
              rewardPointSettingData?.rewardPointSetting?.isActive
            }
            rewardPointSettingData={rewardPointSettingData}
            checked={checked}
            setChecked={setChecked}
          />
        </div>
        <hr className="my-3"></hr>
        <div className="h-[25%] w-full">
          <PosScreenQuickAccessTool />
        </div>
      </div>
    </>
  );
}
