import { gql } from "@apollo/client";
export const POS_SCREEN_COMPOUND_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $productType: String
  ) {
    products(
      first: $first
      after: $after
      before: $before
      title: $search
      offset: $offset
      category: $categoryList
      productType: $productType
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          barcodeContent
          productStocks {
            availableQuantity
          }
          plu
          isActive

          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          isPromotionByCategory
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image

          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $productType: String
    $isOpenProduct: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      title: $search
      offset: $offset
      category: $categoryList
      productType: $productType
      isOpenProduct: $isOpenProduct
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          plu
          productStocks {
            availableQuantity
          }
          barcodeContent
          isActive
          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const PRODUCT_LIST = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isOpenProduct: Boolean
    $productType: String
    $barcodeContent_Isnull: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      isOpenProduct: $isOpenProduct
      productType: $productType
      barcodeContent_Isnull: $barcodeContent_Isnull
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          pk
          id
          title
          barcodeContent
          tax {
            id
            pk
            title
            rate
          }
          finalPrice
          sellingPrice
          costPrice
          image
          productStocks {
            availableQuantity
            threshold
          }
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const STOCK_PRODUCT_LIST = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isOpenProduct: Boolean
    $productType: String
    $barcodeContent_Isnull: Boolean
    $productStocks_AvailableQuantity_Lte: Float
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      isOpenProduct: $isOpenProduct
      productType: $productType
      barcodeContent_Isnull: $barcodeContent_Isnull
      productStocks_AvailableQuantity_Lte: $productStocks_AvailableQuantity_Lte
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          pk
          id
          title
          barcodeContent
          tax {
            id
            pk
            title
            rate
          }
          finalPrice
          sellingPrice
          costPrice
          image
          productStocks {
            availableQuantity
            threshold
          }
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const POS_SCREEN_SINGLE_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      pk
      id
      title
      plu
      productStocks {
        availableQuantity
        threshold
      }
      isScalable
      barcodeContent
      isActive
      isModifierCategoryGrid
      isPromotionByCategory
      isOpenPriceProduct
      displayInWebsite
      tax {
        id
        pk
        title
        isIncluded
        rate
      }
      isWeightable
      printOrder {
        pk
        name
      }
      shortDesc
      longDesc
      finalPrice
      sellingPrice
      costPrice
      image
      promotionCategory {
        id
        pk
        name
      }
      promotionCategories {
        category {
          id
          pk
          name
        }
      }

      unit {
        id
        pk
        title
      }
      compound {
        title
        pk
        id
        finalPrice
      }
      printer {
        edges {
          node {
            pk
            id
            name
          }
        }
      }
      modifierCategories {
        edges {
          node {
            id
            pk
            category {
              pk
              name
              productsByCategory {
                edges {
                  node {
                    title
                    id
                    pk
                    sellingPrice
                    tax {
                      rate
                      isIncluded
                    }
                    taxAmount
                    image
                  }
                }
              }
            }
          }
        }
      }
      category {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT_CATEGORY = gql`
  query QUERY_CATEGORY($first: Int, $level: Int, $status: String) {
    categories(first: $first, level: $level, status: $status) {
      edges {
        node {
          id
          pk
          name
          status
          parent {
            id
            pk
            name
          }
          children(first: 100) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRINTER_LIST = gql`
  query printers($first: Int) {
    printers(first: $first) {
      edges {
        node {
          id
          pk
          name
          department
          location
          port
        }
      }
    }
  }
`;
