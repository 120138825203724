import { gql } from "@apollo/client";

export const POS_SCREEN_CREATE_PRODUCT = gql`
  mutation (
    $posScreenProductId: String
    $category: [String]
    $title: String
    $unit: String
    $tax: String
    $isWeightable: String
    $printOrder: String
    $isModifierCategoryGrid: String
    $stock: Decimal
    $threshold: Decimal
    $isOpenPriceProduct: String
    $sellingPrice: String
    $costPrice: String
    $image: Upload
    $modifierCategories: [String]
    $printers: [String]
    $barcodeContent: String
    $plu: String
    $shortDesc: String
    $isOpenProduct: String
    $promotionCategory: String
    $isScalable: String
    $posScreenCategroryId: String
    $displayInWebsite: String
  ) {
    createProduct(
      input: {
        category: $category
        posScreenProductId: $posScreenProductId
        title: $title
        promotionCategory: $promotionCategory
        unit: $unit
        isScalable: $isScalable
        tax: $tax
        stock: $stock
        threshold: $threshold
        isWeightable: $isWeightable
        printOrder: $printOrder
        isModifierCategoryGrid: $isModifierCategoryGrid
        isOpenPriceProduct: $isOpenPriceProduct
        printers: $printers
        modifierCategories: $modifierCategories
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        barcodeContent: $barcodeContent
        plu: $plu
        shortDesc: $shortDesc
        isOpenProduct: $isOpenProduct
        posScreenCategoryId: $posScreenCategroryId
        displayInWebsite: $displayInWebsite
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        costPrice
        isActive
        taxAmount
        tax {
          id
          pk
          title
          rate
        }
      }
    }
  }
`;
export const POS_SCREEN_CREATE_MODIFIER = gql`
  mutation (
    $posScreenProductId: String
    $category: [String]
    $title: String
    $unit: String
    $tax: String
    $sellingPrice: String
    $costPrice: String
    $image: Upload
    $shortDesc: String
    $posScreenCategroryId: String
  ) {
    createModifier(
      input: {
        category: $category
        posScreenProductId: $posScreenProductId
        title: $title
        unit: $unit
        tax: $tax
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        shortDesc: $shortDesc
        posScreenCategoryId: $posScreenCategroryId
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        costPrice
        isActive
        taxAmount
        tax {
          id
          pk
          title
          rate
        }
      }
    }
  }
`;
export const POS_SCREEN_CREATE_PROMOTION = gql`
  mutation (
    $title: String
    $tax: String
    $compound: String
    $sellingPrice: String
    $promotionCategories: [String]
    $isPromotionByCategory: String
  ) {
    createPromotion(
      input: {
        compound: $compound
        title: $title
        tax: $tax
        sellingPrice: $sellingPrice
        promotionCategories: $promotionCategories
        isPromotionByCategory: $isPromotionByCategory
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;
export const POS_SCREEN_UPDATE_PRODUCT = gql`
  mutation (
    $id: String!
    $category: [String]
    $title: String
    $unit: String
    $tax: String
    $isWeightable: String
    $printOrder: String
    $isModifierCategoryGrid: String
    $isOpenPriceProduct: String
    $stock: Decimal
    $threshold: Decimal
    $sellingPrice: String
    $costPrice: String
    $image: Upload
    $modifierCategories: [String]
    $printers: [String]
    $barcodeContent: String
    $plu: String
    $shortDesc: String
    $isScalable: String
    $promotionCategory: String
    $displayInWebsite: String
  ) {
    updateProduct(
      input: {
        id: $id
        category: $category
        promotionCategory: $promotionCategory
        title: $title
        unit: $unit
        tax: $tax
        isScalable: $isScalable
        stock: $stock
        threshold: $threshold
        isWeightable: $isWeightable
        printOrder: $printOrder
        isModifierCategoryGrid: $isModifierCategoryGrid
        isOpenPriceProduct: $isOpenPriceProduct
        printers: $printers
        modifierCategories: $modifierCategories
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        barcodeContent: $barcodeContent
        plu: $plu
        shortDesc: $shortDesc
        displayInWebsite: $displayInWebsite
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        image
        sellingPrice
        shortDesc
        costPrice
        isActive
        plu
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;

export const POS_SCREEN_UPDATE_MODIFIER = gql`
  mutation (
    $id: String!
    $category: [String]
    $image: Upload
    $title: String
    $unit: String
    $tax: String
    $sellingPrice: String
    $costPrice: String
    $shortDesc: String
  ) {
    updateModifier(
      input: {
        id: $id
        category: $category
        title: $title
        unit: $unit
        tax: $tax
        image: $image
        sellingPrice: $sellingPrice
        costPrice: $costPrice
        shortDesc: $shortDesc
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        image
        sellingPrice
        shortDesc
        costPrice
        tax {
          id
          pk
          title
        }
      }
    }
  }
`;

export const POS_SCREEN_UPDATE_PROMOTION = gql`
  mutation (
    $id: String!
    $title: String
    $tax: String
    $compound: String
    $sellingPrice: String
    $promotionCategories: [String]
    $isPromotionByCategory: String
  ) {
    updatePromotion(
      input: {
        id: $id
        compound: $compound
        title: $title
        tax: $tax
        sellingPrice: $sellingPrice
        promotionCategories: $promotionCategories
        isPromotionByCategory: $isPromotionByCategory
      }
    ) {
      errors
      success
      product {
        pk
        id
        title
        finalPrice
        sellingPrice
        tax {
          id
          pk
          title
        }
        compound {
          id
          pk
          title
        }
      }
    }
  }
`;
export const POS_PRODUCT_SOLD_OUT = gql`
  mutation ($objectId: String!) {
    toggleProduct(input: { objectId: $objectId }) {
      success
      errors
      message
      clientMutationId
    }
  }
`;

export const POS_SCREEN_DELETE_PRODUCT = gql`
  mutation ($objectId: String!) {
    deleteProduct(input: { objectId: $objectId }) {
      clientMutationId
      errors
      success
    }
  }
`;

export const POS_SCREEN_ASSIGN_PRINTER = gql`
  mutation ($contentType: Int, $objectId: String, $printer: [String]) {
    assignProductPrinter(
      input: {
        contentType: $contentType
        objectId: $objectId
        printer: $printer
      }
    ) {
      errors
      success
      message
    }
  }
`;

export const COMMIT_IMPORT_PRODUCT = gql`
  mutation COMMIT_IMPORT_PRODUCT($products: [ImportProductListInput]) {
    commitImportProduct(input: { products: $products }) {
      success
      errors
      message
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation CREATE_COLLECTION($name: String, $isVisible: String) {
    createCollection(input: { name: $name, isVisible: $isVisible }) {
      success
      errors
      collection {
        name
        isVisible
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation ($objectId: String!) {
    deleteCollection(input: { objectId: $objectId }) {
      success
      errors
    }
  }
`;

export const IMPORT_FROM_EXCEL = gql`
  mutation IMPORT_FROM_EXCEL(
    $id: String
    $file: Upload!
    $title: String
    $costPrice: String
    $sellingPrice: String
    $shortDesc: String
    $category: String
    $unit: String
    $tax: String
    $isWeightable: String
    $barcodeContent: String
    $plu: String
    $isOpenPriceProduct: String
  ) {
    importFromExcelFile(
      input: {
        id: $id
        file: $file
        title: $title
        shortDesc: $shortDesc
        costPrice: $costPrice
        sellingPrice: $sellingPrice
        category: $category
        unit: $unit
        isWeightable: $isWeightable
        barcodeContent: $barcodeContent
        plu: $plu
        tax: $tax
        isOpenPriceProduct: $isOpenPriceProduct
      }
    ) {
      success
      errors
      message
      skippedRow {
        id
        title
        sellingPrice
        unit
        barcodeContent
        plu
        costPrice
        description
        tax
        category
        isWeightable
        isOpenPriceProduct
        message
      }
    }
  }
`;
