import { useMutation, useQuery } from "@apollo/client";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { POS_SCREEN_ORDERED_ITEMS } from "../../../../../pages/PosScreen/queries";
import GET_RECEIPT from "../../../../../pages/ReceiptSetting/queries";
import {
  kitchenOrderItemId,
  kitchenOrdreCache,
  openTableCache,
  openTableVar,
} from "../../../../localstate/Cache";
import {
  GET_KITCHEN_CARD_ID,
  GET_ORDER_TYPE,
} from "../../../../localstate/Cart";
import JsBarcode from "jsbarcode";
import { getLocalKey } from "../../../../../helpers/sessionKey";
import EmailIcon from "../../../../../assets/email.svg";
import MessageIcon from "../../../../../assets/message.svg";
import Modal from "../../../../reusable/Modal";
import EmailField from "../../../../reusable/Forms/EmailField";
import MobileNumberField from "../../../../reusable/Forms/MobileNumberField";
import { Form, Formik } from "formik";
import PrimaryButton from "../../../../reusable/Buttons/PrimaryButton";
import {
  EmailValidationSchema,
  SMSValidationSchema,
} from "../../../../../utils/formValidation";
import { SEND_EMAIL_SMS } from "../../../../../pages/mutations";
import { toast } from "react-toastify";
import { backendErrorDisplay } from "../../../../../utils/backendErrorDisplay";

type Props = {
  setOpen?: any;
  paymentOrderId?: any;
  receiptPrintDefault: any;
};
const PayBillForm = (props: Props) => {
  const { setOpen, paymentOrderId, receiptPrintDefault } = props;
  const [printStart, setPrintStart] = useState(false);
  const { data: orderDetailsData } = useQuery(POS_SCREEN_ORDERED_ITEMS, {
    variables: {
      id: paymentOrderId,
    },
  });
  const orderId = orderDetailsData?.order?.kitchenOrder.getInvoiceNumber;
  const [image, setImage] = useState("");
  const generateBarcodeSVG = (format: any) => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    JsBarcode(svg, orderId, {
      format: format,
      displayValue: false,
    });
    return svg;
  };
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");
  const svgElement = generateBarcodeSVG("CODE128");
  const svgString = new XMLSerializer().serializeToString(svgElement);
  const img = new Image();
  img.onload = function () {
    ctx.drawImage(img, 0, 0);
    const dataUrl = canvas.toDataURL("image/png");
    setImage(dataUrl);
  };
  img.src = "data:image/svg+xml;base64," + btoa(svgString);
  const jsonData = orderDetailsData?.order?.eftposReceipt
    ? JSON.parse(orderDetailsData?.order?.eftposReceipt)
    : "";
  const handleAfterPrint = (e: any) => {
    e.stopPropagation();
    setOpen(false);
    const openPopupAfterPayment: boolean =
      receiptPrintDefault?.isOpenPopupAfterPayment;

    if (openPopupAfterPayment === true) {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
    return () => {
      kitchenOrdreCache.writeQuery({
        query: GET_KITCHEN_CARD_ID,
        data: {
          kitchenOrderItemId: kitchenOrderItemId({}),
        },
      });
    };
  };
  const componentRef: MutableRefObject<any> = useRef(null);
  const isEnableA4 = JSON.parse(getLocalKey("A4Enable") || "false");
  const [sendSmsEmail] = useMutation(SEND_EMAIL_SMS, {});
  const { data: receiptData } = useQuery(GET_RECEIPT);
  const isEnableEmail: boolean = receiptPrintDefault?.isEnableEmail;
  const isEnableSms: boolean = receiptPrintDefault?.isEnableSms;
  const handleBillPrint = () => {
    setPrintStart(true);
    setOpen(false);
    const openPopupAfterPayment: boolean =
      receiptPrintDefault?.isOpenPopupAfterPayment;
    if (openPopupAfterPayment === true) {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
  };
  function roundToNearest(value: any, multiple: any) {
    return Math.round(value / multiple) * multiple;
  }
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [offlinePopup, setOfflinePopup] = useState(false);
  const [smsPopup, setSmsPopup] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const handleSMSEmailSend = async (type: any) => {
    if (!isOnline) {
      setOfflinePopup(true);
      return;
    }
    if (type === "email") {
      if (orderDetailsData && orderDetailsData?.order?.customer?.id) {
        const response = await sendSmsEmail({
          variables: {
            Type: type,
            email: orderDetailsData?.order?.customer?.user?.profile?.email,
            orderId: orderDetailsData?.order?.pk,
          },
        });
        if (response?.data?.sendSmsEmail?.success) {
          toast.success("Email Send Successfully");
        } else {
          const errors = backendErrorDisplay(
            response?.data?.sendSmsEmail?.errors
          );
          toast.error(errors);
        }
        return;
      }
      setEmailPopup(true);
      return;
    }
    if (type === "sms") {
      if (orderDetailsData && orderDetailsData?.order?.customer?.id) {
        const response = await sendSmsEmail({
          variables: {
            Type: type,
            mobileNumber: orderDetailsData?.order?.customer?.user?.mobileNumber,
            orderId: orderDetailsData?.order?.pk,
          },
        });
        if (response?.data?.sendSmsEmail?.success) {
          toast.success("SMS Send Successfully");
        } else {
          const errors = backendErrorDisplay(
            response?.data?.sendSmsEmail?.errors
          );
          toast.error(errors);
        }
        return;
      }
      setSmsPopup(true);
      return;
    }
  };
  const handleSMSEmail = async (type: any, props: any, values: any) => {
    if (type === "email") {
      const response = await sendSmsEmail({
        variables: {
          Type: type,
          email: values?.email,
          orderId: orderDetailsData?.order?.pk,
        },
      });
      if (response?.data?.sendSmsEmail?.success) {
        toast.success("Email Send Successfully");
        setEmailPopup(false);
      } else {
        const errors = backendErrorDisplay(
          response?.data?.sendSmsEmail?.errors
        );
        toast.error(errors);
      }
      return;
    }
    if (type === "sms") {
      const response = await sendSmsEmail({
        variables: {
          Type: type,
          mobileNumber: values?.mobileNumber,
          orderId: orderDetailsData?.order?.pk,
        },
      });
      if (response?.data?.sendSmsEmail?.success) {
        toast.success("SMS Send Successfully");
        setSmsPopup(false);
      } else {
        const errors = backendErrorDisplay(
          response?.data?.sendSmsEmail?.errors
        );
        toast.error(errors);
      }
      return;
    }
  };
  return (
    <div>
      <p className="text-xl">
        Change Amount:{" "}
        {roundToNearest(orderDetailsData?.order?.changeAmount, 0.05).toFixed(2)}
      </p>
      <div className="flex justify-between mt-8 gap-4">
        <button
          onClick={(e) => handleAfterPrint(e)}
          className="w-[48%] text-white bg-[#D32F2F] h-[50px]"
        >
          Cancel
        </button>
        {isEnableA4 ? (
          <></>
        ) : (
          <>
            {orderDetailsData ? (
              <button
                ref={componentRef}
                onClick={handleBillPrint}
                className="w-[48%] text-white h-[50px] bg-[#4338CA] rounded"
                disabled={printStart}
              >
                Print Bill
              </button>
            ) : (
              <button
                ref={componentRef}
                onClick={handleBillPrint}
                className="w-[48%] text-gray-900 h-[50px] bg-gray-100 rounded"
                disabled
              >
                Please Wait...
              </button>
            )}
          </>
        )}
      </div>
      <div className="flex justify-between mt-4 gap-4">
        {isEnableSms ? (
          <button
            ref={componentRef}
            onClick={() => handleSMSEmailSend("sms")}
            className="w-[48%] text-white h-[50px] bg-[#4338CA] rounded"
            disabled={printStart}
          >
            <span className="w-full flex justify-center items-center gap-2">
              <p>Send SMS</p>
            </span>
          </button>
        ) : (
          ""
        )}
        {isEnableEmail ? (
          <button
            ref={componentRef}
            onClick={() => handleSMSEmailSend("email")}
            className="w-[48%] text-white h-[50px] bg-[#4338CA] rounded"
            disabled={printStart}
          >
            <span className="w-full flex justify-center items-center gap-2">
              <p>Send Email</p>
            </span>
          </button>
        ) : (
          ""
        )}
      </div>
      <Modal
        open={offlinePopup}
        setOpen={setOfflinePopup}
        children={<>Please Connect to the Internet !!!</>}
        title={"Device Offline"}
      />
      <Modal
        size="h-[300px] max-w-xl"
        open={smsPopup}
        setOpen={setSmsPopup}
        children={
          <>
            <Formik
              initialValues={{
                mobileNumber: "",
              }}
              validationSchema={SMSValidationSchema}
              onSubmit={(values: any, props: any) =>
                handleSMSEmail("sms", props, values)
              }
            >
              {({ touched, values, setFieldValue, errors, handleChange }) => (
                <Form>
                  <MobileNumberField
                    name="mobileNumber"
                    label="Mobile Number"
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                  <div className="mt-32">
                    <PrimaryButton text={"Send SMS"} />
                  </div>
                </Form>
              )}
            </Formik>
          </>
        }
        title={"Set SMS Number"}
      />
      <Modal
        size="h-[200px] max-w-xl"
        open={emailPopup}
        setOpen={setEmailPopup}
        children={
          <>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={EmailValidationSchema}
              onSubmit={(values: any, props: any) =>
                handleSMSEmail("email", props, values)
              }
            >
              {({ touched, values, setFieldValue, errors, handleChange }) => (
                <Form>
                  <EmailField
                    name="email"
                    label="Email Address"
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                  <div>
                    <PrimaryButton text={"Send Email"} />
                  </div>
                </Form>
              )}
            </Formik>
          </>
        }
        title={"Set Email Address "}
      />
    </div>
  );
};

export default PayBillForm;
